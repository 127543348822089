import React, { Component, Fragment, ReactNode } from 'react';
import ProjectInput, { ProjectFormPropsBase } from './ProjectInput';
import { default as _ } from 'lodash';
import './css/ProjectFormHousing.scss';
import { ProjectHousingPlanType } from './model/Project';
import CloseIcon from '@material-ui/icons/Close';
import { Input, Select, MenuItem, Menu, Button, InputAdornment, IconButton } from '@material-ui/core';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { pad } from './Utils';
import C3Chart from './C3Chart';
import AWSModule from './AWSModule';
import App from './App';
import ProjectFieldInput from './ProjectFieldInput';
import BuilditInput from './BuilditInput';
import S3Image from './S3Image';
import * as URI from "uri-js";
import { buildingShapeTypeToString } from './Utils';
import { availableHousingData } from './Constraints';
import { ModalOptions } from './Modal';
import BuilditSelect from './BuilditSelect';
const Div = (props: {children: ReactNode}) => <div>{props.children}</div>;


export interface MyBuildingShapeType {
  user_id?: number;
  global_id?: number,
  building_area?: number,
  core_area?: number,
  created_at?: string,
  deleted?: boolean,
  floor_area?: number,
  houses_number?: number,
  img_path?: string,
  modified_at?: string,
  name?: string,
  total_exclusive_area?: number,
  total_service_area?: number,
  proportion?: number,
}

export interface ProjectFormHousingProps extends ProjectFormPropsBase {
  open: boolean;
  setOpen: (open: boolean) => void;
  setModal: (open: boolean, options?: ModalOptions) => void;
}

export interface ProjectFormHousingState {
  chartData: any;
  myBuildingShapeType: Array<MyBuildingShapeType>
  value: number,
}

export default class ProjectFormHousing extends Component<ProjectFormHousingProps, ProjectFormHousingState> {
  state: ProjectFormHousingState = {
    value: 0,
    chartData: {
      columns: []
    },
    myBuildingShapeType: [],
  }

  componentWillMount = async () => {
    this.props.setOpen(true);
    const myBuildingShapeType: Array<any> = [];

    for (const t of this.props.currentProject.my_building_shape_type!) {
      const r = await App.search({
        table: App.DDBTable.MyBuildingType,
        query: `stage.keyword:${App.stage} AND global_id:${t.id}`,
      });

      const rr = r.data;
      if (rr.hits.hits && rr.hits.hits.length > 0) {
        myBuildingShapeType.push({
          proportion: t.proportion,
          ...rr.hits.hits[0]._source
        });
      }
    }
    this.setState({
      myBuildingShapeType: myBuildingShapeType,
    }, () => {
      this.setChartData();
    })
  }
  componentWillUnmount = () => {
    this.props.setOpen(false);
  }
  componentDidUpdate = (pp: ProjectFormHousingProps, ps: ProjectFormHousingState) => {
    if (
      !_.isEqual(pp.currentProject.my_building_shape_type, this.props.currentProject.my_building_shape_type)
      || !_.isEqual(pp.currentProject.housing_plan_type, this.props.currentProject.housing_plan_type)
    ) {
      const myBuildingShapeType = _.cloneDeep(this.state.myBuildingShapeType);
      for (const t of this.props.currentProject.my_building_shape_type!) {
        const index = myBuildingShapeType.findIndex(e => e.global_id === t.id);
        if (index > -1) {
          myBuildingShapeType[index].proportion = t.proportion;
        }
      }
      this.setState({
        myBuildingShapeType: myBuildingShapeType
      }, () => {
        this.setChartData();
      })
    }
  }

  setChartData = async () => {
    const chartData: any = [];
    if (this.state.myBuildingShapeType && this.state.myBuildingShapeType.length > 0) {
      this.state.myBuildingShapeType.map((r, i) => {
        chartData.push([`동평면 (${r.name})`, r.proportion])
      });
    }
    if (this.props.currentProject.housing_plan_type && this.props.currentProject.housing_plan_type.length > 0) {
      this.props.currentProject.housing_plan_type.map((r, i) => chartData.push([`세대타입 ${pad((i + 1).toString(), 2)}(${r.area === undefined ? '선택안됨' : r.area}㎡)`, r.proportion]));
    }

    this.setState({ chartData: { columns: chartData.length > 0 ? chartData : [] } });
  }

  handleClick = (e: any) => {
    
  }

  render () {

    const listArea: Array<{label: string, value: number}> = [];
    const listBay: Array<{label: string, value: number}> = [];

    // @ts-ignore
    let availList = _.uniq(_.reduceRight(this.props.currentProject.building_shape_type!.map(r => availableHousingData[r.figures!]), (a, b) => { return a.concat(b) })).sort((a, b) => a - b);
    // if (this.props.currentProject.housing_plan_type!.length > 0) {
    //   this.props.currentProject.housing_plan_type!.map(r => {
    //     const index = availList.findIndex(e => e === r.area!);
    //     if (index >= 0) {
    //       availList.splice(index, 1);
    //     }
    //   })
    // }

    if (availList.length === 0) {
      for (let i = 15; i <= 150; i+=5) {
        listArea.push({
          label: i + "㎡",
          value: i
        })
      }
    } else {
      availList.map((r: any) => {
        listArea.push({
          label: r + "㎡",
          value: r
        })
      })
    }

    for (let i = 1; i <= 6; i++) {
      listBay.push({
        label: i + " Bay",
        value: i
      })
    }


    return (
      <div className="ProjectFormHousing">
        {
          this.props.currentProject.building_shape_type!.length > 0 &&
          <div className="available-list">
            {
              Object.values(this.props.projectErros!.errs).filter(e => e!.subDetailType === "HOUSING").length > 0 &&
              <div className="font font-error font-12px" style={{width: "224px", marginRight: "40px"}}>
                <div>{ this.props.projectErros!.errs!.housing_rate !== undefined && this.props.projectErros!.errs!.housing_rate!.msg }</div>
                <div>{ this.props.projectErros!.errs!.housing_plan_type !== undefined  && this.props.projectErros!.errs!.housing_plan_type!.msg }</div>
              </div>
            }
            {/* <div className="info">BUILDIT 동타입과 나의 동평면의 세대 비율을 설정합니다</div> */}
            <div className="header" style={{ fontSize: "13px" }}>선택 BUILDIT 동타입에 따른 가능 면적</div>
            {
              this.props.currentProject.building_shape_type!.map(r => {
                return (
                  // @ts-ignore
                  <div className={`item ${this.props.currentProject.housing_plan_type!.map(rr => availableHousingData[r.figures!]
                    .includes(rr.area!)).filter(f => f === false).length === this.props.currentProject.housing_plan_type!.length && "font-highlight"}`}>
                    <div className="name">{buildingShapeTypeToString(r.figures!) + ` ${r.houses_number}세대`}</div>
                    <div className="variance">
                    {
                      // @ts-ignore
                      `${availableHousingData[r.figures!][0]} ~ ${availableHousingData[r.figures!][availableHousingData[r.figures!].length - 1]}㎡`
                    }
                    </div>
                  </div>
                )
              })
            }
          </div>
          ||
          Object.values(this.props.projectErros!.errs).filter(e => e!.subDetailType === "HOUSING").length > 0 &&
          <div className="font font-error font-12px" style={{width: "224px", marginRight: "40px"}}>
            <div>{ this.props.projectErros!.errs!.housing_rate !== undefined && this.props.projectErros!.errs!.housing_rate!.msg }</div>
            <div>{ this.props.projectErros!.errs!.housing_plan_type !== undefined  && this.props.projectErros!.errs!.housing_plan_type!.msg }</div>
          </div>

        }
        <div className="content">
        {
          ((this.props.currentProject.project_type! === "AI" && this.props.currentProject.building_type === "아파트")
          ||
          (this.props.currentProject.project_type! !== "AI")) &&
         <div className="sub-title">BUILDIT 동타입</div>  // 특정상황에는 표기안될 수 있음 (현재는 조건 없음)
        }
        {
          this.props.currentProject.housing_plan_type!.map((h, i) => {
            return (
              <div key={i} className="housing-item">
                <div className="header">
                  <div className="font bg-navy font-secondary font-12px">{`세대타입 ${pad((i + 1).toString(), 2)}`}</div>
                  <IconButton disableRipple={true} style={{padding: "0px"}} className="m-l-a"><CloseIcon className="icon close-icon"
                    onClick={e => this.deleteHousingPlan(i)}
                  /></IconButton>
                </div>
                <div className="content">
                  <BuilditSelect 
                    type="Normal"
                    className={`housing-select area ${!(this.props.currentProject.project_type === "AI" && this.props.currentProject.building_type === "아파트") && "full-width" }`}
                    value={h.area}
                    onChange={(e: any) => { 
                      let value;
                      if (this.props.currentProject.housing_plan_type!.findIndex(r => r.area! === Number(e)) < 0) {
                        value = Number(e);
                        this.updateValue("HOUSING", i, value, undefined, undefined)
                      } else {
                        this.props.setModal(true, {
                          positive: () => { this.props.setModal(false) },
                          negative: () => { this.props.setModal(false) },
                          content: <div>이미 존재하는 면적입니다.</div>
                        })
                      }
                    }}
                    list={listArea}
                  />
                  {
                    this.props.currentProject.project_type === "AI" && this.props.currentProject.building_type === "아파트" &&
                    <ProjectFieldInput
                      className="proportion"
                      type="number"
                      unit="%"
                      fieldValue={h.proportion}
                      onChange={e => this.updateValue("HOUSING", i, undefined, undefined, Number(e))}
                      {...this.props}
                    />

                  }
                </div>
              </div>
            )
          })
        }
        {
          ( this.props.currentProject.project_type === "AI" &&
            this.props.currentProject.building_type === "아파트" &&
            this.props.currentProject.building_shape_type!.length > 0 &&
            this.props.currentProject.housing_plan_type!.length < 4
          ) &&
          <Button onClick={this.addHousingPlan} className="btn bg-navy btn-primary m-l-a btn-small housing-type-add-btn" disableRipple={true}>+ 세대타입 추가</Button>
        }
        {
          (this.props.currentProject.project_type === "AI" && this.props.currentProject.building_type !== "아파트" && this.props.currentProject.housing_plan_type!.length < 1) &&
          <Button onClick={this.addHousingPlan} className="btn bg-navy btn-primary m-l-a btn-small housing-type-add-btn" disableRipple={true}>+ 세대타입 추가</Button>
        }
        {
          (this.props.currentProject.project_type !== "AI" && this.props.currentProject.building_shape_type!.length > 0 && this.props.currentProject.housing_plan_type!.length < 4) &&
          <Button onClick={this.addHousingPlan} className="btn bg-navy btn-primary m-l-a btn-small housing-type-add-btn" disableRipple={true}>+ 세대타입 추가</Button>
        }
        {
          this.state.myBuildingShapeType.length > 0 &&
         <div className="sub-title">나의 동평면</div>
        }
        {
          this.state.myBuildingShapeType.map((t, i) => {
            return (
              <div key={i} className="housing-plan-type my-type">
                <div className="header">
                  <span>{t.user_id}</span>
                  <img src="/img/my_icon.png" className="my-icon m-l-md" />
                </div>
                <div className="body">
                  <div className="img">
                    <S3Image s3={{
                        Bucket: App.S3Bucket.MyBuildingType,
                        Key: (URI.parse(t.img_path!, { absolutePath: true }).path as string).substring(1)
                    }}/>
                  </div>
                  <div className="info">
                    <div className="title" title={t.name}>{t.name}</div>
                    <div className="key-value">
                      <div className="key">세대 수</div>
                      <div className="value">{t.houses_number}세대</div>
                    </div>
                    <div className="key-value">
                      <div className="key">평균 전용 면적</div>
                      <div className="value">{Number(t.total_exclusive_area! / t.houses_number!).toFixed(2)}㎡</div>
                    </div>
                    {
                      this.props.currentProject.project_type === "AI" &&
                      <ProjectFieldInput 
                        type="number"
                        className="my-type-proportion"
                        unit="%"
                        fieldValue={t.proportion}
                        placeholder="입력"
                        onChange={e => {
                          const list = _.cloneDeep(this.props.currentProject.my_building_shape_type);
                          const index = list!.findIndex(e => e.id === t.global_id);
                          if (index > -1) {
                            this.updateValue("MY_TYPE", index, undefined, undefined, Number(e));
                          }
                        }}
                        {...this.props}
                      />
                    }
                  </div>
                </div>
              </div>
            );
          })
        }
        </div>
      </div>
    );
  }
  onSelectChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ value: Number(e.target.value) });
  }
  addHousingPlan = () => {
    if (this.props.currentProject.housing_plan_type!.length > 3) {
      this.props.setModal(true, {
        positive: () => { this.props.setModal(false) },
        negative: () => { this.props.setModal(false) },
        content: <div>최대 4개까지 세대타입을 추가 할 수 있습니다.</div>
      })
      return;
    }
    const list: ProjectHousingPlanType[] = _.cloneDeep(this.props.currentProject.housing_plan_type!);
    list.push(new ProjectHousingPlanType());
    this.props.onUpdateProject({ 
      housing_plan_type: list,
      housing_rate: list.map(r => r.proportion!).reduce((a, b) => a + b, 0) + this.props.currentProject.my_building_shape_type!.map(r => r.proportion!).reduce((a, b) => a + b, 0),
      auto_setting: false
    });
  }
  deleteHousingPlan = (index: number) => {
    const list: ProjectHousingPlanType[] = _.cloneDeep(this.props.currentProject.housing_plan_type!);
    list.splice(index, 1);
    this.props.onUpdateProject({ 
      housing_plan_type: list,
      housing_rate: (list.map(r => r.proportion!).reduce((a, b) => a + b, 0) + this.props.currentProject.my_building_shape_type!.map(r => r.proportion!).reduce((a, b) => a + b, 0)),
      auto_setting: false 
    });
  }
  updateHousingPlan = (index: number, area?: number, bay?: number, proportion?: number) => {
    const list: ProjectHousingPlanType[] = _.cloneDeep(this.props.currentProject.housing_plan_type!);
    list[index].area = area !== undefined ? area : list[index].area;
    list[index].bay = bay !== undefined ? bay : list[index].bay;
    list[index].proportion = proportion !== undefined ? proportion : list[index].proportion;
    this.props.onUpdateProject({ 
      housing_plan_type: list,
      housing_rate: list.map(r => r.proportion!).reduce((a, b) => a + b, 0) + this.props.currentProject.my_building_shape_type!.map(r => r.proportion!).reduce((a, b) => a + b, 0),
      auto_setting: false 
    });
  }
  updateValue = (type: 'HOUSING' | 'MY_TYPE', index: number, area?: number, bay?: number, proportion?: number) => {
    if (proportion !== undefined) {
      const curValue = Number(type === "HOUSING" ? this.props.currentProject.housing_plan_type![index].proportion : this.props.currentProject.my_building_shape_type![index].proportion)
      const sumOfRate = 
        this.props.currentProject.housing_plan_type!.reduce((a: any, b: any) => a + Number(b.proportion), 0) +
        this.props.currentProject.my_building_shape_type!.reduce((a: any, b: any) => a + Number(b.proportion), 0) -
        curValue;

      // TODO: ProjectField에서 동작할 수 있도록 변경
      if (sumOfRate + proportion > 100) {
        // alert('세대타입 비율을 100%를 초과 할 수 없습니다')
        this.props.setModal(true, {
          positive: () => { this.props.setModal(false) },
          negative: () => { this.props.setModal(false) },
          content: <div>세대타입 비율이 100%를 초과 할 수 없습니다.</div>
        })
        return;
      }
    }

    if (type === "HOUSING") {
      this.updateHousingPlan(index, area, bay, proportion);
    } else {
      const list = _.cloneDeep(this.props.currentProject.my_building_shape_type);
      if (index > -1) {
        list![index].proportion = proportion;
        this.props.onUpdateProject({
          my_building_shape_type: list,
          housing_rate: list!.map(r => r.proportion!).reduce((a, b) => a + b) + this.props.currentProject.housing_plan_type!.map(r =>  r.proportion!).reduce((a, b) => a + b, 0),
          auto_setting: false,
        })
      }
    }
  }
}




