import React, { Component, Fragment } from 'react';
import ProjectInput, { ProjectFormPropsBase } from './ProjectInput';

import './css/ProjectFormValue.scss';
import { Button } from '@material-ui/core';
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { ConfigType } from './ProjectFormDetail';
import ProjectFieldInput from './ProjectFieldInput';
import ProjectSelectOptions from './SelectOptions';
import Modal, { ModalOptions } from './Modal';
import BuildingLaws, {Laws} from './BuildingLaws';
import { ProjectConstraints } from './Constraints';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { 표기_기본값 } from './ProjectForm';

export interface ProjectFormValueProps extends ProjectFormPropsBase {
  more: boolean;
  setMore: (type: ConfigType) => void;
  initDefault?: 표기_기본값;
}
export interface ProjectFormValueState {
  openModal: boolean;
  modalOptions?: ModalOptions;
  showDefault?: boolean;
}

export default class ProjectFormValue extends React.Component<ProjectFormValueProps, ProjectFormValueState> {
  state: ProjectFormValueState = {
    openModal: false,
    showDefault: this.props.initDefault ? false : undefined ,
  }

  componentDidMount = () => {
    window.addEventListener('pointerup', (e) => {
      this.setState({showDefault: false});
    });
    window.addEventListener('mouseup', (e) => {
      this.setState({showDefault: false});
    });
  }

  componentWillUpdate = () => {
    if (this.state.showDefault === undefined) {
      this.setState({showDefault: false});
    }
  }

  inputMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>{
    if (this.state.showDefault === true && e.nativeEvent.which === 1) {
      this.setState({showDefault: false});
    }
  }

  render = () => {
    return (
      <Fragment>
        <div className="ProjectFormValue-Header">
          <div className="description">기본값을 수정하시면 컬러가 변경됩니다.</div>
          <Button className="show-button" disableRipple={true}
              onMouseDown={(e) => {
                if (e.nativeEvent.which === 1 && this.props.initDefault) {
                  this.setState({showDefault: true});
                } else if (this.props.initDefault === undefined) {
                  this.setModal(true, {
                    title: "기본값 보기",
                    type: "SIMPLE",
                    positive: () => {
                      this.setModal(false);
                    },
                    negative: "hidden",
                    content: <div>필지를 선택해 주세요</div>
                  })
                }
              }}
          >
            기본값 보기
            {
              this.state.showDefault &&
              <VisibilityIcon className="icon active"/>
              ||
              <VisibilityOffIcon className="icon"/>
            }
            
          </Button>
        </div>
        
        <div className="ProjectFormValue">
        
        {
          this.props.currentProject.project_type === "AI" &&
          <Fragment>
            <div className="project-field">
              <ProjectFieldInput 
                showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                defaultValue={this.props.initDefault ? this.props.initDefault.건폐율 : undefined}
                type="number"
                title={`건폐율`}
                unit="%"
                className="width-half"
                fieldName="building_land_ratio"
                fieldValue={this.props.currentProject.building_land_ratio}
                placeholder="입력"
                step={5}
                onMouseDown={this.inputMouseDown}
                {...this.props}
              />
            </div>
            <div className="project-field m-l-a">
              <ProjectFieldInput 
                showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                defaultValue={this.props.initDefault ? this.props.initDefault.용적률 : undefined}
                onMouseDown={this.inputMouseDown}
                type="number"
                title={`용적률`}
                unit="%"
                className="width-half"
                fieldName="floor_area_ratio"
                fieldValue={this.props.currentProject.floor_area_ratio}
                placeholder="입력"
                step={10}
                {...this.props}
              />
            </div>
            <div className="building-stories-wrap">
              {
                this.props.currentProject.building_type! === "아파트" &&
                <div className="project-field">
                  <ProjectFieldInput 
                    showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                    defaultValue={this.props.initDefault ? this.props.initDefault.최저층수 : undefined}
                    onMouseDown={this.inputMouseDown}
                    type="number"
                    title={`최저 층수`}
                    unit="층"
                    className="width-half"
                    fieldName="building_stories_min"
                    fieldValue={this.props.currentProject.building_stories_min}
                    placeholder="입력"
                    step={1}
                    {...this.props}
                  />
                </div>

              }
              <div className="project-field m-l-a">
                <ProjectFieldInput 
                  showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                  defaultValue={this.props.initDefault ? this.props.initDefault.최고층수 : undefined}
                  onMouseDown={this.inputMouseDown}
                  type="number"
                  title={`최고 층수`}
                  unit="층"
                  className="width-half"
                  fieldName="building_stories_max"
                  fieldValue={this.props.currentProject.building_stories_max}
                  placeholder="입력"
                  step={1}
                  {...this.props}
                />
              </div>
              {
                this.props.currentProject.building_type! === "아파트" &&
                <div className="project-field">
                  <ProjectFieldInput 
                    showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                    defaultValue={this.props.initDefault ? this.props.initDefault.평균층수 : undefined}
                    onMouseDown={this.inputMouseDown}
                    type="number"
                    title={`평균 층수`}
                    unit="층"
                    className="width-half"
                    fieldName="building_stories_avg"
                    fieldValue={this.props.currentProject.building_stories_avg}
                    placeholder="입력"
                    step={1}
                    {...this.props}
                  />
                </div>

              }
              <div className="project-field m-l-a">
                <ProjectFieldInput 
                  title="평균 층수 산정 기준"
                  select={true}
                  className="width-half"
                  fieldName="building_stories_avg_type"
                  fieldValue={this.props.currentProject.building_stories_avg_type}
                  list={ProjectSelectOptions.building_stories_avg_type}
                  {...this.props}
                />
              </div>
            </div>
          </Fragment>
        }
        <div className="project-field">
          <ProjectFieldInput 
            showDefault={this.props.initDefault ? this.state.showDefault : undefined}
            defaultValue={this.props.initDefault ? this.props.initDefault.층고 : undefined}
            onMouseDown={this.inputMouseDown}
            type="number"
            title={`층고`}
            unit="m"
            className="width-half"
            fieldName="floor_height"
            fieldValue={this.props.currentProject.floor_height}
            placeholder="입력"
            step={0.1}
            {...this.props}
          />
        </div>
        {
          (this.props.currentProject.project_type! === "AI" && this.props.currentProject.building_type! === "아파트") &&
          <Fragment>
            <div className="project-field m-l-a" style={{ width: "175px"}}>
              <div className="header" style={{ height: "20px", color: "#999", fontSize: "12px", marginBottom: "5px" }}>예상 건물 최고 높이</div>
              <div className="content" style={{ height: "40px", color: "#ccc", fontSize: "16px", display: "flex", alignItems: "center" }}>
                {(this.props.currentProject.building_stories_max! * this.props.currentProject.floor_height!).toFixed(2)} m
              </div>
            </div>
            <div className="warning" style={{ color: "#48ddf2", fontSize: "13px" }}>
              * 최고 높이 150m 이상은 결과가 타당하지 못 할 수 있습니다.
            </div>
          </Fragment>
        }
        <div className="more-btn-wrap">
          <Button onClick={e => this.props.setMore('VALUE')} disableRipple={true} className="btn bg-navy btn-primary btn-small more-btn">더보기 
          { (this.props.more === false) && <DownArrowIcon className="icon" /> || <UpArrowIcon className="icon" /> }
          </Button>
          <Button onClick={() => this.showBuildingLaw()} className="btn bg-navy btn-extra btn-small law-btn">건축법규 보기</Button>
        </div>
        <div className="more-fields">
          {
            this.props.more &&
            <Fragment>
              <div className="project-field-wrap">
                {
                  ["아파트", "다세대주택"].includes(this.props.currentProject.building_type!) &&
                    <div className="title">
                      정북일조
                      <InfoOutlinedIcon className="info-icon" onClick={e => this.showBuildingLaw("A")}/>
                    </div>
                }
                <div className="project-field-group">
                  {
                    ["아파트", "다세대주택"].includes(this.props.currentProject.building_type!) &&
                    <Fragment>
                      <div className="project-field m-r-a">
                        <ProjectFieldInput 
                          showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                          defaultValue={this.props.initDefault ? this.props.initDefault.정북일조_9m이하 : undefined}
                          onMouseDown={this.inputMouseDown}
                          type="number"
                          title={`9m이하`}
                          unit={`${this.props.currentProject.setback_regulation_from_north_less_9m_type === "METER" && "m" || "h"}`}
                          className="width-half"
                          fieldName="setback_regulation_from_north_less_9m"
                          fieldValue={this.props.currentProject.setback_regulation_from_north_less_9m}
                          // unitFieldName="setback_regulation_from_north_less_9m_type"
                          // unitFieldValue={this.props.currentProject.setback_regulation_from_north_less_9m_type}
                          unitList={ProjectSelectOptions.setback_regulation_from_north_less_9m_type}
                          placeholder="입력"
                          step={0.1}
                          {...this.props}
                        />
                      </div>
                      <div className="project-field">
                        <ProjectFieldInput
                          showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                          defaultValue={this.props.initDefault ? this.props.initDefault.정북일조_9m초과 : undefined} 
                          onMouseDown={this.inputMouseDown}
                          type="number"
                          title={`9m초과`}
                          unit={`${this.props.currentProject.setback_regulation_from_north_more_9m_type === "METER" && "m" || "h"}`}
                          className="width-half"
                          fieldName="setback_regulation_from_north_more_9m"
                          fieldValue={this.props.currentProject.setback_regulation_from_north_more_9m}
                          // unitFieldName="setback_regulation_from_north_more_9m_type"
                          // unitFieldValue={this.props.currentProject.setback_regulation_from_north_more_9m_type}
                          unitList={ProjectSelectOptions.setback_regulation_from_north_more_9m_type}
                          placeholder="입력"
                          step={0.1}
                          {...this.props}
                        />
                      </div>

                    </Fragment>
                  }
                </div>                
              </div>
              {
                ["아파트"].includes(this.props.currentProject.building_type!) &&
                <div className="project-field-wrap">
                  <div className="title">
                    채광사선
                    <InfoOutlinedIcon className="info-icon" onClick={e => this.showBuildingLaw("B")}/>
                  </div>
                  <div className="project-field-group">
                    {
                      ["아파트"].includes(this.props.currentProject.building_type!) &&  
                      <div className={`project-field m-r-a`}>
                        <ProjectFieldInput 
                          showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                          defaultValue={this.props.initDefault ? this.props.initDefault.정북일조_9m초과 : undefined} 
                          onMouseDown={this.inputMouseDown}
                          type="number"
                          title={`채광창↔인접대지경계선`}
                          unit="h"
                          className="width-half"
                          fieldName="setback_regulation_from_site_boundary"
                          fieldValue={this.props.currentProject.setback_regulation_from_site_boundary}
                          placeholder="입력"
                          step={0.1}
                          {...this.props}
                        />
                      </div>
                    }
                  </div>                
                </div>

              }
              {
                ["아파트", "오피스텔"].includes(this.props.currentProject.building_type!) &&
                <div className="project-field-wrap">
                  <div className="title">인동거리</div>
                  <div className="project-field-group">
                    {
                      ["아파트", "오피스텔"].includes(this.props.currentProject.building_type!) &&
                      <div className={`project-field small m-r-a`}>
                        <ProjectFieldInput 
                          showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                          defaultValue={this.props.initDefault ? this.props.initDefault.인동간격_채광창_다른건물 : undefined} 
                          onMouseDown={this.inputMouseDown}
                          type="number"
                          title={`채광창↔다른건물`}
                          unit="h"
                          className="width-half"
                          fieldName="distance_between_window_opaque_walls"
                          fieldValue={this.props.currentProject.distance_between_window_opaque_walls}
                          placeholder="입력"
                          step={0.1}
                          infoAdornment={<InfoOutlinedIcon className="info-icon" onClick={e => this.showBuildingLaw("C")}/>}
                          {...this.props}
                        />
                      </div>
                    }
                    {
                      ["아파트"].includes(this.props.currentProject.building_type!) &&
                      <div className="project-field">
                        <ProjectFieldInput 
                          showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                          defaultValue={this.props.initDefault ? this.props.initDefault.인동간격_벽면_측벽 : undefined} 
                          onMouseDown={this.inputMouseDown}
                          type="number"
                          title={`벽면↔측벽`}
                          unit="m"
                          className="width-half"
                          fieldName="distance_between_side_opaque_walls"
                          fieldValue={this.props.currentProject.distance_between_side_opaque_walls}
                          placeholder="입력"
                          step={0.1}
                          infoAdornment={<InfoOutlinedIcon className="info-icon" onClick={e => this.showBuildingLaw("D")}/>}
                          {...this.props}
                        />
                      </div>
                    }
                    {
                      ["아파트"].includes(this.props.currentProject.building_type!) &&
                      <div className="project-field">
                        <ProjectFieldInput 
                          showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                          defaultValue={this.props.initDefault ? this.props.initDefault.인동간격_측벽_측벽 : undefined} 
                          onMouseDown={this.inputMouseDown}
                          type="number"
                          title={`측벽↔측벽`}
                          unit="m"
                          className="width-half"
                          fieldName="distance_between_side_walls"
                          fieldValue={this.props.currentProject.distance_between_side_walls}
                          placeholder="입력"
                          step={0.1}
                          infoAdornment={<InfoOutlinedIcon className="info-icon" onClick={e => this.showBuildingLaw("E")}/>}
                          {...this.props}
                        />
                      </div>
                    }
                  </div>                
                </div>

              }
              {
                (this.props.currentProject.building_type === "아파트") &&
                <div className="project-field-wrap">
                  <div className="title">
                    대지안의공지 (아파트)
                    <InfoOutlinedIcon className="info-icon" onClick={e => this.showBuildingLaw("F")}/>
                  </div>
                  <div className="project-field-group">
                    <div className="project-field m-r-a">
                      <ProjectFieldInput 
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_건축선후퇴 : undefined}
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`건축선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_building_line_apartment"
                        fieldValue={this.props.currentProject.setback_building_line_apartment}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                    <div className="project-field">
                      <ProjectFieldInput 
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_인접대지경계선후퇴 : undefined}
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`인접대지경계선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_site_boundary_apartment"
                        fieldValue={this.props.currentProject.setback_site_boundary_apartment}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                  </div>
                </div>
              }
              {
                (this.props.currentProject.building_type === "연립주택") &&
                <div className="project-field-wrap">
                  <div className="title">
                    대지안의공지 (연립주택)
                    <InfoOutlinedIcon className="info-icon" onClick={e => this.showBuildingLaw("F")}/>
                  </div>
                  <div className="project-field-group">
                    <div className="project-field m-r-a">
                      <ProjectFieldInput 
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_건축선후퇴 : undefined}
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`건축선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_building_line_row_house"
                        fieldValue={this.props.currentProject.setback_building_line_row_house}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                    <div className="project-field">
                      <ProjectFieldInput 
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_인접대지경계선후퇴 : undefined}
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`인접대지경계선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_site_boundary_row_house"
                        fieldValue={this.props.currentProject.setback_site_boundary_row_house}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                  </div>
                </div>
              }
              {
                (this.props.currentProject.building_type === "다세대주택") &&
                <div className="project-field-wrap">
                  <div className="title">
                    대지안의공지 (다세대주택)
                    <InfoOutlinedIcon className="info-icon" onClick={e => this.showBuildingLaw("F")}/>
                  </div>
                  <div className="project-field-group">
                    <div className="project-field m-r-a">
                      <ProjectFieldInput
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_건축선후퇴 : undefined} 
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`건축선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_building_line_multi_house"
                        fieldValue={this.props.currentProject.setback_building_line_multi_house}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                    <div className="project-field">
                      <ProjectFieldInput 
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_인접대지경계선후퇴 : undefined} 
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`인접대지경계선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_site_boundary_multi_house"
                        fieldValue={this.props.currentProject.setback_site_boundary_multi_house}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                  </div>
                </div>
              }
              {
                (this.props.currentProject.building_type === "오피스텔") &&
                <div className="project-field-wrap">
                  <div className="title">
                    대지안의공지 (오피스텔)
                    <InfoOutlinedIcon className="info-icon" onClick={e => this.showBuildingLaw("F")}/>
                  </div>
                  <div className="project-field-group">
                    <div className="project-field m-r-a">
                      <ProjectFieldInput 
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_건축선후퇴 : undefined} 
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`건축선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_building_line_officetel"
                        fieldValue={this.props.currentProject.setback_building_line_officetel}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                    <div className="project-field">
                      <ProjectFieldInput 
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_인접대지경계선후퇴 : undefined}
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`인접대지경계선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_site_boundary_officetel"
                        fieldValue={this.props.currentProject.setback_site_boundary_officetel}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                  </div>
                </div>
              }
              {
                (this.props.currentProject.building_type === "도시형생활주택 - 원룸") &&
                <div className="project-field-wrap">
                  <div className="title">
                    대지안의공지 (도시형생활주택 - 원룸)
                    <InfoOutlinedIcon className="info-icon" onClick={e => this.showBuildingLaw("F")}/>
                  </div>
                  <div className="project-field-group">
                    <div className="project-field m-r-a">
                      <ProjectFieldInput 
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_건축선후퇴 : undefined}
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`건축선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_building_line_city_apartment"
                        fieldValue={this.props.currentProject.setback_building_line_city_apartment}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                    <div className="project-field">
                      <ProjectFieldInput 
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_인접대지경계선후퇴 : undefined}
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`인접대지경계선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_site_boundary_city_apartment"
                        fieldValue={this.props.currentProject.setback_site_boundary_city_apartment}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                  </div>
                </div>
              }
              {
                (this.props.currentProject.building_type === "도시형생활주택 - 연립주택") &&
                <div className="project-field-wrap">
                  <div className="title">
                    대지안의공지 (도시형생활주택 - 연립주택)
                    <InfoOutlinedIcon className="info-icon" onClick={e => this.showBuildingLaw("F")}/>
                  </div>
                  <div className="project-field-group">
                    <div className="project-field m-r-a">
                      <ProjectFieldInput 
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_건축선후퇴 : undefined}
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`건축선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_building_line_city_row_house"
                        fieldValue={this.props.currentProject.setback_building_line_city_row_house}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                    <div className="project-field">
                      <ProjectFieldInput
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_인접대지경계선후퇴 : undefined} 
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`인접대지경계선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_site_boundary_city_row_house"
                        fieldValue={this.props.currentProject.setback_site_boundary_city_row_house}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                  </div>
                </div>
              }
              {
                (this.props.currentProject.building_type === "도시형생활주택 - 다세대주택") &&
                <div className="project-field-wrap">
                  <div className="title">
                    대지안의공지 (도시형생활주택 - 다세대주택)
                    <InfoOutlinedIcon className="info-icon" onClick={e => this.showBuildingLaw("F")}/>
                  </div>
                  <div className="project-field-group">
                    <div className="project-field m-r-a">
                      <ProjectFieldInput 
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_건축선후퇴 : undefined}
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`건축선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_building_line_city_multi_house"
                        fieldValue={this.props.currentProject.setback_building_line_city_multi_house}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                    <div className="project-field">
                      <ProjectFieldInput
                        showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                        defaultValue={this.props.initDefault ? this.props.initDefault.대지안의_공지_인접대지경계선후퇴 : undefined} 
                        onMouseDown={this.inputMouseDown}
                        type="number"
                        title={`인접대지경계선후퇴`}
                        unit="m"
                        className="width-half"
                        fieldName="setback_site_boundary_city_multi_house"
                        fieldValue={this.props.currentProject.setback_site_boundary_city_multi_house}
                        placeholder="입력"
                        step={0.1}
                        {...this.props}
                      />
                    </div>
                  </div>
                </div>
              }
              {
                (this.props.currentProject.building_type === "미정" || !this.props.currentProject.building_type) &&
                <span className="font font-special font-14px">건물타입 설정이 필요합니다.</span>
              }
            </Fragment>
          }
        </div>
        {
          this.state.openModal &&
          <Modal
            open={this.state.openModal}
            type={this.state.modalOptions && this.state.modalOptions.type && this.state.modalOptions.type || "SIMPLE"}
            positive={this.state.modalOptions && this.state.modalOptions.positive}
            negative={this.state.modalOptions && this.state.modalOptions.negative}
            title={this.state.modalOptions && this.state.modalOptions.title}
            negativeTitle={this.state.modalOptions && this.state.modalOptions.negativeTitle}
            positiveTitle={this.state.modalOptions && this.state.modalOptions.positiveTitle}
            content={this.state.modalOptions && this.state.modalOptions.content}
            color={this.state.modalOptions && this.state.modalOptions.color || "DARK"}
          />
        }
      </div>
      </Fragment>
      
      
    )
  }

  setModal = (open: boolean, options?: ModalOptions) => {
    this.setState({
      openModal: open,
      modalOptions: options
    });
  }

  showBuildingLaw = (initail_type?: Laws) => {
    this.setModal(true, {
      title: "건축법규",
      type: "NORMAL",
      positive: () => {
        this.setModal(false);
      },
      negative: "hidden",
      content: <BuildingLaws initial_type={initail_type}/>
    })
  }
}
