import React, { Component } from 'react';
import { default as RcSwitch } from 'rc-switch';
import '../node_modules/rc-switch/assets/index.css';
import './css/Switch.scss';

export interface SwitchProps {
  defaultChecked?: boolean;
  className?: string;
  checked?: boolean;
  name?: string;
  projectType?: string;
  onClick?: (checked: boolean) => void;
}

export interface SwitchState {
  checked: boolean;
}

export default class Switch extends Component<SwitchProps, SwitchState> {
  state: SwitchState = {
    checked: this.props.defaultChecked === undefined ? (this.props.checked === undefined ? false : this.props.checked) : this.props.defaultChecked,
  }

  componentDidUpdate = (pp: Readonly<SwitchProps>) => {
    if (this.props.checked !== undefined) {
      if (this.props.checked !== pp.checked) {
        this.setState({checked: this.props.checked});
      }
    }
  }

  render() {
    return (
      <div className={`Switch ${this.props.className || ""} `}>
        <RcSwitch 
        className={`${this.props.projectType || ""}`}
          checked={this.state.checked}
          onClick={(checked) => this.onClick(checked)}
        />
        {
          this.props.name && <span className="name">{this.props.name}</span>
        }
      </div>
    )
  }

  onClick = (checked: boolean) => {
    this.props.onClick && this.props.onClick(checked);
  }
}