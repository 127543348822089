import React, { Component } from 'react';
import '../css/CADConverter/ErrorLog.scss';
import CloseIcon from '@material-ui/icons/Close';
import ListOutlined from '@material-ui/icons/ListOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import * as THREE from '@teneleven/three';
import { ConverterLayer } from './DataTypes';
import { brightenAllLayer, darkenAllLayer } from './CoreAndHouseController';

export enum ErrorType {
  All = 'all',
  Error = 'Error',
  Warning = 'Warning'
}

export interface ErrorLogCell {
  Type: ErrorType,
  Information: string,
  subInfomation: string
  hilightPolygon: THREE.Group,
  hilightLayer: ConverterLayer[],
}

interface ErrorLogState {
  ErrorType: ErrorType,
}

interface ErrorLogPorp {
  show: boolean,
  errorSize: number,
  warningSize: number,
  logs: ErrorLogCell[][],
  closeLog: () => void,
  brightenAllLayer: () => void,
  darkenAllLayer: () => void,
}

export function makeErrorInformation(information: string, subInfomation?: string, hilightPolygon?: THREE.Group, hilightLayer?: ConverterLayer[]): ErrorLogCell {
  return {
    Information: information,
    Type: ErrorType.Error,
    subInfomation: subInfomation ? subInfomation : '',
    hilightPolygon: hilightPolygon ? hilightPolygon : new THREE.Group(),
    hilightLayer: hilightLayer ? hilightLayer : [],
  }
}

export function makeWarningInformation(information: string, subInfomation?: string, hilightPolygon?: THREE.Group, hilightLayer?: ConverterLayer[]): ErrorLogCell {
  return {
    Information: information,
    Type: ErrorType.Warning,
    subInfomation: subInfomation ? subInfomation : '',
    hilightPolygon: hilightPolygon ? hilightPolygon : new THREE.Group(),
    hilightLayer: hilightLayer ? hilightLayer : [],
  }
}

export class ErrorLog extends Component<ErrorLogPorp, ErrorLogState> {
  state: ErrorLogState = {
    ErrorType: ErrorType.All,
  }

  showErrorPolygon = (errorGroup: THREE.Group) => {
    errorGroup.children.forEach(c => {
      c.visible = true;
    })
  }

  hideErrorPolygon = (errorGroup: THREE.Group) => {
    errorGroup.children.forEach(c => {
      c.visible = false;
    })
  }

  render = () => {
    return (
      <div className={`errorLog`}>
        <div className='errorLogHead'>
          <span className='headText'>Error log</span>
          <CloseIcon className='icon' onClick={this.props.closeLog} />
        </div>
        <div className='errorLogBody'>
          <div className='category'>
            <div className={`cell ${this.state.ErrorType === ErrorType.All && 'active'} ${this.props.errorSize + this.props.warningSize === 0 && 'hidden'}`} onClick={() => this.setState({ ErrorType: ErrorType.All })}>
              <ListOutlined className='icon' />
              <span className='text'>{this.props.errorSize + this.props.warningSize} messages</span>
            </div>
            <div className={`cell ${this.state.ErrorType === ErrorType.Error && 'active'} ${this.props.errorSize === 0 && 'hidden'}`} onClick={() => this.setState({ ErrorType: ErrorType.Error })}>
              <ErrorIcon className='icon red' />
              <span className='text'>{this.props.errorSize} error</span>
            </div>
            <div className={`cell ${this.state.ErrorType === ErrorType.Warning && 'active'} ${this.props.warningSize === 0 && 'hidden'}`} onClick={() => this.setState({ ErrorType: ErrorType.Warning })}>
              <WarningIcon className='icon yellow' />
              <span className='text'>{this.props.warningSize} warning</span>
            </div>
          </div>
          <div className='errorList'>
            {this.props.logs.map(errors => {
              return errors.map(error => {
                return ((this.state.ErrorType === error.Type || this.state.ErrorType === ErrorType.All) &&
                  <div key={error.Information} className={`info ${error.Type === ErrorType.Error && 'error' || 'warning'}`}
                    onMouseOver={() => { this.props.darkenAllLayer(); this.showErrorPolygon(error.hilightPolygon); brightenAllLayer(error.hilightLayer) }}
                    onMouseOut={() => { this.hideErrorPolygon(error.hilightPolygon); darkenAllLayer(error.hilightLayer); this.props.brightenAllLayer(); }}
                  >
                    {error.Type === ErrorType.Error && <ErrorIcon className='icon red' /> || <WarningIcon className='icon yellow' />}
                    <span>
                      <div className='text'> {error.Information}</div>
                      <div className='text sub'>{error.subInfomation}</div>
                    </span>
                  </div>)
              })
            })}
          </div>
        </div>
      </div>
    )
  }
}