import React, { Component, Fragment } from 'react';
import './css/ProjectIcon.scss';
import { ProjectStatus, ProjectType } from './model/Project';

import ConfigIcon from '@material-ui/icons/Tune';
import RunIocn from '@material-ui/icons/HourglassEmpty';
import FinishedIcon from '@material-ui/icons/Check';
import FailedIcon from '@material-ui/icons/ErrorOutline';
import FavoriteProjectIconActive from '@material-ui/icons/Star';
import FavoriteProjectIcon from '@material-ui/icons/StarBorder';
import DiscardProjectIcon from '@material-ui/icons/Delete';
import { Tooltip } from '@material-ui/core';

export interface ProjectIconProps {
  className?: string;
  iconClassName?: string;
  status?: ProjectStatus;
  type?: ProjectType;
  discard?: boolean;
  favorite?: boolean | "tab";
  onTooltip?: boolean;
  onText?: boolean;
}
export interface ProjectIconState {
  
}

export default class ProjectIcon extends Component<ProjectIconProps, ProjectIconState> {
  render() {
    if (this.props.status !== undefined) {
      return (
        <div className={`ProjectIcon status ${this.props.className || ""}`}>
          {
            this.props.status === "CONFIG" && 
            <Fragment>
              <ConfigIcon className={`icon status-icon m-r-sm config ${this.props.iconClassName || ""}`} />
              <span className="status-text config">{ this.props.onText && "설정중 프로젝트" || ""}</span>
            </Fragment>
            
          }
          { 
            this.props.status === "WAITING" && 
            <Fragment>
              <RunIocn className={`icon status-icon m-r-sm waiting ${this.props.iconClassName || ""}`} />
              <span className="status-text waiting">{ this.props.onText && "분석중 프로젝트" || ""}</span>
            </Fragment>
          }
          { 
            this.props.status === "RUNNING" && 
            <Fragment>
              <RunIocn className={`icon status-icon m-r-sm running ${this.props.iconClassName || ""}`} />
              <span className="status-text running">{ this.props.onText && "분석중 프로젝트" || ""}</span>
            </Fragment>
          }
          { 
            this.props.status === "FINISHED" &&
            <Fragment>
              <FinishedIcon className={`icon status-icon m-r-sm finished ${this.props.iconClassName || ""}`} />
              <span className="status-text finished">{ this.props.onText && "완료된 프로젝트" || ""}</span>
            </Fragment>
          }
          { 
            this.props.status === "FAILED" && 
            <Fragment>
              <FailedIcon className={`icon status-icon m-r-sm failed ${this.props.iconClassName || ""}`} />
              <span className="status-text failed">{ this.props.onText && "확인요 프로젝트" || ""}</span>
            </Fragment>
          }
        </div>  
      )
    } else if (this.props.type !== undefined) {
      return (
        <div className={`ProjectIcon project-type ${this.props.className || ""}`}>
        </div>
      )
    } else if (this.props.discard !== undefined) {
      return (
        <div className={`ProjectIcon discard ${this.props.className || ""}`}>
          <DiscardProjectIcon className={`icon discard-icon ${this.props.iconClassName || ""}`} />
        </div>
      )
    } else if (this.props.favorite === "tab") {
      return (
        <div className={`ProjectIcon favorite ${this.props.className || ""}`}>
        {
          this.props.favorite === "tab" &&
          <FavoriteProjectIconActive className={`icon favorite-icon ${this.props.iconClassName || ""}`} />
        }
        </div>
      )
    } else if (typeof this.props.favorite === "boolean") {
      return (
        <div className={`ProjectIcon favorite ${this.props.className || ""}`}>
        {
          this.props.favorite === true &&
          <FavoriteProjectIconActive className={`icon favorite-icon ${this.props.iconClassName || ""}`} /> ||
          <FavoriteProjectIcon className={`icon favorite-icon ${this.props.iconClassName || ""}`} />
        }
        </div>
      )
    }
    else {
      return (
        <div></div>
      )
    }
  }
}

export interface TooltipProjectIconProps extends ProjectIconProps {
  tooltip: string;
}
export class TooltipProjectIcon extends Component<TooltipProjectIconProps, ProjectIconState> {
  render() {
    return (
      <Tooltip title={this.props.tooltip}>
        <div>
          <ProjectIcon {...this.props} />
        </div>
      </Tooltip>
    )
  }
}