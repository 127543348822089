import React, { Component } from 'react';
import { ConverterLayer, PartOfSelect, makeHouseState } from "./DataTypes";
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import { ConverterInputBox } from './ConverterInputBox';
import { switchLayerState, CheckColsedPolygon, brightenAllLayer } from './CoreAndHouseController';
import '../css/CADConverter/HouseAndCore.scss';
import { House } from './House';

interface houseUIState {
  name: string,
  exclusiveArea: number; // 전용 면적
  serviceArea: number; // 발코니 면적
  balconyOver150cm: number;
  balconyLess150cm: number;
  commonWallArea: number;
  extraMenu: boolean;
  level: number;
  levleHeight: number;
  piloti: number;
  part: PartOfSelect,
  setting: boolean,
  id: string,
  hideList: boolean,
}

interface houseUIProp {
  showLayerList: (id: string, partOfHouse: PartOfSelect) => void,
  removeHouse: (house: House) => void,
  valueChanged: () => void,
  showModel: (information: string) => void,
  brightenAllLayer?: () => void,
  darkenAllLayer?: () => void,
  house: House,
  selectLayer: ConverterLayer | null,
  settingID: string,
  isSettingLevel?: boolean,
  editable: boolean,
}

export class HouseUI extends Component<houseUIProp, houseUIState> {
  state: houseUIState = {
    name: this.props.house.name,
    exclusiveArea: this.props.house.exclusiveArea, // 전용 면적
    serviceArea: this.props.house.serviceArea, // 발코니 면적
    balconyOver150cm: this.props.house.balconyOver150cm,
    balconyLess150cm: this.props.house.balconyLess150cm,
    commonWallArea: 0,
    piloti: this.props.house.piloti,
    level: this.props.house.level.length,
    levleHeight: this.props.house.levelHeights[0],
    part: PartOfSelect.wall,
    extraMenu: false,
    setting: false,
    id: this.props.house.id,
    hideList: false,
  }

  componentDidMount = async () => {

  }

  componentDidUpdate = (previousProps: Readonly<houseUIProp>, previousState: Readonly<houseUIState>) => {
    if (this.props.house.id !== this.props.settingID)
      return;

    if (previousProps.selectLayer !== this.props.selectLayer) {
      switch (this.state.part) {
        case PartOfSelect.wall:
          this.setWall(this.props.selectLayer);
          break;
        case PartOfSelect.lightWindow:
          this.setLightWindow(this.props.selectLayer);
          break;
        case PartOfSelect.normalWindow:
          this.setNormalWindow(this.props.selectLayer);
          break;
        default:
          break;
      }

      this.makeHousePolygon();
      this.props.valueChanged();
    }
  }

  setWall = (layer: ConverterLayer | null) => {
    if (!layer)
      return;

    if (this.props.selectLayer!.selected && this.props.selectLayer !== this.props.house.wall) {
      this.props.showModel('이미 선택되어 있는 레이어입니다.');
      return;
    }

    if (!CheckColsedPolygon(layer)) {
      // setErrorColorForLayer(layer);
      this.props.showModel('닫친 폴리곤이 아닙니다.');
      return;
    }

    this.props.house.setWallLayer(layer);
  }

  setLightWindow = (layer: ConverterLayer | null) => {
    if (!layer) return;

    if (this.props.selectLayer!.selected && this.props.selectLayer !== this.props.house.lightWindow) {
      this.props.showModel('이미 선택되어 있는 레이어입니다.');
      return;
    }

    this.props.house.setLightWindow(layer);
  }

  setNormalWindow = (layer: ConverterLayer | null) => {
    if (!layer) return;

    if (this.props.selectLayer!.selected && this.props.selectLayer !== this.props.house.normalWindow) {
      this.props.showModel('이미 선택되어 있는 레이어입니다.');
      return;
    }

    this.props.house.setNormalWindow(layer);
  }

  makeHousePolygon = () => {
    this.props.house.makeHousePolygon();
    if (this.props.house.makeState !== makeHouseState.Finish)
      this.props.showModel(`${this.props.house.name}에 생성 안 된 창문 라인이 있습니다.`);
  }

  showLayerList = (part: PartOfSelect) => {
    this.props.showLayerList(this.props.house.id, part);
    this.setState({ part: part })
  }

  setExclusiveArea = (value: number) => {
    this.props.house.setExclusiveArea(value);
    this.props.valueChanged();
  }

  setServiceArea = (value: number) => {
    this.props.house.setServiceArea(value);
    this.props.valueChanged();
  }

  setBalconyOver150cm = (value: number) => {
    this.props.house.setBalconyOver150cm(value);
    this.props.valueChanged();
  }

  setCommonWallArea = (value: number) => {
    this.props.house.setCommonWallArea(value);
    this.props.valueChanged();
  }

  //to-do: need rebuild function
  checkSelectState = (partOfHouse: PartOfSelect) => {
    return this.props.settingID === this.props.house.id && this.state.part === partOfHouse;
  }

  deleteWall = () => {
    switchLayerState(this.props.house.wall);
    this.props.house.wall = null;

    this.makeHousePolygon();
    this.props.valueChanged();
  }

  deleteLightWindow = () => {
    switchLayerState(this.props.house.lightWindow);
    this.props.house.lightWindow = null;

    this.makeHousePolygon();
    this.props.valueChanged();
  }

  deleteNormalWindow = () => {
    switchLayerState(this.props.house.normalWindow);
    this.props.house.normalWindow = null;

    this.makeHousePolygon();
    this.props.valueChanged();
  }

  render = () => {
    return (
      <div className='house' onMouseOver={() => { this.props.darkenAllLayer!(); brightenAllLayer(this.props.house.getSettedLayer()) }} onMouseOut={() => this.props.brightenAllLayer!()}>
        <div className='nameDiv' onClick={() => this.setState({ hideList: !this.state.hideList })}>{(this.state.hideList && <DownIcon className='showIcon iconRight' />) || <UpIcon className='showIcon iconRight' />}{this.state.name}
          <CloseIcon className='closeIcon' onClick={() => { if (this.props.editable) this.props.removeHouse(this.props.house) }} />
        </div>
        <div hidden={this.state.hideList}>
          <div className='buttonDiv'>
            <div className={`selectButton ${this.checkSelectState(PartOfSelect.wall) && 'selected' || 'unselected'}`} onClick={() => this.showLayerList(PartOfSelect.wall)} >
              <span className='text'>벽 레이어 선택</span>
              <CheckIcon className={`CheckIcon ${this.props.house.wall && 'checked'}`} />
            </div>
            <div className='nameText'>
              {this.props.house.wall && this.props.house.wall.name}
              <CloseIcon className={`closeIcon ${(this.checkSelectState(PartOfSelect.wall) && this.props.house.wall) && 'selected' || 'unselected'} ${this.props.house.wall && 'show' || 'hide'}`} onClick={this.deleteWall} />
            </div>
          </div>
          <div className='buttonDiv'>
            <div className={`selectButton ${this.checkSelectState(PartOfSelect.lightWindow) && 'selected' || 'unselected'}`} onClick={() => this.showLayerList(PartOfSelect.lightWindow)} >
              <span>채광 창 레이어 선택</span>
              <CheckIcon className={`CheckIcon ${this.props.house.lightWindow && 'checked'}`} />
            </div>
            <div className='nameText'>
              {this.props.house.lightWindow && this.props.house.lightWindow.name}
              <CloseIcon className={`closeIcon  ${(this.checkSelectState(PartOfSelect.lightWindow) && this.props.house.lightWindow) && 'selected' || 'unselected'} ${this.props.house.wall && 'show' || 'hide'}`} onClick={this.deleteLightWindow} />
            </div>
          </div>
          <div className='buttonDiv'>
            <div className={`selectButton ${this.checkSelectState(PartOfSelect.normalWindow) && 'selected' || 'unselected'}`} onClick={() => this.showLayerList(PartOfSelect.normalWindow)}>
              <span>일반 창 레이어 선택</span>
              <CheckIcon className={`CheckIcon ${this.props.house.normalWindow && 'checked'}`} />
            </div>
            <div className='nameText'>
              {this.props.house.normalWindow && this.props.house.normalWindow.name}
              <CloseIcon className={`closeIcon ${(this.checkSelectState(PartOfSelect.normalWindow) && this.props.house.normalWindow) && 'selected' || 'unselected'} ${this.props.house.wall && 'show' || 'hide'}`} onClick={this.deleteNormalWindow} />
            </div>
          </div>
          <div className='inputValues'>
            <ConverterInputBox name='전용 면적' value={this.props.house.exclusiveArea} valueOnChange={this.setExclusiveArea} unit='㎡' step={0.01} />
            <ConverterInputBox name='벽체 공용 면적' value={this.props.house.commonWallArea} valueOnChange={this.setCommonWallArea} unit='㎡' step={0.01} />
          </div>
          <div style={{ position: 'relative', width: 'fit-content' }}>
            <div className='extraMenu' onClick={() => this.setState({ extraMenu: !this.state.extraMenu })}>자세히+</div>
            <ConverterInputBox name='발코니 면적' value={this.props.house.serviceArea} valueOnChange={this.setServiceArea} unit='㎡' step={0.01} />
          </div>
          <div className={`inputValues ${!this.state.extraMenu && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
            <ConverterInputBox name='서비스 면적' value={this.props.house.balconyLess150cm} valueOnChange={this.setBalconyOver150cm} disable={true} unit='㎡' step={0.1} />
            <ConverterInputBox name='용적율 포함 면적' value={this.props.house.balconyOver150cm} valueOnChange={this.setBalconyOver150cm} unit='㎡' step={0.01} />
          </div>
          <div className={`inputValues ${!this.props.isSettingLevel && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
            <ConverterInputBox name='층수' value={this.props.house.level.length} valueOnChange={this.props.house.setLevel} unit='층' />
            <ConverterInputBox name='층고' value={this.props.house.levelHeights[0]} valueOnChange={this.props.house.setLevelHeight} unit='m' step={0.01} />
          </div>
          <div className={`inputValues ${!this.props.isSettingLevel && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
            <ConverterInputBox name='필로티' value={this.props.house.piloti} valueOnChange={this.props.house.setPiloti} unit='층' />
          </div>
        </div>
        <div className='Line' />
      </div >
    )
  }
}
