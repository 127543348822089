import { sampleProject1, sampleProject2, sampleProject3 } from '../SampleData';
import Axios from 'axios';

export async function getURLData(url: string, binary = false) {
  if (binary) {
    let r = await Axios.get(url, {
      responseType: 'arraybuffer',
    });
    return Buffer.from(r.data);
  } else {
    let r = await Axios.get(url);
    return r.data;
  }
}

export function getProjectData(projectID: number) {
  switch (projectID) {
    case 1:
      return sampleProject1.project;
    case 2:
      return sampleProject2.project;
    case 3:
      return sampleProject3.project;
    default:
      return sampleProject1.project;
  }
}

export function getResultData(projectID: number) {
  switch (projectID) {
    case 1:
      return sampleProject1.result;
    case 2:
      return sampleProject2.result;
    case 3:
      return sampleProject3.result;
    default:
      return sampleProject1.result;
  }
}

export function getURL(projectID: number, fileName: string) {
  return `https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/sample_report/sample${projectID}/3DViewer/${fileName}`;
}