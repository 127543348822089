import React, { Component } from 'react';
import _ from 'lodash';
// @ts-ignore
import c3 from 'c3';
import './css/C3Chart.scss';
import jquery from 'jquery';
const $ = jquery;
const d3 = require('d3');

export interface C3ChartProps {
  data?: any,
  onClick?: () => void,
  onMouseOver?: () => void,
  onMouseOut?: () => void,
  type?: string,
  config?: Object,
  className?: string,
  customLegend?: {
    show: boolean,
    position: "right" | "bottom",
    id: string,
  },
} 
export interface C3ChartState {}

export default class C3Chart extends Component<C3ChartProps, C3ChartState> {

  c3Instance: any;
  chartContainer: any;
  chart: any = null;
  constructor(props: C3ChartProps) {
    super(props);

    this.c3Instance = c3;
    this.chart = null;
  }
  ref = (el: any) => this.chartContainer = el;

  setChart() {
    this.chartContainer.innerHTML = '';
    const { data, onClick, onMouseOver, onMouseOut, type, config } = this.props;
    if (this.props.onClick) {
      data['onclick'] = onClick;
    }
    if (this.props.onMouseOut) {
      data['onmouseout'] = onMouseOut;
    }
    if (this.props.onMouseOver) {
      data['onmouseover'] = onMouseOver;
    }

    if (this.props.customLegend && this.props.customLegend.show) {
      data['onmouseover'] = (a: any) => {
        const id = a.id;
        if (this.props.customLegend) {
          $($(`.${this.props.customLegend.id} > .C3Chart-legend`).children(`[data-id="${id}"]`)[0]).addClass('focused');
        }
      }
  
      data['onmouseout'] = (a: any) => {
        const id = a.id;
        if (this.props.customLegend) {
          $($(`.${this.props.customLegend.id} > .C3Chart-legend`).children(`[data-id="${id}"]`)[0]).removeClass('focused');
        }
      }
    }
    
    const chart = this.chart =  this.c3Instance.generate({
        bindto: this.chartContainer,
        data: {...data, type: type || 'pie' },
        ...config
    });
    const customLegend = this.props.customLegend;
    if (customLegend && customLegend.show) {
      d3.select('.C3Chart-legend').remove();
      d3.select(`.${customLegend.id}`).attr('class', `C3ChartWrapper ${customLegend.id} ${customLegend.position}`);

      d3.select(`.${customLegend.id}`).insert('div').attr('class', 'C3Chart-legend').selectAll('div')
        .data(data.columns && data.columns.map((r: any) => r[0]) || data.rows.map((r: any) => r[0]))
        .enter().append('div').attr('class', 'legend-item')
          .each(function (id: string, i: any) {
            const proportion = data.columns ? data.columns[i][1] : data.rows[i][1];
            // @ts-ignore
            d3.select(this).insert('div').attr('class', 'symbol').style('background-color', chart.color(id));
            
            // @ts-ignore
            d3.select(this).insert('div').attr('class', 'legend-id').html(id);
            // @ts-ignore
            d3.select(this).insert('div').attr('class', 'proportion').text(`${proportion}%`);
          }).on('mouseover', function (id: string) {
            // @ts-ignore
            $(this).addClass('focused');
            chart.focus(id);
          })
          .on('mouseout', function (id: string) {
            // @ts-ignore
            $(this).removeClass('focused');
            chart.revert();
          })
          .on('click', function (id: string) {
            chart.toggle(id);
          })
          .attr('data-id', function (id: string) {
            // @ts-ignore
            d3.select(this).attr('title', id)
            return id; 
          })
          //.append('div').attr('class', 'legend-id').html(function (id: string) { return id; })

    }
  }
  componentDidMount = () => {
    this.setChart();
  }

  componentWillUnmount = () => {
    if (this.chart !== null) {
      this.chart.destroy();
    }
  }

  componentDidUpdate = (pp: C3ChartProps) => {
    if (!_.isEqual(pp.data.rows, this.props.data.rows) || !_.isEqual(pp.data.columns, this.props.data.columns)) {
      this.chart.destroy();
      this.setChart();
    }
  }
  render () {
    return (
      <div className={`C3ChartWrapper ${this.props.customLegend && this.props.customLegend.show && this.props.customLegend.id || ""}`}>
        <div ref={this.ref} className={`C3Chart ${this.props.className && this.props.className || ""}`} />
      </div>
    );
  }
}