import React, { Component } from 'react';
import { ConverterType } from './DataTypes';
import '../css/CADConverter/LoadingPage.scss';

interface loadingPageProp {
  show: boolean,
  type: ConverterType
}

interface loadingPageState {

}

export class LoadingPage extends Component<loadingPageProp, loadingPageState>{
  render = () => {
    return <div className='loadingPage' style={{ display: (this.props.show && 'flex' || 'none') }}>
      <div className='progress'>
        <img src={'/img/icon/loading.png'} ></img>
      </div>
      <div className='centerWord'><div>BUILDIT</div> <div>CAD CONVERTER</div></div>
      <div className='loadingMessage'>
        <span>{this.props.type} 파일을 불러오는 중입니다.</span>
      </div>
    </div>
  }
}
