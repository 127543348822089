import React, { Component } from 'react';
import { Dialog, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import '../css/Visualizer.scss';
import { reportInformation } from './scene';
import { default as moment } from 'moment';

export interface ModalProps {
  reportInformation: reportInformation[],
  siteUseDistrict: string,
  siteArea: string,
  projectID: number,
  address: string,
  open: boolean,
  indexArray: number[],
}

interface ModalState {
  open: boolean,
}

export class InformationModal extends Component<ModalProps, ModalState> {
  state: ModalState = {
    open: false,
  }

  componentWillUpdate = (previousProps: Readonly<ModalProps>, previousState: Readonly<ModalState>,) => {
    if (previousProps.open !== this.props.open) {
      this.setState({
        open: true,
      })
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  getInfoById = (index: number) => {
    return this.props.reportInformation.find((e) => { return index === e.reportID; })
  }


  render = () => {
    return (
      <Dialog className='modalBody' aria-labelledby="simple-dialog-title" open={this.state.open}>
        <div className='modal-title' id="alert-dialog-title">
          <div className='title'>프로젝트 개요</div>
          <CloseIcon className="icon" onClick={this.handleClose} />
        </div>

        <div className='project_info'>
          <div className='info_parameter w175 rb'>
            <div className='info_title'>프로젝트 번호</div>
            <div className='info_value'>{this.props.projectID}</div>
          </div>
          <div className='info_parameter w360 rb'>
            <div className='info_title'>프로젝트 주소</div>
            <div className='info_value'>{this.props.address}</div>
          </div>
          <div className='info_parameter w175 rb'>
            <div className='info_title'>용도 지역</div>
            <div className='info_value'>{this.props.siteUseDistrict}</div>
          </div>
          <div className='info_parameter w175'>
            <div className='info_title'>대지 면적</div>
            <div className='info_value'>{this.props.siteArea.toLocaleString()}㎡</div>
          </div>
        </div>
        <div className='dividingLine' />
        <div className='info'>
          <div className='row'><div className='cell'></div>{this.props.indexArray!.map(r => <div key={r} className='cell reportNum'>{`${this.getInfoById(r) ? this.getInfoById(r)!.realReportID : 0}번 결과`}</div>)}</div>
          <div className='row'><div className='cell'>건폐율 : </div>{this.props.indexArray.map(r => <div key={r} className='cell'>{`${this.getInfoById(r) ? this.getInfoById(r)!.coreAreaRatio : 0}%`}</div>)}</div>
          <div className='row'><div className='cell'>용적률 : </div>{this.props.indexArray.map(r => <div key={r} className='cell'>{`${this.getInfoById(r) ? this.getInfoById(r)!.floorAreaRatio : 0}%`}</div>)}</div>
          <div className='row'><div className='cell'>평균층수(산술/면적/세대) :</div>
            {
              this.props.indexArray.map(r => {
                return <div key={r} className='cell'>{`${this.getInfoById(r) ? this.getInfoById(r)!.aveLevelNum : 0}층/${this.getInfoById(r) ? this.getInfoById(r)!.aveLevelArea : 0}층/${this.getInfoById(r) ? this.getInfoById(r)!.aveLevelHouse : 0}층`}</div>;
              })
            }</div>
          <div className='row'><div className='cell'>층 수(최고/최저) :</div>
            {
              this.props.indexArray.map(r => {
                return <div key={r} className='cell'>{`${this.getInfoById(r) ? this.getInfoById(r)!.maxLevel : 0}층`}</div>;
              })
            }</div>

          <div className='row'><div className='cell'>건물 수 : </div>{this.props.indexArray.map(r => <div key={r} className='cell'>{`${this.getInfoById(r) ? this.getInfoById(r)!.totalBuildingNumber : 0}동`}</div>)}</div>
          <div className='row'><div className='cell'>총 세대수 : </div>{this.props.indexArray.map(r => <div key={r} className='cell'>{`${this.getInfoById(r) ? this.getInfoById(r)!.totalHouse : 0}세대`} </div>)}</div>
          <div className='row'><div className='cell'>평균 일조시간 : </div>
            {
              this.props.indexArray.map(r => {
                return (
                  <div key={r} className='cell'>
                    {moment(0).utc().add(moment.duration(this.getInfoById(r) ? this.getInfoById(r)!.daylightHoursAvg : 0, 'hours')).format('HH시간 mm분')}
                  </div>
                )
              })
            }
          </div>
          <div className='row'><div className='cell'>최빈 일조시간 : </div>
            {
              this.props.indexArray.map(r => {
                return (
                  <div key={r} className='cell'>
                    {moment(0).utc().add(moment.duration(this.getInfoById(r) ? this.getInfoById(r)!.daylightHoursMin : 0, 'hours')).format('HH시간 mm분')}
                  </div>
                )
              })
            }
          </div>
          <div className='row'><div className='cell'>평균 조망점수 : </div>{this.props.indexArray.map(r => <div key={r} className='cell'>{`${this.getInfoById(r) ? this.getInfoById(r)!.viewPointAvg.toFixed(2) : 0}점`}</div>)}</div>
          <div className='row'><div className='cell'>예상 공사비 : </div>
            {
              this.props.indexArray.map(r => {
                return (
                  <div key={r} className='cell'>
                    {`${Math.round((this.getInfoById(r) ? this.getInfoById(r)!.coast : 0) / 1000).toLocaleString()}(천)원`}
                  </div>
                )
              })
            }
          </div>
        </div>
      </Dialog>
    )
  }
}
