import * as THREE from '@teneleven/three';
import { BuildingPart } from './BuildingPart';
import { Core } from './Core';
import { ErrorLogCell } from './ErrorLog';
import { House } from './House';

export enum entityType {
  LWPOLYLINE = 'LWPOLYLINE',
  LINE = 'LINE',
  SPLINE = 'SPLINE',
  POLYLINE = 'POLYLINE',
  INSERT = 'INSERT',
}

export enum PartOfSelect {
  wall = 'wall',
  lightWindow = 'lightWindow',
  normalWindow = 'normalWindow',
  core = 'core',
  house = 'house',
  field = 'field',
}

export enum areaType {
  exclusiveArea,
  serviceArea,
  coreArea,
  buildingArea,
  groundArea,
  roadArea,
  vacancyOutsizeArea,
  vacancyInsideArea,
  openSpaceArea,
  totalBuildingArea,
  totalGroundArea,
  exclusiveAverageArea,
}

// export enum InputBoxType {
//   exclusiveArea,
//   serviceArea,
//   balconyOver150cm,
//   balconyLess150cm,
//   commonWallArea,
//   level,
//   levelHeights,
//   piloti,
//   coreArea,
//   fieldArea,
// }

export interface BuildingTypeData {
  name: string;
  id: string;
  houses: House[];
  cores: Core[];
  showList: boolean;
  totalExclusiveAreas: number;
  totalServiceAreas: number;
  totalCoreAreas: number;
  buildingArea: number;
  groundArea: number;
  hideList: boolean;
}

export enum ListType {
  layer,
  house
}

export enum LayerType {
  site = 'site',
  road = 'road',
  vacancyOutsize = 'vacancy outsize',
  vacancyInside = 'vacancy inside',
  wall = 'wall',
  window = 'window',
  core = 'core',
  house = 'house',
  none = 'none'
}

export interface selectHouse {
  building: BuildingTypeData;
  house: House | Core;
  part: LayerType;
}

export interface ConverterLayer {
  name: string;
  visible: boolean;
  colorIndex: number;
  color: number;
  frozen: boolean;
  polygons: Polygon[];
  selected: boolean;
  isSinglePolygon: boolean;
  z_index: number;
  errorLayer: boolean;
}

export enum BlockType {
  core = 'core',
  house = 'house',
  group = 'group',
}

export interface ConverterEntity {
  layer: string;
  type: entityType;
}

export interface ConverterUnit extends ConverterEntity {
  polygon: Polygon;
  verts: THREE.Vector3[];
  shape: boolean;
  color: number;
}

export interface ConverterInsert extends ConverterEntity {
  position: THREE.Vector3;
  scale: THREE.Vector3;
  rotate: number;
  name: string;
}

export interface ConverterBuilding {
  parts: BuildingPart[];
  position: THREE.Vector3;
  name: string;
  renderGroup: THREE.Group;
}

export interface PolylineInfo {
  line: THREE.Line3;
  type: LineType;
  thickness: number;
}

export enum LineType {
  LT_EMPTY = 'LT_EMPTY',
  LT_WALL = 'LT_WALL',
  LT_OUTERWALL = 'LT_OUTERWALL',     //외벽
  LT_INNERWALL = 'LT_INNERWALL',     //내벽
  LT_COREOUTERWALL = 'LT_COREOUTERWALL',     //코어외벽
  LT_COREINNERWALL = 'LT_COREINNERWALL',     //코어내벽
  LT_SIDEWALL = 'LT_SIDEWALL',     //측벽

  LT_WINDOW = 'LT_WINDOW',   //내창(방)
  LT_INNERWINDOW = 'LT_INNERWINDOW',   //내창(방)
  LT_OUTERWINDOW = 'LT_OUTERWINDOW',   //외창
  LT_LIGHTWINDOW = 'LT_LIGHTWINDOW',   //채광창
  LT_VIEWWINDOW = 'LT_VIEWWINDOW',   //조망창
  LT_LVWINDOW = 'LT_LVWINDOW',   //조망+채광창

  LT_DOOR = 'LT_DOOR',  //방문
  LT_SLIDE = 'LT_SLIDE',  //슬라이드
  LT_ENTRANCE = 'LT_ENTRANCE',  //현관문
  LT_GATE = 'LT_GATE',  //건물출입문
}

export enum PolygonType {
  PT_OUTLINE = 'PT_OUTLINE',

  PT_ENTRANCE = 'PT_ENTRANCE', //현관
  PT_UTILITY = 'PT_UTILITY', //다용도실
  PT_DRESS = 'PT_DRESS', //드레스룸
  PT_REST = 'PT_REST', //화장실
  PT_BALCONY = 'PT_BALCONY', //발코니
  PT_BED = 'PT_BED', //침실
  PT_DINING = 'PT_DINING', //주방
  PT_KITCHEN = 'PT_KITCHEN', //부엌
  PT_LIVING = 'PT_LIVING', //거실
  PT_STRUCT = 'PT_STRUCT',//설비실

  PT_ENTEXCL = 'PT_ENTEXCL',//현관앞공간

  PT_CORE = 'PT_CORE',//코어
  PT_ELEVATOR = 'PT_ELEVATOR',//엘리베이터
  PT_STAIRWAY = 'PT_STAIRWAY',//계단실

  PT_EMPTY = 'PT_EMPTY',//임시
}

export interface Polygon {
  lineMesh: any;
  plane?: THREE.Mesh;
  vertices: THREE.Vector3[];
  innerMesh: THREE.Mesh;
  area: number;
  type: entityType;
  motherPolygon?: Polygon;
  selected: boolean;
  shape: boolean;
  hasCurve: boolean;
  layer?: string;
}

interface dbDataStruct {
  stage: string,
  global_id: string,
  user_id: string,
  email: string,
  name: string
  created_at: string,
  modified_at: string,
  deleted: boolean
  img_path: string,
}

export interface buildingTypeStruct extends dbDataStruct {
  total_exclusive_area: number,
  total_service_area: number,
  common_wall_area: number,
  uuid: string,
  core_area: number,
  building_area: number,
  floor_area: number,
  houses_number: number,
  file_path: string,
  meta_path: string,
}

export interface buildingPlanStruct extends dbDataStruct {
  building_number: number,
  vacancy_inside: string | any[],//특수영역
  vacancy_inside_area: number,
  vacancy_outside: string | any[],//공지영역
  vacancy_outside_area: number,
  project_site: string | any[],
  project_site_area: number,
  road_site: string | any[],
  road_site_area: number,
  total_floor_area: number,
  total_building_area: number,
  average_exclusive_area: number,
  cover_area_ratio: number,
  floor_area_ratio: number,
  address: string,
  shapeInfo: string,
  resultJson: string,
  resultLocation: string,
}

export interface siteStruct extends dbDataStruct {
  vacancy_inside: string | any[], //특수영역
  vacancy_inside_area: number,
  vacancy_outside: string | any[], //공지영역
  vacancy_outside_area: number,
  project_site: string | any[],
  project_site_area: number,
  road_site: string | any[],
  road_site_area: number,
  shapeInfo: string,
  address: string,
  centerOfRoad: string[],
  topographyLines: { polygon: string[], height: number }[]
}

export interface resultLocation {
  total_household: number,
  cover_area_ratio: number,
  floor_area_ratio: number,
  building_number: number,
  construction_cost: number,
}

export interface areaProportion {
  house_plan_type_area: number,
  number_of_bay: number,
  housing_plan_type_proportion: number,
  housing_plan_type_number_min: number,
}


export interface lineStruct {
  linetype: LineType,
  points: [THREE.Vector3, THREE.Vector3],
  thickness: number,
}

export interface houseStruct {
  lines: lineStruct[];
  group: number,
  category: string,
  type: string,
  housesInCore?: number,
  piloti?: number,
  areaInformation: houseAreaStruct,
  rooms?: any[],
}

export interface houseAreaStruct {
  exclusiveArea?: number,
  coreArea?: number,
  serviceArea?: number,
  balconyLess150cm?: number,
  balconyOver150cm?: number,
}

export enum SiteType {
  Apartment,
  Officetel
}

export interface floorStatus {
  name: string,
  category: string,
  level: boolean[],
  levelHeights: number[],
}

export interface buildingTemplate {
  houseline: houseStruct[],
  name: string,
  rotateCenter: THREE.Vector3,
  groupTotal: number,
  coreTotal: number[],
  houseTotal: number[],
  nodes: { x: number, y: number }[],
  angleRange: number[],
}

export interface templateList {
  template: buildingTemplate,
  floorStatus: floorStatus[],
}

export interface buildingData {
  position: THREE.Vector3,
  rotateAngle: number,
  templateList: templateList[],
}

export interface mapProjectionData {
  projection: any,
  mapOffset: THREE.Vector2,
}

export interface screenInfo {
  offset: DOMRect,
  mvp: THREE.Matrix4,
  rendererSize: THREE.Vector2
}

export interface DEMData {
  leftBottom: THREE.Vector2,
  values: number[][],
}

export enum makeHouseState {
  Finish,
  lightWindowError,
  outerWindowError,
  allWindowError,
  Error,
}

export enum saveState {
  complete,
  error,
  warning,
}

export enum BuildingTypeAlertType {
  complete,
  shapeError,
  curveError,
  scaleError,
  windowError,
  apartPolygonWarning,
  overlapPolygonWarning,
  areaOffset3Warning,
}

export enum CompletenessType {
  complete,
  warning,
  error,
}

export enum Unit {
  // Unitless,
  Inches = 'inch',
  // Feet,
  // Miles,
  Millimeters = 'mm',
  Centimeters = 'cm',
  Meters = 'm',
  Kilometers = 'km',
  // Microinches,
  // Mils,
  // Yards,
  // Angstroms,
  // Nanometers,
  // Microns,
  // Decimeters,
  // Decameters,
  // Hectometers,
  // Gigameters,
  // AstronomicalUnits,
  // LightYears,
  // Parsecs
}

export enum ConverterType {
  myType = '동평면',
  mySite = '사업영역',
  myPlane = '배치안'
}

let wallMaterial = new THREE.MeshLambertMaterial({ color: new THREE.Color(0xffffff) });
let coreMaterial = new THREE.MeshLambertMaterial({ color: new THREE.Color(0x270a04) });
let windowMaterial = new THREE.MeshLambertMaterial({ color: new THREE.Color(0x00ffff) });

export { wallMaterial, coreMaterial, windowMaterial };

