import React, { Component, Fragment } from 'react';
import Template from './Template';
import MapView from './MapView';
import { ProjectJS } from '@teneleven/protocols-ts-web/lib/project';
import { Drawer, Button, TextField } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import SessionComponent from './SessionComponent';
import { ListProjectRequestJS } from '@teneleven/protocols-ts-web/lib/db_manager';
import App from './App';

export interface TestProjectState {
  configOpen: boolean;
  projects?: ProjectJS[];
}
export interface TestProjectProps {

}

export default class TestProject extends SessionComponent<TestProjectProps, TestProjectState> {
  state: TestProjectState = {
    configOpen: false,
  }

  componentWillMount = async () => {
    /* const r = await App.client.ListProject(new ListProjectRequestJS({
      limit: 1000,
      page: 1,
      uid: this.props.session!.uid,
      filter: {
        enabled: false,
      }
    }))

    this.setState({
      projects: r.body.result as ProjectJS []
    }) */
  }

  render = () => {
    console.log(this.state.projects);
    return (
      <Template title="" noPadding={true}>
        {/* <MapView projects={this.state.projects} project={new ProjectJS({projectSiteCenter: {lat: 37.574515, lng: 126.976930}})} /> */}
        <Drawer anchor="right" variant="permanent" open={true}>
          <div style={{width:"400px"}}>
          </div>
        </Drawer>
      </Template>
    );
  }

  handleProjectForm = (on: boolean) => {
    this.setState({
      configOpen: on
    });
  }
}