import * as THREE from '@teneleven/three';
import { Console } from 'console';

export interface DEMData {
  value: number[][];
  pos: THREE.Vector2;
}

function getInterpolationValue(v: THREE.Vector2, value00: number, value01: number, value10: number, value11: number) {
  let vx = v.x % 1;
  let vy = v.y % 1;
  let xx0 = value00 + (value10 - value00) * vx;
  let xx1 = value01 + (value11 - value01) * vx;
  return xx0 + (xx1 - xx0) * vy;
}

export function getDemHeight(demData: DEMData[], pos: THREE.Vector2) {
  //00-----10
  //  |   |
  //01-----11
  let height = 0;
  demData.forEach(dem => {
    if (pos.x > dem.pos.x && pos.x < dem.pos.x + 2560 && pos.y > dem.pos.y && pos.y < dem.pos.y + 2560) {
      let indexX = (pos.x - dem.pos.x) / 10;
      let indexY = (pos.y - dem.pos.y) / 10;
      let floorIX = Math.floor(indexX);
      let floorIY = Math.floor(indexY);

      height = getInterpolationValue(new THREE.Vector2(indexX, indexY),
        dem.value[floorIX][floorIY],
        dem.value[floorIX][floorIY + 1],
        dem.value[floorIX + 1][floorIY],
        dem.value[floorIX + 1][floorIY + 1]);
    }
  });

  return height;
}

export function ToOADate(date: Date) {
  //@ts-ignore
  let time = (date - new Date(1899, 11, 29, 23, 27, 52));
  return (time + date.getTimezoneOffset() * 60 * 1000) / 86400000
}

export function FromOADate(oadate: number, timeZoneOffset: number) {
  //@ts-ignore
  let time = (oadate * 86400000) + new Date(1899, 11, 29, 23, 27, 52).getTime();
  return new Date().setTime(time - timeZoneOffset * 60 * 1000);
}

export function ToJulian(date: Date) {
  let _OADateToJulianDate = 2415018.5;
  return ToOADate(date) + _OADateToJulianDate;
}

export function FromJulian(julianDate: number, timeZoneOffset: number) {
  let _OADateToJulianDate = 2415018.5;
  return FromOADate(julianDate - _OADateToJulianDate, timeZoneOffset);
}

export function EquationTime(J: number) {
  let _Deg2Rad = Math.PI / 180.0;
  let _Rad2Deg = 180.0 / Math.PI;

  let W = 360.0 / 365.24;
  let A = W * (J + 10);
  let B = A + 2 * _Rad2Deg * 0.0167 * Math.sin(W * (J - 2) * _Deg2Rad);
  let C = (A - Math.atan2(Math.tan(B * _Deg2Rad), Math.cos(23.45 * _Deg2Rad)) * _Rad2Deg) / 180;
  return 720 * (C - Math.round(C)); // 반올림법?
}

export function ToLocalTime(date: Date, lng: number) {
  let _StdLng = 135;
  let _J1970 = 2440588;
  let _J2000 = 2451545;
  // day 기준값.
  // 율리우스력 시작일 기준으로 하기 위해 J2000을 주석 처리.
  return ToJulian(date) - ((lng - _StdLng) / 15 - EquationTime(ToJulian(date) - _J1970 + 0.5) / 60) / 24;// - _J2000;
}
