import Events from 'events';
import { default as _ } from 'lodash';
import { map } from '@teneleven/protocols-ts-web';
import App from './App';
import { GeometryTypes } from '@turf/turf';

export interface DrawingOptions {
  drawingControl?: boolean | null;
  drawingMode?: number; // 2: ellipse, 4: polyline, 5: polygon
  polygonOptions?: PolygonOptions;
  polylineOptions?: PolylineOptions;
}

export interface PolygonOptions {
  map?: any;
  fillColor?: string; // #xxxxxx
  fillOpacity?: number; // 0~1
  strokeColor?: string; // #xxxxxx
  strokeOpacity?: number; // 0~1
  strokeWeight?: number; // 1~5
  strokeStyle?: string; // 'shortdashdotdot' ... naver api 참고
  zIndex?: number; // -xx ~ +xx
  clickable?: boolean;
  visible?: boolean;
  isProjectSite?: boolean;
  simplify?: boolean;
  controllable?: boolean;
}

export interface PolylineOptions {
  map?: any;
  fillColor?: string; // #xxxxxx
  fillOpacity?: number; // 0~1
  strokeColor?: string; // #xxxxxx
  strokeOpacity?: number; // 0~1
  strokeWeight?: number; // 1~5
  strokeStyle?: string; // 'shortdashdotdot' ... naver api 참고
  startIcon?: number; // 1 ~ 4
  endIcon?: number; // 1 ~ 4
  startIconSize?: number;
  endIconSize?: number;
  zIndex?: number; // -xx ~ +xx
  clickable?: boolean;
  visible?: boolean;
}

export interface CircleOptions {
  map?: any;
  fillColor?: string; // #xxxxxx
  fillOpacity?: number; // 0~1
  strokeColor?: string; // #xxxxxx
  strokeOpacity?: number; // 0~1
  strokeWeight?: number; // 1~5
  zIndex?: number; // -xx ~ +xx
  clickable?: boolean;
  visible?: boolean;
}

export interface InfoWindowOptions {
  position?: any;
  content?: any;
  zIndex?: number;
  maxWidth?: number;
  pixelOffset?: any;
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  disableAnchor?: boolean; // 꼬리
  anchorColor?: string;
  disableAutoPan?: boolean;
}

export interface MarkerOptions {
  map?: any;
  clickable?: boolean;
  draggable?: boolean;
  visible?: boolean;
  zIndex?: number;
  icon?: ImageIcon | HTMLIcon;
}

export interface ImageIcon {
  url?: string
}
export interface HTMLIcon {
  content?: string
  anchor?: any
}

export interface MapOptions {
  ref: any,
  center?: any,
  zoom?: number,
  wheel?: boolean,
  zoomControl?: boolean,
  scaleControl?: boolean,
  logoControl?: boolean,
  mapDataControl?: boolean,
  transitionOptions?: {
    duration: number,
    easing: string,
  },
  cadastral?: boolean, 
  maxZoom?: number,
  draggable?: boolean,
  pinchZoom?: boolean,
  scrollWheel?: boolean,
  keyboardShortcuts?: boolean,
  disableDoubleTapZoom?: boolean,
  disableDoubleClickZoom?: boolean,
  disableTwoFingerTapZoom?: boolean,
  tileSpare?: number,
}

export interface InfoWindow {
  map?: any;
}

export class Map extends Events {
  nMap: any;
  
  setCenter = (coord: [number, number]) => {
    this.nMap.setCenter(coord);
  }
  getCenter = () => {
    return this.nMap.getCenter();
  }
  setZoom = (zoom: number, effect: boolean = false) => {
    this.nMap.setZoom(zoom, effect);
  }
  getZoom = () => {
    return this.nMap.getZoom();
  }

  getBoundary = () => {
    return this.nMap.getBounds();
  }
  
  fitBounds = (min: [number, number], max: [number, number]) => {
    this.nMap.fitBounds(new App.naver.maps.LatLngBounds(
      new App.naver.maps.LatLng(min[1], min[0]),
      new App.naver.maps.LatLng(max[1], max[0]),
    ), {
      top: 10, right: 10, left: 10, bottom: 10,
    })
  }
}

export abstract class Shape extends Events {
  selected?: boolean;
  overlay: any;
  id: string | null;
  type: GeometryTypes | null;

  constructor() {
    super();
    this.id = null;
    this.type = null;
  }
  setOverlay = (overlay: any) => {
    this.overlay = overlay;
  }
  getOverlay = () => {
    return this.overlay;
  }

  setSelected = (select: boolean) => {
    this.selected = select;
  }
  getSelected = () => {
    return this.selected;
  }
  setVisible = (visible: boolean) => {
    this.overlay.setVisible(visible);
  }
  setId = (id: string) => {
    this.id = id;
  }
  getId = () => {
    return this.id;
  }
  setType = (type: GeometryTypes) => {
    this.type = type;
  }
  getType = () => {
    return this.type;
  }
  setEditable = (editable: boolean) => {
    if (this.overlay.getEditable() === undefined) {
      return;
    }
    this.overlay.setEditable(editable);
  }
  getEditable = (): boolean => {
    return this.overlay.getEditable();
  }
  getPath() {};

  remove = (all: boolean = false) => {
    this.overlay.setMap(null);
    if (all === false) {
      this.emit('removed');
    }
  }
}

export class Polygon extends Shape {
  multiPolygonPath?: [][][][] = undefined;
  setOptions = (option: PolygonOptions) => {
    this.overlay.setOptions({...option});
  }

  getPath = (): number[][][] => {
    return this.overlay.getPaths()._array.map((r: any) => r._array.map((rr: any) => [rr.x, rr.y]));
  }

  setPath = (paths: number[][][]) => {
    this.overlay.setPaths(paths);
    this.emit('changed');
  }

  getCenter = () => {
    return this.getPath()[0].reduce((a: number[], b: number[]) => [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2]);
  }

  getLastVertex = () => {
    const path = this.getPath();
    return path[0][path[0].length - 1];
  }

  setRightClick = (on: boolean, callback?: Function) => {

    if (on) {
      this.overlay.__event_relations__.rightclick = callback && callback;
    } else {
      this.overlay.__event_relations__.rightclick = null;
    }

  }

  getRightClick = () => {
    return this.overlay.__event_relations__.rightclick;
  }
}

export class Polyline extends Shape {
  setOptions = (option: PolylineOptions) => {
    this.overlay.setOptions({...option});
  }

  getPath = () => {
    if (this.overlay) {
      return this.overlay.getPath()._array.map((r: any) => [r.x, r.y]);
    } else {
      return undefined;
    }
  }

  setPath = (path: number[][]) => {
    this.overlay.setPath(path);
    // this.overlay.setPath();
  }
}

export class ProjectSitePolygon extends Polygon {
  road: {
    spacePolygon: Array<Polygon | undefined >
    verticalLine: Array<Polyline | undefined >
    horizontalLine: Array<Polyline | undefined >
  }
  pnu?: string;

  constructor () {
    super();
    this.road = {
      spacePolygon: [],
      verticalLine: [],
      horizontalLine: []
    }
  }

  setPnu = (pnu: string) => {
    this.pnu = pnu;
  }
  getPnu = () => {
    return this.pnu;
  }

  getHasRoad = () => {
    let result: boolean = false;
    this.road.spacePolygon.map(r => r !== undefined && (result = true));
    return result;
  }
}

export class Circle extends Shape {
  setOptions = (option: CircleOptions) => {
    this.overlay.setOptions(option);
  }

  getPath = () => {
    const bounds = this.overlay.getBounds();
    return [[
      [bounds._min.x, bounds._min.y],
      [bounds._max.x, bounds._min.y],
      [bounds._max.x, bounds._max.y],
      [bounds._min.x, bounds._max.y]
    ]];
  }

  setPath = (path: number[][][]) => {
    const bounds = new App.naver.maps.LatLngBounds(
      { lat: path[0][0][1], lng: path[0][0][0] }, 
      { lat: path[0][2][1], lng: path[0][2][0] });
    this.overlay.setBounds(bounds);
  }
}

export class Marker extends Shape {
  setOptions = (option: MarkerOptions) => {
    this.overlay.setOptions(option);
  }

  getPosition = () => {
    return this.overlay.getPosition();
  }

  setPosition = (position: number[]) => {
    this.overlay.setPosition(position);
  }

  setInfoWindow = (content: string) => {
    new InfoWindow(content);
  }

}

export class InfoWindow {
  infoWindow: any;
  content?: string;
  map?: any;
  isShow: boolean;  

  constructor(map: any, content?: string) {
    this.content = content;
    this.map = map.nMap;
    this.isShow = false;
    this.map.addListener('mousemove', _.debounce( (e: any) => {
      if (this.isShow) {
        this.infoWindow.open(this.map, e.coord);
      } else { 
        this.close();
      }
    }, 1))

    this.map.addListener('mouseout', () => {
      this.close();
    });
  }

  show = (content?: any) => {
    this.infoWindow.setContent(content);
    this.isShow = true;
  }

  close = () => {
    this.isShow = false;
    setTimeout(() => {
      this.infoWindow.close();
    }, 100)
    // this.map.removeListener('mousemove');
  }
}