import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import queryString from 'query-string';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import './css/UnconfirmedUser.scss';
import App from './App';
import axios from 'axios';

export interface UnconfirmedUserProps {
}
export interface UnconfirmedUserState {
  email: string;
}
export default class UnconfirmedUser extends Component<UnconfirmedUserProps, UnconfirmedUserState> {
  state: UnconfirmedUserState = {
    email: "",
  }

  componentWillMount = () => {
    const qs = queryString.parse(location.search);
    this.setState({ email: qs.email as string }, async () => {
      const type = qs.t;
      if (type !== "register") {
        const emailCheckUrl = `${App.APIG_URL}/CheckEmailExists`;
        const r = await axios.post(emailCheckUrl, { email: this.state.email });
        if (r.status === 200) {
          // 존재함
          if (r.data.value) {
            this.resendConfirmEmail();
          }
        }
      }
    })
  }
  render() {
    return (
    <div className="UnconfirmedUser buildit-template">
      <div className="wrapper">
        <div className="title">빌드잇<br />회원가입</div>
        <div className="register-info font font-primary bg-navy font-18px">
          <div>이메일로 회원가입 링크가 전송되었습니다.</div>
          <div>회원가입을 완료하기 위해 등록한 이메일을</div>
          <div>확인 해주세요.</div>
        </div>
        <div className="email-wrap">
        { this.state.email }
        </div>
        <Link to="/login" style={{textDecoration: "none"}}>
          <Button disableRipple={true} className="bg-navy btn btn-run btn-large login-btn">LOGIN</Button>
        </Link>
      </div>
    </div>
    )
  }

  resendConfirmEmail = () => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: App.USER_POOL_ID,
      ClientId: App.COGNITO_CLIENT_ID,
    });

    const userData = {
      Username: this.state.email,
      Pool: userPool,
    }

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.resendConfirmationCode((e: any, r) => {
      if (e) {
        switch (e.code) {
          case "LimitExceededException": 
            alert('잠시후 다시 이용해주세요');
          break;
          default: 
            console.log(e.code);
            alert('예기치 못한 에러');
          break;
        }
      }
    })
    
  }
}