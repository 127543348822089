import React, { Component, Fragment } from 'react';
import './css/BuilditSelect.scss';
import { ClickAwayListener } from '@material-ui/core';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { default as _ } from 'lodash';
import { SvgIcon } from 'material-ui';
import ProjectIcon from './ProjectIcon'

type SelectLabel = React.ReactText | JSX.Element;
type SelectType = "Normal" | "Table" | "CARD_TOP";
type AdornmentPlace = "Right" | "Bottom";

export interface InputAttr {
  error: boolean, 
  msg?: string,
  requiredCheck?: boolean,
}

export interface List_Element {
  value: React.ReactText,
  label: SelectLabel,
  disable?: boolean,
  tooltip_msg?: string,
}

export interface BuilditSelectProps {
  className?: string;
  list?: Array<List_Element>;
  value?: React.ReactText;
  placeholder?: SelectLabel;
  useJSXPlaceholder?: boolean;
  id?: string;
  forceBlur?: boolean;
  hoverDisable?: boolean;
  type: SelectType;
  adornment?: AdornmentPlace;

  onKeyDown?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  onSubmit?: (e: any) => void;
  onChange: (val: React.ReactText) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  onClickAway?: () => void;
}
export interface  BuilditSelectState {
  openSelect: boolean;
  focus: boolean;
}

export default class BuilditSelect extends Component<BuilditSelectProps, BuilditSelectState> {
  state: BuilditSelectState = {
    openSelect: false,
    focus: false,
  }

  componentDidUpdate = (pp: Readonly<BuilditSelectProps>) => {
    if (this.props.forceBlur) {
      this.setState({
        focus: false
      })
    }
  }

  getHeaderJSX = () : JSX.Element => {
    let ret: JSX.Element = <div></div>;
    let value: SelectLabel;
    if (this.props.list && this.props.list.length > 0) {
      const index = this.props.list.findIndex((e: {value: React.ReactText, label: SelectLabel}) => e.value == this.props.value);
      if (index < 0) {
        value = this.props.placeholder ? this.props.placeholder : "선택";
      } else {
        value = this.props.placeholder ? this.props.placeholder : this.props.list[index].label;
      }
    } else {
      value = "";
    }
    let type = "";
    switch(this.props.type) {
      default:
      case "Normal":
        break;
      case "Table":
        type = "table";
        break;
      case "CARD_TOP":
        type = "card-top";
        break;
    }

    switch (this.props.adornment) {
      default:
      case "Right":
        ret = <div className={`select-header ${this.props.hoverDisable && "hover-disable" || ""} ${this.state.focus && "focus" || ""} ${type}`} 
                  onClick={e => { 
                    this.onClickHeader();
                }}>
              {
                <div className="custom-placeholder">
                    {this.props.placeholder && this.props.placeholder || value}
                </div>
              }

              {/* select input's end icon */}
              {
                <div className="select-icon">
                  <DownArrowIcon className="icon"/>
                </div>
              }
            </div>
            break;
      case "Bottom":
        ret = <div className={`bottom-header ${this.props.hoverDisable && "hover-disable" || ""} ${this.state.focus && "focus" || ""} ${type}`} 
                  onClick={e => { 
                  this.onClickHeader();
                }}>
              {
                <div className="custom-placeholder">
                    {this.props.placeholder && this.props.placeholder || value}
                </div>
              }

              {/* select input's end icon */}
              {
                <div className="select-icon">
                  <DownArrowIcon className="icon"/>
                </div>
              }
            </div>
      break;
    }
    return ret
  }

  render () {
    const forProjectList: boolean = (this.props.type === "CARD_TOP" ||this.props.type === "Table")
    return (
      <ClickAwayListener onClickAway={this.onClickAway}>
      {
        <Fragment>
          <div className={`BuilditSelect select ${this.props.className || "default"} ${this.state.openSelect && " opened" || ""} ${forProjectList && "table"}`}>
            {this.getHeaderJSX()}
          {/* select options */}
          {
            this.props.list && 
            this.props.list.length > 0 &&
            this.state.openSelect &&
            <div className={`select-wrapper ${forProjectList && "select-table"}`}>
            {
              this.props.list.map((option: List_Element) => {
                return (
                      <div className={`option ${forProjectList && "option-table"} ${option.disable && 'disabled'}`} onClick={e => this.onSelectChange(option.value)}>
                        { option.label }
                      </div>
                )
              })
            }
            </div>
          }
          </div>
        </Fragment>
      }
      </ClickAwayListener>
    )
  }

  onSelectChange = (value: React.ReactText) => {
    this.props.onChange(value);
    this.onClickAway();
  }
  onChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onChange(e.target.value);
  }
  onClickHeader = () => {
    this.onOpenMenu(!this.state.openSelect);
    this.props.onClick && this.props.onClick();
  }
  onClickAway = () => {
    this.onOpenMenu(false);
    if (this.props.onClickAway) {
      this.props.onClickAway();
    }
  }
  onOpenMenu = (open: boolean) => {
    this.setState({ openSelect: open, focus: open, })
  }
}