import React, { Fragment, Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, } from '@material-ui/core';
import queryString from 'query-string';
import { default as _ } from 'lodash';

import './css/DesignerDownloadPage.scss';
import PageFoot from './pageFoot';
import SessionComponent from './SessionComponent';
import DownloadButton from './DownloadButton';
import App from './App'
import Login from './Login'
import BuilditTemplate from './BuilditTemplate';

export interface DesignerDownloadPageProps {
}

export interface DesignerDownloadPageState {
}

export default class DesignerDownloadPage extends SessionComponent<DesignerDownloadPageProps, DesignerDownloadPageState> {
  state: DesignerDownloadPageState = {

  }

  componentWillMount = async () => {
  }

  componentDidUpdate = (pp: Readonly<DesignerDownloadPageProps>, ps: Readonly<DesignerDownloadPageState>) => {

  }

  downloadDesigner = (callback?: Function) => {
    App.s3!.getSignedUrl("getObject", {
      Bucket: "teneleven-application-registry-apn2",
      Key: `buildit-designer/v1/${App.stage === "prod" ? "buildit_designer_installer_release.exe" : "buildit_designer_installer_test.exe"}`,
      Expires: 43200,
      ResponseContentDisposition: `attachment; filename="Buildit_Designer_Installer.exe"`
    }, async (err, data) => {
      if (err) {
        console.log(err);
      } else {
        window.location.href = data;
      }

      if (callback) {
        callback();
      }
    })
  }

  onInitToken = (token: number) => {
    this.setState({
      token: token,
    });
    window.location.href = "/";
  }

  render() {
    App.stage !=="prod" && console.log(App.session)
    if (App.session === undefined) {
      return (<Login onInitToken={this.onInitToken} fromDownLoadPage={true} {...this.props}/>)
    } else {
        return (
          <BuilditTemplate footer={true}  {...this.props} >
            <div className="designer-download-page">
              <div className="bg-wrapper">
                <img src="/img/designer_download_bg.png" className="bg-img"/>
              </div>
              <div className="content-wrapper">
                <div className="title">DESIGNER DOWNLOAD</div>
                <div className="downloder-wrapper">
                  <div className="button-wrapper">
                    <div className="title">최신 버전 다운로드</div>
                    <div className="sub-title">BUILDIT DESIGNER를 다운로드하여 새롭게 추가된 기능과 개선된 성능을 확인해 보세요</div>
                    <DownloadButton
                      className="bg-navy btn btn-large btn-run m-r-md download-btn designer-download-btn"
                      onClick={(callback: Function) => { 
                        this.downloadDesigner(() => {
                          callback && callback();
                        });
                      }}
                    >
                      <div>DESIGNER</div>
                      <span className="m-l-a width-full" style={{textTransform: "none"}}>exe</span>
                    </DownloadButton>
                  </div>
                  
                </div>
              </div>
            </div>
          </BuilditTemplate>
        )
    }
  }
}