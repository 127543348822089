import { Button} from '@material-ui/core';
import React, { Component } from 'react';
import './css/ProjectTypeModal.scss';
import CloseIcon from '@material-ui/icons/Close'
import { Link } from 'react-router-dom';

interface ProjectTypeModalProps {
  isShow: boolean;
  showModal: Function;
}


class ProjectTypeModal extends Component<ProjectTypeModalProps> {

  componentDidUpdate () {
    if (this.props.isShow){
      document.addEventListener('click', this.handleClickOutside);
    } else {
      document.removeEventListener('click', this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (e:any) => {
    // console.log(e.target.className);
    if (e.target.className === "modal-background") {
      this.props.showModal(this.props.isShow);
    }
  }

  render() {
    return (
      <div className="ProjectTypeModal" style={this.props.isShow ? {display: 'block'} : {display: 'none'}}>
        <div className="modal-background"></div>
          <div className="modal-box">
            <div className="header">
              <Button className="closeBtn" onClick={() => {
                this.props.showModal(this.props.isShow) // 함수
              }}>
              <CloseIcon className='closeIcon' />
              </Button>
            </div>
            <div className="modal-content">
              <div className="title-box">
                <h3 className="title">어떤 프로젝트를 시작할까요?</h3>
              </div>
              <section>
              <Link to="/project/AI/create/" className="text-deco-none" >
                <article className="AI">
                  <div className="AI content">
                  <h3 className="title">BUILDIT AI</h3>
                  <p className="description">인공지능 기반으로 건축 게획안을 자동 생성하는 프로젝트</p>
                  {/* <button className="">+ 새 프로젝트 시작</button> */}
                    <Button className="btn-new-project">+ 새 프로젝트 시작</Button>
                  </div>
                </article>
                </Link>
                <Link to="/project/DESIGNER/create" className="text-deco-none">
                <article className="DESIGNER">
                  <div className="DESIGNER content">
                  <h3 className="title">BUILDIT DESIGNER</h3>
                  <p className="description">반자동 검토 기능으로 건축 계획안을 직접 생성하는 프로젝트</p>
                    <Button className="btn-new-project">+ 새 프로젝트 시작</Button>
    
                  </div>
                </article>
                </Link>
              </section>
            </div>
          </div>


      </div>
    );
  }
}

export default ProjectTypeModal;