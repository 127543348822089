import { mapProjectionData } from "./DataTypes";
import * as THREE from '@teneleven/three';
import { any } from 'prop-types';

export class NaverMapManager {
  //@ts-ignore
  private naverMap = window.naver.maps;
  private cadastralLayer: any;// = new window.naver.maps.CadastralLayer();
  private map: any;
  private benchmarkPosition: any;
  private mapProj: mapProjectionData = { mapOffset: new THREE.Vector2(0), projection: any };
  private naverSitePolygon: any;
  private naverVacancyOutsidePolygon: any;
  private naverVacancyInsidePolygon: any;
  private naverRoadPolygon: any;
  private centerOfRoadPolygon: any;


  createMap = (center: any, htmlElement: HTMLElement) => {
    if (this.map) {
      this.map.destroy();
    }
    this.map = new this.naverMap.Map(htmlElement, {
      // mapTypes: this.naverMap.StyleMapTypeOption.getMapTypes({
      //   initLayers: [
      //     this.naverMap.StyleMapLayerId.BACKGROUND_DETAIL,
      //     this.naverMap.StyleMapLayerId.POI_KOREAN,
      //     this.naverMap.StyleMapLayerId.CADASTRAL,
      //   ]
      // }),
      mapTypeControlOptions: {
        style: this.naverMap.MapTypeControlStyle.DROPDOWN
      },
      // mapTypeControl: true,
      center: center,
      // mapTypeId: this.naverMap.StyleMapTypeId.NORMAL,
      zoom: 17,
    });

    this.cadastralLayer = new this.naverMap.CadastralLayer({ useStyleMap: true });
    this.mapProj.projection = this.map.getProjection();
    this.mapProj.mapOffset = new THREE.Vector2(0);
  }

  getMap = () => {
    return this.map;
  }

  searchAddressToCoordinate = (address: string) => {
    this.naverMap.Service.geocode({
      query: address
    }, (status: any, response: any) => {
      if (status === this.naverMap.Service.Status.ERROR) {
        return alert('Something Wrong!');
      }

      if (response.v2.meta.totalCount === 0) {
        return alert('totalCount' + response.v2.meta.totalCount);
      }

      var htmlAddresses = [],
        item = response.v2.addresses[0],
        point = new this.naverMap.Point(item.x, item.y);

      if (item.roadAddress) {
        htmlAddresses.push('[도로명 주소] ' + item.roadAddress);
      }

      if (item.jibunAddress) {
        htmlAddresses.push('[지번 주소] ' + item.jibunAddress);
      }

      if (item.englishAddress) {
        htmlAddresses.push('[영문명 주소] ' + item.englishAddress);
      }

      this.map.setCenter(point);
    });

  }

  setMapCenter = (x: number, y: number) => {
    this.map.setCenter(new this.naverMap.Point(x, y));
  }

  addListener = (event: string, eventFunction: Function) => {
    this.naverMap.Event.addListener(this.map, event, () => {
      eventFunction();
    })
  }

  getProjection = () => {
    return this.map.getProjection();
  }

  isCreate = () => {
    if (this.map) {
      return true;
    }
    else {
      return false;
    }
  }

  getTMPosition = (screenPos: THREE.Vector2, proj: any) => {
    return proj.fromPageXYToCoord(NaverPoint(screenPos.x, screenPos.y));
  }

  changeMapType = (isCadastral: boolean) => {
    this.cadastralLayer.setMap(isCadastral ? this.map : null);
  }

  clearAllPolygon = () => {
    if (this.naverSitePolygon) {
      this.naverSitePolygon.setMap(null);
      this.naverSitePolygon = undefined;
    }

    if (this.naverVacancyOutsidePolygon) {
      this.naverVacancyOutsidePolygon.setMap(null);
      this.naverVacancyOutsidePolygon = undefined;
    }

    if (this.naverVacancyInsidePolygon) {
      this.naverVacancyInsidePolygon.setMap(null);
      this.naverVacancyInsidePolygon = undefined;
    }

    if (this.naverRoadPolygon) {
      this.naverRoadPolygon.setMap(null);
      this.naverRoadPolygon = undefined;
    }

    if (this.centerOfRoadPolygon) {
      this.centerOfRoadPolygon.setMap(null);
      this.centerOfRoadPolygon = undefined;
    }

  }

  setNaverSitePolygon = (latlngs: any) => {
    if (this.naverSitePolygon) {
      this.naverSitePolygon.setPaths(latlngs)
    }
    else {
      //@ts-ignore
      this.naverSitePolygon = new window.naver.maps.Polygon({
        map: this.map,
        paths: latlngs,
        fillColor: 'rgb(35, 39, 50)',
        fillOpacity: 0.3,
        strokeColor: "#232732",
        strokeWeight: 2,
        strokeStyle: 'shortdashdotdot',
        zIndex: -10,
        clickable: true,
        isProjectSite: true,
      });
    }
  }

  setNaverVacancyOutsidePolygon = (latlngs: any) => {
    if (this.naverVacancyOutsidePolygon) {
      this.naverVacancyOutsidePolygon.setPaths(latlngs)
    }
    else {
      //@ts-ignore
      this.naverVacancyOutsidePolygon = new window.naver.maps.Polygon({
        map: this.map,
        paths: latlngs,
        fillColor: 'rgb(0, 161, 94)',
        fillOpacity: 0.3,
        strokeColor: 'rgb(0, 161, 94)',
        strokeWeight: 1,
        zIndex: -8,
        clickable: true,
      });
    }
  }

  setNaverVacancyInsidePolygon = (latlngs: any) => {
    if (this.naverVacancyInsidePolygon) {
      this.naverVacancyInsidePolygon.setPaths(latlngs)
    }
    else {
      //@ts-ignore
      this.naverVacancyInsidePolygon = new window.naver.maps.Polygon({
        map: this.map,
        paths: latlngs,
        fillColor: 'rgb(214, 48, 0)',
        fillOpacity: 0.3,
        strokeColor: 'rgb(214, 48, 0)',
        strokeWeight: 1,
        zIndex: -6,
        clickable: true,
      });
    }
  }

  setNaverRoadPolygon = (latlngs: any) => {
    if (this.naverRoadPolygon) {
      this.naverRoadPolygon.setPaths(latlngs)
    }
    else {
      //@ts-ignore
      this.naverRoadPolygon = new window.naver.maps.Polygon({
        map: this.map,
        paths: latlngs,
        fillColor: 'rgb(90, 121, 165)',
        fillOpacity: 0.5,
        strokeWeight: 0,
        clickable: false,
        zIndex: -9,
        simple: true,
      });
    }
  }

  setCenterofRoadPolygon = (latlngs: any) => {
    if (this.centerOfRoadPolygon) {
      this.centerOfRoadPolygon.setPath(latlngs[0])
    }
    else {
      //@ts-ignore
      this.centerOfRoadPolygon = new window.naver.maps.Polyline({
        map: this.map,
        path: latlngs[0],
        strokeColor: '#0000FF',
        strokeWeight: 2,
        endIcon: 2,
        zIndex: -6,
      });
    }
  }


  //sitePoint:: site field's firset point in screen space
  setBenchmarkPosition = (sitePoint: THREE.Vector2) => {
    this.benchmarkPosition = this.mapProj.projection.fromPageXYToCoord(NaverPoint(sitePoint.x, sitePoint.y));
  }

  getBenchmarkPosition = () => { return this.benchmarkPosition; }

  getLatLngPosition(screenPoint: THREE.Vector2) {
    this.mapProj.projection = this.map.getProjection();
    return this.mapProj.projection.fromPageXYToCoord(NaverPoint(screenPoint.x, screenPoint.y));
  }

  getMapProjectionData = () => { return this.mapProj }
  getMapSettingState = () => { return !this.mapProj.projection || !this.benchmarkPosition }
}

export function NaverPoint(x: number, y: number) {
  //@ts-ignore
  return new window.naver.maps.Point(x, y);
}

export function NaverLatLng(lat: number, lng: number) {
  //@ts-ignore
  return new window.naver.maps.LatLng(lat, lng);
}

export function NaverPolygon(parameters: any) {
  //@ts-ignore
  return new window.naver.maps.Polygon(parameters);
}

