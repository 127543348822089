import * as THREE from '@teneleven/three';
import { degrees2radians, radiansToDegrees } from '@turf/turf';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { BlockType } from './DataTypes';
import { BuildingPart, BuildingComponent } from './BuildingPart';
import { ConverterBlock } from './ConverterBlock';

export class BuildingGroup extends BuildingPart {
  constructor(block: ConverterBlock) {
    super(block);
  }

  SetName = (name: string) => { this.name = name; }
  GetName = () => { return this.name; }

  GetCoreNumber = () => { return this.coreNumber; }
  GetHouseNumber = () => { return this.houseNumber; }

  SetPosition = (position: THREE.Vector3) => {
    this.position = position;
    this.renderGroup.position.set(position.x, position.y, position.z);
  }

  SetScale = (scale: THREE.Vector3) => {
    this.scale = scale
    this.renderGroup.scale.set(scale.x, scale.y, 0);
  }

  RotateWithRadians = (radians: number) => {
    this.rotate = radiansToDegrees(radians);
    this.renderGroup.rotateZ(radians);
  }

  RotateWithDegrees = (degress: number) => {
    this.rotate = degress;
    this.renderGroup.rotateZ(degrees2radians(degress));
  }

  AddNewPart = (part: BuildingPart) => {
    this.parts.push(part);
    this.renderGroup.add(part.renderGroup);

    if (part.block.type === BlockType.core) {
      this.coreNumber++;
    }
    else if (part.block.type === BlockType.house) {
      this.houseNumber++;
    }
  }

  UpdateArea = () => {
    this.parts.forEach(p => {
      p.UpdateArea();

      this.totalCoreAreas += p.totalCoreAreas;
      this.totalServiceAreas += p.totalServiceAreas;
      this.totalExclusiveAreas += p.totalExclusiveAreas;
      this.totalCommonWallAreas += p.totalCommonWallAreas;
    })
  }

  RebuildOutputPolygon = () => {
    this.parts.forEach(p => {
      p.RebuildOutputPolygon();
    })
  }

  toJson = () => {
    let text = {
      name: this.name,
      buildingType: this.buildingType,
      block: this.block.name,
      position: this.position,
      scale: this.scale,
      rotate: this.rotate,
      parts: this.parts.map(p => p.toJson()),
    }

    return text;
  }
}