import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import '../css/CADConverter/ConverterInputBox.scss';
import { ClickAwayListener } from '@material-ui/core';

interface InputBoxProp {
  name: string,
  value: number,
  disable?: boolean,
  step?: number
  unit: string,
  valueOnChange: (value: number) => void,
}

interface InputBoxState {
  focus: boolean,
  hover: boolean,
  value: number,
  step: number,
  disable: boolean
}

export class ConverterInputBox extends Component<InputBoxProp, InputBoxState> {
  state: InputBoxState = {
    focus: false,
    hover: false,
    value: this.props.value,
    disable: false,
    step: 1,
  }


  componentDidMount = async () => {
    if (this.props.disable !== null) {
      this.setState({ disable: this.props.disable! })
    }

    if (this.props.step) {
      this.setState({ step: this.props.step })
    }
  }

  componentDidUpdate = (previousProps: Readonly<InputBoxProp>, previousState: Readonly<InputBoxState>) => {
    if (previousProps.disable !== this.props.disable) {
      this.setState({
        disable: this.props.disable!,
      })
    }

    if (previousProps.value !== this.props.value || this.state.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      })
    }
  }

  addEvent = () => {
    this.changeValue((this.state.value + this.state.step).toString());
  }

  decreaseEvent = () => {
    this.changeValue((this.state.value - this.state.step).toString());
  }

  onClickAway = () => {
    this.setState({
      focus: false,
    })
  }

  changeValue = (value: string) => {
    let v = Number(value);
    
    if (v < 0) { v = 0 }
    let s = 1 / this.state.step;

    v = Math.round(v * s) / s;

    this.props.valueOnChange(v);
    this.setState({ value: v });
  }

  render = () => {
    return (
      <div className='inputBody'>
        <div className='name'>{this.props.name}</div>
        <ClickAwayListener onClickAway={this.onClickAway}>
          <div className={`${(this.state.disable && 'box-disable') || 'box'}`} onFocus={() => this.setState({ focus: true })} onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
            <RemoveIcon
              className={`icon ${(!this.state.focus && !this.state.hover) && 'hide'} ${(this.state.disable) && 'disable'}`}
              onClick={this.decreaseEvent}
            />
            <AddIcon
              className={`icon ${(!this.state.focus && !this.state.hover) && 'hide'} ${(this.state.disable) && 'disable'}`}
              onClick={this.addEvent}
            />
            <input
              type='number'
              className={`value ${(this.state.disable) && 'disable'}`}
              value={this.state.value}
              onChange={e => this.changeValue(e.target.value)}
              disabled={this.state.disable}
              step='0.1'
            />
            <span className='unit'>{this.props.unit}</span>
          </div>
        </ClickAwayListener>
      </div>
    )
  }
}