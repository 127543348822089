import React, { Fragment, Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, } from '@material-ui/core';
import queryString from 'query-string';
import { default as _ } from 'lodash';

import './css/NotFoundPage.scss';
import PageFoot from './pageFoot';
import SessionComponent from './SessionComponent';
import DownloadButton from './DownloadButton';
import App from './App'
import Login from './Login'
import BuilditTemplate from './BuilditTemplate';

export interface NotFoundPageProps {
}

export interface NotFoundPageState {
}

export default class NotFoundPage extends SessionComponent<NotFoundPageProps, NotFoundPageState> {
  state: NotFoundPageState = {

  }

  componentWillMount = async () => {
  }

  componentDidUpdate = (pp: Readonly<NotFoundPageProps>, ps: Readonly<NotFoundPageState>) => {

  }

  onInitToken = (token: number) => {
    this.setState({
      token: token,
    });
    window.location.href = "/login";
  }

  render() {
    App.stage !=="prod" && console.log(App.session)
    if (App.session === undefined) {
      return (<Login onInitToken={this.onInitToken} {...this.props}/>)
    } else {
        return (
          <BuilditTemplate footer={true}  {...this.props} >
            <div className="not-found-page">
              <div className="bg-wrapper">
                <div className="bg-img" />
              </div>
              <div className="content-wrapper">
                <div className="title">Oops!!🙄</div>
                <div className="sub-title">죄송합니다. 페이지를 찾을 수 없습니다.</div>
                <div className="description">
                  링크가 끊겼거나 페이지가 제거되었을 수 있습니다.
                  <br/>
                  HOME으로 돌아가서 다시 시도해 주세요
                </div>
                <Button className="home-button btn" 
                onClick={e => {
                  //@ts-ignore
                  this.props.history.push("/")
                }}>HOME으로 가기</Button>
              </div>
              
            </div>
          </BuilditTemplate>
        )
    }
  }
}