import React from 'react';
import Tooltip from './Tooltip';

// const DiscountTag = (props: any) => { return <div className={props.className} style={{ minWidth: "65px", color: "#48DDF2", border: "1px solid #48DDF2", borderRadius: "20px", fontSize: "12px", display: "flex", flexFlow: "row", alignItems: "center", justifyContent: "center" }}>할인적용</div> }

const DiscountTag = (props: any) => { 
  return (
    <Tooltip className={props.className} msg="할인이 적용된 프로젝트입니다.">
      <img style={{ minWidth: "20px", height: "20px", color: "#48DDF2", display: "flex", flexFlow: "row", alignItems: "center", justifyContent: "center" }} src="/img/icon-sale.svg"></img> 
    </Tooltip>
  )
}

export default DiscountTag;