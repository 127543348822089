import React, { Component } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/VerticalAlignBottom';
import './css/DownloadButton.scss';

interface DownloadButtonProps {
  disabled?: boolean;
  onClick: (callback: Function) => void;
  className?: string;
}
interface DownloadButtonState {
  loading: boolean;
}

export default class DownloadButton extends Component<DownloadButtonProps, DownloadButtonState> {
  state: DownloadButtonState = {
    loading: false,
  }

  render() {
    return (
      <Button disableRipple={true} className={`DownloadButton ${this.props.className}`}
        disabled={this.props.disabled}
        onClick={() => this.onClick()}
      >
        <span style={{ width: "calc(100% - 25px)", display: "flex" }}>
        {
          this.props.children
        }
        </span>
        {
          this.state.loading && 
          <CircularProgress className="icon progress-icon" /> ||
          <DownloadIcon className="icon download-icon" />
        }
      </Button>
    )
  }
  
  onClick = () => {
    this.setState({ loading: true }, () => {
      this.props.onClick(() => {
        this.setState({ loading: false })
      })
    })
  }
}