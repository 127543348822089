import React, { Component, Fragment } from 'react';
import './css/ProjectFormJiguInfo.scss';
import { Project } from './model/Project';
import AWSModule from './AWSModule';
import { Button } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';

import { default as _ } from 'lodash';
import { JiguInfo } from './ProjectForm';
import { Link } from 'react-router-dom';
import url from 'url';

export interface ProjectFormJiguInfoProps {
  setOpen: (open: boolean) => void;
  updateJiguInfo: (jiguInfo: Array<Array<JiguInfo>>) => void;
  open: boolean;
  project: Project;
  jiguInfo: Array<Array<JiguInfo>>;
}
export interface ProjectFormJiguInfoState {
  outerIndex: number,
  innerIndex: number,
}

export default class ProjectFormJiguInfo extends Component<ProjectFormJiguInfoProps, ProjectFormJiguInfoState> {
  state: ProjectFormJiguInfoState = {
    outerIndex: -1,
    innerIndex: -1,
  }
  componentDidMount = () => {
    if (this.props.jiguInfo.length > 0) { // 초기 요소
      const jiguInfo = _.cloneDeep(this.props.jiguInfo);
      this.clearJiguInfo(jiguInfo);
      if (jiguInfo !== undefined && jiguInfo.length > 0 && jiguInfo[0].length > 0) {
        jiguInfo[0][0].active = true;
      }
      this.setState({ outerIndex: 0, innerIndex: 0 }, () => {
        this.props.updateJiguInfo(jiguInfo);
      })
    }
  }

  componentDidUpdate = (pp: Readonly<ProjectFormJiguInfoProps>, ps: Readonly<ProjectFormJiguInfoState>) => {
    if (this.props.jiguInfo.length === 0 && !_.isEqual(this.props.jiguInfo, pp.jiguInfo)) {
      this.setState({ innerIndex: -1, outerIndex: -1})
    }
  }
  render() {
    let data: any;

    if (this.props.jiguInfo.length > 0 && this.state.outerIndex > -1 && this.state.innerIndex > -1) {      
      data = this.props.jiguInfo[this.state.outerIndex][this.state.innerIndex];
    }

    return (
      <div className="ProjectFormJiguInfo">
        <div className="content">
          <div className="header">
            토지이용계획 정보
            <Button className="close-btn m-l-a" disableRipple={true} onClick={() => this.props.setOpen(false)}>
              <CloseIcon className="icon" />
            </Button>
          </div>
          <div className="info-wrap">
            <div className="info-nav">
            {
              this.props.jiguInfo.map((r, i) => {
                return (
                  <div className="area-content">
                    <div className="header">
                      {Number(i + 1).toString().padStart(2, '0')} 대지영역
                    </div>
                  {
                    r.map((rr, j) => {
                      return (
                        <div className={`jibun ${this.state.outerIndex === i && this.state.innerIndex === j && 'active' || ""}`}
                          onClick={() => { this.onClickAddress(i, j) }}
                          onMouseEnter={() => { this.onHoverAddress(i ,j) }}>
                          {rr.address!.split(" ")[rr.address!.split(" ").length - 1]}
                        </div>
                      )
                    })
                  }
                  </div>
                )
              })
            }
            </div>
            <div className="info">
              {
                data &&
                <Fragment>
                  {
                    console.log('a', url.parse(data.url) )
                  }
                  <a target="_blank" href={`http://www.eum.go.kr/web/ar/lu/luLandDet.jsp${url.parse(data.url).search}`} className="text-deco-none">
                    <Button className="bg-navy btn btn-extra btn-small m-b-md">
                      <span className="font font-14px">토지이음에서 확인하기</span>
                      <ArrowForwardIcon className="icon m-l-sm" />
                    </Button>
                  </a>
                  <div className="address ">
                    <div className="title">필지 주소</div>
                    <div className="content">
                      ({data.jimog})
                      {data.address}
                    </div>
                  </div>
                  <div className="land-raw jigu-raw">
                    <div className="title">국토의 계획 및 이용에 관한 법률</div>
                    <div className="content">
                      <div className="key">포함</div>
                      <div className="value">{data.info!['국토법']['포함'].length === 0 ? "-" : data.info!['국토법']['포함'].join(", ")}</div>
                    </div>
                    <div className="content">
                      <div className="key">저촉</div>
                      <div className="value">{data.info!['국토법']['저촉'].length === 0 ? "-" : data.info!['국토법']['저촉'].join(", ")}</div>
                    </div>
                    <div className="content">
                      <div className="key">접함</div>
                      <div className="value">{data.info!['국토법']['접함'].length === 0 ? "-" : data.info!['국토법']['접함'].join(", ")}</div>
                    </div>
                  </div>
                  <div className="etc-raw jigu-raw">
                    <div className="title">기타 법률</div>
                    <div className="content">
                      <div className="key">포함</div>
                      <div className="value">{data.info!['기타법']['포함'].length === 0 ? "-" : data.info!['기타법']['포함'].join(", ")}</div>
                    </div>
                    <div className="content">
                      <div className="key">저촉</div>
                      <div className="value">{data.info!['기타법']['저촉'].length === 0 ? "-" : data.info!['기타법']['저촉'].join(", ")}</div>
                    </div>
                    <div className="content">
                      <div className="key">접함</div>
                      <div className="value">{data.info!['기타법']['접함'].length === 0 ? "-" : data.info!['기타법']['접함'].join(", ")}</div>
                    </div>
                  </div>
                </Fragment>
                ||
                <div className="font font-primary">
                {
                  this.props.jiguInfo.length > 0 &&
                  <Fragment>
                    {
                      this.props.jiguInfo[0].length > 0 &&
                      <p>왼쪽의 탭을 클릭하여<br />상세 정보를 확인 하세요</p> ||
                      <p>설정 영역에 포함되는<br />필지가 없습니다</p>
                    }
                  </Fragment>
                   ||
                   <Fragment>
                    <p>대지영역 설정후 확인 하세요</p>
                  </Fragment>
                }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  onClickAddress = (outerIndex: number, innerIndex: number) => {
    const jiguInfo = _.cloneDeep(this.props.jiguInfo);
    this.clearJiguInfo(jiguInfo, 'CLICK');
    jiguInfo[outerIndex][innerIndex].active = true;
    this.props.updateJiguInfo(jiguInfo);
    this.setState({
      outerIndex: outerIndex,
      innerIndex: innerIndex,
    })
  }
  onHoverAddress = (outerIndex: number, innerIndex: number) => {
    const jiguInfo = _.cloneDeep(this.props.jiguInfo);
    this.clearJiguInfo(jiguInfo, 'HOVER');
    jiguInfo[outerIndex][innerIndex].hover = true;
    this.props.updateJiguInfo(jiguInfo);
  }
  clearJiguInfo = (jiguInfo: Array<Array<JiguInfo>>, type?: "CLICK" | "HOVER") => {
    jiguInfo.map(r => r.map(rr => {
      if (type === "CLICK") {
        rr.active = false;
      } else if (type === "HOVER") {
        rr.hover = false;
      } else {
        rr.active = rr.hover = false;
      }
    }));
  }
}