import React, { Component, HTMLAttributes } from 'react';
import AWSModule from './AWSModule';
import _ from 'lodash';

export interface S3ImageProps {
  s3: {
    Bucket: string;
    Key: string;
  };
  props?: HTMLAttributes<HTMLImageElement>;
}
export interface S3ImageState {
  url?: string;
}

export default class S3Image extends Component<S3ImageProps, S3ImageState> {
  state: S3ImageState = {

  };

  updateImage = async () => {
    const s3 = await new AWSModule('S3').connect();

    while (true) {
      try {
        this.setState({
          url: await new Promise<string>((resolve, reject) => {
            s3.S3!.getSignedUrl('getObject', this.props.s3, (e, r) => {
              if (e) {
                reject(e);
              } else {
                resolve(r);
              }
            })
          }),
        });

        break;
      } catch (e) {
        await new Promise(resolve => setTimeout(resolve, 250));
      }
    }
  }

  componentDidMount = () => {
    this.updateImage();
  }

  componentDidUpdate = (pp: S3ImageProps) => {
    if (!_.isEqual(this.props.s3, pp.s3)) {
      this.updateImage();
    }
  }

  render() {
    if (this.state.url) {
      return (
        <img  {...this.props.props} src={this.state.url} />
      )
    } else {
      return (
        <div>Loading...</div>
      )
    }
  }
}