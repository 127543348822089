import React, { Component, useEffect } from 'react';
import styled, { css } from 'styled-components';

export type ServicePlanTagType = "POINT" | "INFINITE" | undefined;

export interface ServicePlanTagProps {
  type: ServicePlanTagType;
  className?: string;
  style?: React.CSSProperties | undefined;
}

const StyledServicePlanTagWrap = styled.div`
  height: 30px;
  border-radius: 6px;
  padding: 10px;
  font-size: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-weight: bold;
  pointer-events: none;
  width: fit-content;
  border: 1px solid ${(props: ServicePlanTagProps) => {
    switch (props.type) {
      case "POINT": return '#ccc';
      case "INFINITE": return '#48ddf2';
      default: return;
    }
  }};
  color: ${(props: ServicePlanTagProps) => {
    switch (props.type) {
      case "POINT": return '#ccc';
      case "INFINITE": return '#48ddf2';
      default: return;
    }
  }};
`;

const StyledServicePlanTagName = styled.span`
  margin-right: 5px;
  font-size: 14px;
  pointer-events: none;
  ${(props: ServicePlanTagProps) => {
    switch (props.type) {
      case "POINT": return `color: #eee;`
      case "INFINITE": {
        return css`
          background: linear-gradient(95.5deg, #48DDF2 16.29%, #95E4B3 84.25%);
          -webkit-background-clip: text;
          color: transparent;
        `
      }
      default: return;
    }
  }};
`;

const ServicePlanTag = (props: ServicePlanTagProps) => {
  const getTagName = (type: ServicePlanTagType) => {
    let name = "";
    switch (type) {
      case "POINT": name="포인트 플랜"; break;
      case "INFINITE": name="무제한 플랜"; break;
      default: return;
    }
    return name;
  }

  if (props.type) {
    return (
      <StyledServicePlanTagWrap {...props}>
        <StyledServicePlanTagName type={props.type}>{getTagName(props.type)}</StyledServicePlanTagName>
        사용 중
      </StyledServicePlanTagWrap>
    )
  } else {
    return <span></span>;
  }
}

export default ServicePlanTag;