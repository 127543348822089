import * as THREE from '@teneleven/three';
import { degrees2radians, radiansToDegrees } from '@turf/turf';
import { BuildingComponent } from './BuildingPart';
import { ConverterBlock } from './ConverterBlock';
import { ConverterUnit, LineType, Polygon, PolylineInfo } from './DataTypes';
import { ErrorLogCell } from './ErrorLog';
import { switchLineDashedState } from './FileParser';
import { PolygonSimplification } from './PolygonManager';

export class BuildingCoreUnit extends BuildingComponent {
  readonly componentType: 'core' | 'house';

  private area: number;

  constructor(block: ConverterBlock) {
    super(block);

    this.componentType = 'core';
    this.area = 0;
    this.coreNumber = 1;

    block!.entities.forEach(en => {
      this.AddNewPolygon((en as ConverterUnit).polygon);
    })
  }

  AddNewPolygon = (polygon: Polygon) => {
    this.polygon.push(polygon);
    let line = polygon.lineMesh.clone();
    switchLineDashedState(line.material, false);
    this.renderGroup.add(line);
  }

  SetName = (name: string) => { this.name = name; }

  SetPosition = (position: THREE.Vector3) => {
    this.position = position;
    this.renderGroup.position.set(position.x, position.y, position.z);
  }

  SetScale = (scale: THREE.Vector3) => {
    this.scale = scale
    this.renderGroup.scale.set(scale.x, scale.y, 0);
  }

  RotateWithRadians = (radians: number) => {
    this.rotate = radiansToDegrees(radians);
    this.renderGroup.rotateZ(radians);
  }

  RotateWithDegrees = (degress: number) => {
    this.rotate = degress;
    this.renderGroup.rotateZ(degrees2radians(degress));
  }

  SetArea = (area: number) => { this.area = area; }
  GetArea = () => { return this.area; }

  UpdateArea = () => {
    this.totalCoreAreas = this.area;
  }

  RebuildOutputPolygon = () => {
    while (this.outputPolygon.length > 0) {
      this.outputPolygon.splice(0, 1);
    }
    let matrix = this.renderGroup.matrixWorld.clone();
    this.polygon.forEach(p => {
      let verts = PolygonSimplification(p.vertices);
      for (let i = 0; i < verts.length - 1; i++) {
        this.outputPolygon.push({
          line: new THREE.Line3(verts[i].clone().applyMatrix4(matrix), verts[i + 1].clone().applyMatrix4(matrix)),
          thickness: 0.6,
          type: LineType.LT_OUTERWALL,
        })
      }
    })
  }

  toJson = () => {
    let text = {
      name: this.name,
      buildingType: this.buildingType,
      block: this.block.name,
      position: this.position,
      scale: this.scale,
      rotate: this.rotate,
      parts: this.parts.map(p => p.toJson()),
      componentType: this.componentType,
      level: this.level,
      area: this.area,
    }

    return text;
  }
}