import React, { Component, Fragment } from 'react';
import './css/Pagination.scss';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Fab } from '@material-ui/core';
import { ConfigurationServicePlaceholders } from 'aws-sdk/lib/config_service_placeholders';
import { Link } from 'react-router-dom'
import {ReactComponent as DoubleArrowRight} from './img/icon/double_arrow_right.svg';
import {ReactComponent as DoubleArrowLeft} from './img/icon/double_arrow_left.svg';
import Tooltip from './Tooltip';

export interface PaginationProps {
  totalNum: number;
  viewNum: number;
  curPage: number;
  href?: string;
  onChange: (page: number) => void;
  viewPage?: number;
}
export interface PaginationState {
  curPage: number;
  list: number[];
  startPageNum: number;
  endPageNum: number;
}

export default class Pagination extends Component<PaginationProps, PaginationState> {
  state: PaginationState = {
    curPage: 1,
    list: [1],
    startPageNum: 1,
    endPageNum: 1,
  }

  setPageList = () => {
    const setCount = this.props.viewPage ? this.props.viewPage : 10
    const max = Math.ceil(this.props.totalNum / this.props.viewNum);
    const start = Math.floor((this.props.curPage - 1) / setCount ) * setCount + 1;
    let end = Math.ceil(this.props.curPage / setCount ) * setCount;    
    const list = [];
    
    for (let i = start; i <= end && i <= max; i++) {
      list.push(i);
    }

    this.setState({
      curPage: this.props.curPage,
      list: list.length === 0 ? [1] : list,
      startPageNum: 1,
      endPageNum: max,
    })
  }

  componentWillMount = () => {
    this.setPageList();
  }

  componentDidUpdate = (pp: Readonly<PaginationProps>, ps: Readonly<PaginationState>) => {
    if (this.props.viewNum !== pp.viewNum || this.props.totalNum !== pp.totalNum || this.props.curPage !== this.state.curPage) {
      this.setPageList();
    }
  }

  prev = () => {
    if (this.state.startPageNum < this.props.curPage) {
      this.onChange(this.props.curPage - 1);
    }
  }
  next = () => {
    if (this.state.endPageNum > this.props.curPage) {
      this.onChange(this.props.curPage + 1);
    }
  }

  onChange = (page: number) => {
    this.props.onChange(page);
  }

  render() {
    return (
      <div className="Pagination">
        <Tooltip msg="10페이지 전으로 이동">
          <Fab 
            disabled={10 >= this.props.curPage} className={`round-icon double-arrow ${(10 < this.props.curPage) && "enabled" || ""}`}
            disableRipple={true}
            onClick={e => this.onChange(this.props.curPage - 10 >= this.state.startPageNum && this.props.curPage - 10 || this.props.curPage)}
          >
            <DoubleArrowLeft className='icon' />
          </Fab>
        </Tooltip>
        
        <Fab 
          disabled={this.state.startPageNum === this.props.curPage} className={`round-icon arrow ${this.state.startPageNum !== this.props.curPage && "enabled" || ""}`}
          disableRipple={true}
          onClick={e => this.onChange(this.props.curPage > this.state.startPageNum && this.props.curPage - 1 || this.props.curPage)}
        >
          <LeftIcon className='icon' />
        </Fab>
        {
          (this.props.curPage > 10) && 
          <Fragment>
            <Fab key={"go-1"} className="round-icon"
              onClick={e => this.onChange(1)}
            >
            {1}
            </Fab>
            <div className="ellipsis">...</div>
          </Fragment>
          
        }
        {this.state.list.map(p => {
          return this.props.curPage === p &&
            <Fab disabled={true} key={p} onClick={e => this.onChange(p)} className="round-icon active">{p}</Fab> ||
              <Fab key={p} className="round-icon"
                onClick={e => this.onChange(p)}
              >
              {p}
              </Fab>
        })}
        {
          ((this.state.endPageNum - (this.state.endPageNum % 10)) >= this.props.curPage) && 
          <Fragment>
            <div className="ellipsis">...</div>
            <Fab key={"go-end"} className="round-icon"
              onClick={e => this.onChange(this.state.endPageNum)}
            >
            {this.state.endPageNum}
            </Fab>
          </Fragment>
          
        }
        <Fab disabled={this.state.endPageNum === this.props.curPage}
          className={`round-icon arrow ${this.state.endPageNum !== this.props.curPage && "enabled" || ""}`}
          disableRipple={true}
          onClick={e => this.onChange(this.props.curPage < this.state.endPageNum && this.props.curPage + 1 || this.props.curPage)}>
            <RightIcon className='icon' />
        </Fab>
        <Tooltip msg="10페이지 후로 이동">
          <Fab disabled={(this.state.endPageNum - 10) < this.props.curPage}
            className={`round-icon double-arrow ${(this.state.endPageNum - 10) >= this.props.curPage && "enabled" || ""}`}
            disableRipple={true}
            onClick={e => this.onChange(this.props.curPage + 10 <= this.state.endPageNum && this.props.curPage + 10 || this.props.curPage)}>
              <DoubleArrowRight className='icon'/>
          </Fab>  
        </Tooltip>
      </div>
    );
  }
}