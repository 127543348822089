import React, { Component, Fragment } from 'react';
import './css/ProjectValue.scss';

export interface ProjectValueProps {
  className?: string;
  title: string;
  value?: number | string;
  unit?: string;
}
export interface ProjectValueState {
  
}
export default class ProjectValue extends Component<ProjectValueProps, ProjectValueState> {
  render() {
    const valueType = typeof this.props.value;
    return (
      <div className={`ProjectValue ${this.props.className}`}>
        <div className="wrapper">
          <div className="project-value-title">{this.props.title}</div>
          <div className="project-value-value">
            {
              this.props.value !== undefined && valueType === "string" &&
              ((this.props.value as string).length > 0 && this.props.value || "-") ||
              (this.props.value !== undefined ? this.props.value : "-")
            }
            <span className="unit">{this.props.unit}</span>
          </div>
        </div>
      </div>
    )
  }
}