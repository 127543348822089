import React from 'react';
import './css/ConstructionLineInfo.scss';
import { ProjectType } from './model/Project';

const lines = [
  {
    type: "대지영역",
    css: {
      border: "2px dashed #000",
      backgroundColor: "rgba(35, 39, 50, 0.3)",
    }
  },
  {
    type: "인접대지경계선",
    css: {
      background: "rgba(25, 25, 230, 0.15)",
      border: "2px solid #1919E6"
    }
  },
  {
    type: "인접도로",
    css: {
      background: "rgba(90, 121, 165, 0.5)",
    }
  },
  {
    type: "공지영역",
    css: {
      background: "rgba(0, 161, 94, 0.3)",
      border: "1px solid #00A15E",
    }
  },
  {
    type: "특수영역",
    css: {
      background: "rgba(214, 48, 0, 0.3)",
      border: "1px solid #D63000"
    }
  },
  {
    type: "스카이라인",
    css: {
      background: "rgba(0, 118, 227, 0.3)",
      border: "1px solid #0076E3"
    }
  },
]

interface ConstructionLineInfoProps {
  className?: string;
  projectType?: ProjectType;
}

const ConstructionLineInfo = (props: ConstructionLineInfoProps) => {
  return (
    <div className={`ConstructionLineInfo ${props.className}`}>
    {
      lines.map(r => {
        if (props.projectType && ["DESIGNER", "SITE_PLAN"].includes(props.projectType)  && r.type === "스카이라인") {
          return;
        }
        return (
          <div className="line" style={{ display: "flex", alignItems: "center", border: "1px dash" }}>
            <div className="img" style={r.css} />
            <span className="name">{r.type}</span>
          </div>
        )
      })
    }
    </div>
  )
}

export default ConstructionLineInfo;