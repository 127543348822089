import * as THREE from '@teneleven/three';
import { ConverterBlock } from './ConverterBlock';
import { BlockType, Polygon, PolylineInfo } from './DataTypes';
import { ErrorLogCell } from './ErrorLog';

const uuid4 = require('uuid/v4');

export abstract class BuildingPart {
  readonly uuid: string;
  readonly buildingType: 'group' | 'component';
  name: string;
  readonly block: ConverterBlock;
  position: THREE.Vector3;
  scale: THREE.Vector3;
  rotate: number;
  renderGroup: THREE.Group;
  parts: BuildingPart[];

  totalExclusiveAreas: number;
  totalServiceAreas: number;
  totalCommonWallAreas: number;
  totalCoreAreas: number;
  houseNumber: number;
  coreNumber: number;

  abstract RebuildOutputPolygon(): void;
  abstract UpdateArea(): void;
  abstract toJson(): {};

  constructor(block: ConverterBlock) {
    this.uuid = uuid4();
    this.block = block;
    this.buildingType = block.type === BlockType.group ? 'group' : 'component';
    this.name = block.name;
    this.position = new THREE.Vector3();
    this.scale = new THREE.Vector3();
    this.rotate = 0;
    this.renderGroup = new THREE.Group();
    this.parts = [];

    this.totalExclusiveAreas = 0;
    this.totalServiceAreas = 0;
    this.totalCommonWallAreas = 0;
    this.totalCoreAreas = 0;

    this.houseNumber = 0;
    this.coreNumber = 0;
  }
}

export abstract class BuildingComponent extends BuildingPart {
  componentType: 'core' | 'house';
  outputPolygon: PolylineInfo[];
  polygon: Polygon[];
  centerOfAllLine: THREE.Vector3;
  ErrorLog: ErrorLogCell[];
  level: boolean[];

  constructor(block: ConverterBlock) {
    super(block);

    this.componentType = 'core';
    this.outputPolygon = [];
    this.polygon = [];
    this.centerOfAllLine = new THREE.Vector3();
    this.ErrorLog = [];
    this.level = [];
  }
}

export class ConverterBuilding {
  parts: BuildingPart[];
  position: THREE.Vector3;
  name: string;
  renderGroup: THREE.Group;

  constructor() {
    this.parts = [];
    this.position = new THREE.Vector3(0);
    this.name = '';
    this.renderGroup = new THREE.Group();
  }

  toJson() {
    console.log('aaa');
    let json = {
      parts: this.parts.map(p => p.toJson()),
      position: this.position,
      name: this.name,
    }

    return json;
  }
}
