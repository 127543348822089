import React, { Component, Fragment } from 'react';
import './css/ProjectFormHousingChart.scss';
import C3Chart from './C3Chart';
import { Project } from './model/Project';
import AWSModule from './AWSModule';
import HousingChart from './HousingChart';
import { Button } from '@material-ui/core';
import RightArrowIcon from '@material-ui/icons/ArrowForward';

interface ProjectFormHousingChartProps {
  project: Project;
  open: boolean;
  setOpen: (open: boolean) => void;
}
interface ProjectFormHousingChartState {
  
}
export default class ProjectFormHousingChart extends Component<ProjectFormHousingChartProps, ProjectFormHousingChartState> {
  componentWillMount = () => {
    this.props.setOpen(true);
  }

  componentWillUnmount = () => {
    this.props.setOpen(false);
  }
 
  render () {
    return (
      <div className="ProjectFormHousingChart">
        <div className="content">
          <div className="header">
            세대타입 비율
          </div>
          <div className="chart-wrapper">
            {
              <HousingChart project={this.props.project} legend="bottom" id="project-form-housing-c3-chart" width={250} height={300} />
            }            
          </div>
        </div>
      </div>
    )
  }
}