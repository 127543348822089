import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import '../css/CADConverter/Modal.scss'

export enum buttonNum {
  oneButton,
  twoButton,
  noButton
}

export interface ModalProps {
  content: string[],
  title: string,
  open: boolean,

  buttonNum: buttonNum,
  positive: () => void,
}

interface ModalState {
  open: boolean,
  buttonNum: buttonNum,
}

export class Modal extends Component<ModalProps, ModalState> {
  state: ModalState = {
    open: false,
    buttonNum: buttonNum.twoButton,
  }

  componentDidUpdate = (previousProps: Readonly<ModalProps>, previousState: Readonly<ModalState>,) => {
    if (previousProps.open !== this.props.open) {
      this.setState({
        open: true,
        buttonNum: this.props.buttonNum,
      })
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  positive = () => {
    this.props.positive();
    this.handleClose();
  }

  render = () => {
    return (
      <Dialog className='ModalBody' aria-labelledby="simple-dialog-title" open={this.state.open}>
        <div className='modal-title' id="alert-dialog-title">
          {this.props.title && this.props.title || "안내"}
          <div className="icon" hidden={true}><CloseIcon onClick={this.handleClose} /></div>
        </div>
        <div className="modal-content">
          {
            this.props.content.map(c => {
              return <div key={`${c}`} className='content'>{c}</div>
            })
          }
        </div>
        <div className="modal-action">
          <button className="positive-btn" hidden={this.state.buttonNum === buttonNum.noButton} onClick={this.positive}>
            {"확인"}
          </button>
          <button className="negative-btn" hidden={this.state.buttonNum === buttonNum.oneButton || this.state.buttonNum === buttonNum.noButton} onClick={this.handleClose}>
            {"취소"}
          </button>
        </div>
      </Dialog>
    )
  }
}
