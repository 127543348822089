import * as THREE from '@teneleven/three';
import { BlockType, ConverterEntity, ConverterInsert, ConverterUnit, entityType } from './DataTypes';


export class ConverterBlock {
  name: string;
  name2: string;
  entities: ConverterEntity[];
  layer: string;
  position: THREE.Vector3;
  type: BlockType;

  constructor() {
    this.name = '';
    this.name2 = '';
    this.entities = [];
    this.layer = '';
    this.position = new THREE.Vector3(0);
    this.type = BlockType.group;
  }

  toJson = () => {
    let entities: any[] = [];
    this.entities.forEach(e => {
      if (e.type === entityType.INSERT) {
        console.log((e as ConverterInsert).scale);
        entities.push({
          layer: e.layer,
          type: e.type,
          position: (e as ConverterInsert).position,
          scale: (e as ConverterInsert).scale,
          rotate: (e as ConverterInsert).rotate,
          name: (e as ConverterInsert).name,
        })
      }
      else {
        entities.push({
          layer: e.layer,
          type: e.type,
          vertices: (e as ConverterUnit).verts,
          shape: (e as ConverterUnit).shape,
          color: (e as ConverterUnit).color,
        })
      }
    });

    let json = {
      name: this.name,
      name2: this.name2,
      entities: entities,
      layer: this.layer,
      position: this.position,
      type: this.type,
    }

    return json;
  }
}