import React, { Component, Fragment } from 'react';
import './css/pageFoot.scss';

export default class PageFoot extends Component {


  render = () => {
    return (
      <footer className='foot'>
        <div className='footContent'>
          <div className='link'>
            <a href="http://www.1011.co.kr">회사  소개</a> <span>|</span>
            <a target="_blank" href="/termofservice?type=개인%20정보%20처리%20방침">개인정보처리방침</a><span>|</span>
            <a target="_blank" href="/termofservice?type=서비스%20이용%20약관">서비스 이용약관</a>
          </div>
          <div className='text'>Copyright © 2019 BUILDIT</div>
          <img src='/img/logo_bottom.png' className='logo' />
        </div>
      </footer>
    )
  }
}