import * as THREE from '@teneleven/three';
import { degrees2radians, radiansToDegrees } from '@turf/turf';
import { BuildingComponent } from './BuildingPart';
import { ConverterBlock } from './ConverterBlock';
import { CheckVerticesArrayisCCW } from './CoreAndHouseController';
import { ConverterUnit, entityType, LineType, makeHouseState, Polygon, PolylineInfo } from './DataTypes';
import { ErrorLogCell } from './ErrorLog';
import { switchLineDashedState } from './FileParser';
import { checkPointOnLine, PolygonSimplification } from './PolygonManager';

export class BuildingHouseUnit extends BuildingComponent {
  readonly componentType: 'core' | 'house';

  exclusiveArea: number; // 전용 면적
  serviceArea: number; // 발코니 면적
  balconyOver150cm: number;
  balconyLess150cm: number;
  commonWallArea: number;
  levelHeights: number[];
  piloti: number;

  private makeState: makeHouseState;

  constructor(block: ConverterBlock) {
    super(block);

    this.componentType = 'house';
    this.houseNumber = 1;
    this.exclusiveArea = 0;
    this.serviceArea = 0;
    this.balconyLess150cm = 0;
    this.balconyOver150cm = 0;
    this.commonWallArea = 0;
    this.levelHeights = [];
    this.piloti = 0;
    this.makeState = makeHouseState.Finish;

    block!.entities.forEach(en => {
      let p = (en as ConverterUnit).polygon;
      let newPolygon: Polygon = {
        area: p.area,
        hasCurve: p.hasCurve,
        innerMesh: p.innerMesh,
        lineMesh: p.lineMesh,
        selected: p.selected,
        shape: p.shape,
        type: p.type,
        vertices: p.vertices,
        layer: en.layer,
      }
      this.AddNewPolygon(newPolygon);
    })
  }

  CheckCompleteness = () => {

  }

  AddNewPolygon = (polygon: Polygon) => {
    this.polygon.push(polygon);
    let line = polygon.lineMesh.clone();
    switchLineDashedState(line.material, false);
    this.renderGroup.add(line);
  }

  SetName = (name: string) => { this.name = name; }
  GetName = () => { return this.name; }

  SetLevelHeight = (value: number) => {
    for (let i = 0; i < this.levelHeights.length; i++) {
      this.levelHeights[i] = value;
    }
  }

  SetPiloti = (level: number) => {
    level = level > this.level.length ? this.level.length : level;

    for (let i = 0; i < this.level.length; i++) {
      this.level[i] = i < level ? false : true;
    }

    this.piloti = level;
  }

  SetExclusiveArea = (value: number) => {
    this.exclusiveArea = value;
    this.CheckCompleteness();
  }

  SetServiceArea = (value: number) => {
    this.serviceArea = value;
    this.balconyLess150cm = value;
    this.balconyOver150cm = 0;
    this.CheckCompleteness();
  }

  SetBalconyOver150cm = (value: number) => {
    if (value > this.serviceArea) {
      value = this.serviceArea;
    }

    this.balconyOver150cm = value;
    this.balconyLess150cm = this.serviceArea - this.balconyOver150cm;
    this.CheckCompleteness();
  }

  SetCommonWallArea = (value: number) => {
    this.commonWallArea = value;
    this.CheckCompleteness();
  }

  SetPosition = (position: THREE.Vector3) => {
    this.position = position;
    this.renderGroup.position.set(position.x, position.y, position.z);
  }

  SetScale = (scale: THREE.Vector3) => {
    this.scale = scale
    this.renderGroup.scale.set(scale.x, scale.y, 0);
  }

  RotateWithRadians = (radians: number) => {
    this.rotate = radiansToDegrees(radians);
    this.renderGroup.rotateZ(radians);
  }

  RotateWithDegrees = (degress: number) => {
    this.rotate = degress;
    this.renderGroup.rotateZ(degrees2radians(degress));
  }

  UpdateArea = () => {
    this.totalServiceAreas += this.balconyLess150cm;
    this.totalExclusiveAreas += this.exclusiveArea + this.balconyOver150cm;
    this.totalCommonWallAreas += this.commonWallArea;
  }

  RebuildOutputPolygon = () => {
    while (this.outputPolygon.length > 0) {
      this.outputPolygon.splice(0, 1);
    }

    let lines: PolylineInfo[] = [];
    let windowPolygons: { polygon: Polygon, lineType: LineType, maked: boolean }[] = [];
    let matrix = this.renderGroup.matrixWorld;

    this.polygon.forEach(p => {
      if (p.type === entityType.LWPOLYLINE) {
        let verts = PolygonSimplification(p.vertices);
        let worldVerts: THREE.Vector3[] = [];
        for (let i = 0; i < verts.length; i++) {
          worldVerts.push(verts[i].clone().applyMatrix4(matrix));
        }
        if (!CheckVerticesArrayisCCW(worldVerts)) {
          worldVerts = worldVerts.reverse();
        }

        for (let i = 0; i < worldVerts.length - 1; i++) {
          lines.push({
            line: new THREE.Line3(worldVerts[i], worldVerts[i + 1]),
            thickness: 0.6,
            type: LineType.LT_OUTERWALL,
          })
        }
      }
      else if (p.type === entityType.LINE) {
        if (p.layer!.indexOf('WIN1')) {
          windowPolygons.push({
            polygon: p,
            lineType: LineType.LT_LIGHTWINDOW,
            maked: false,
          })
        }

        if (p.layer!.indexOf('WIN2')) {
          windowPolygons.push({
            polygon: p,
            lineType: LineType.LT_OUTERWINDOW,
            maked: false,
          })
        }
      }
    })

    let makeState: makeHouseState = makeHouseState.Finish;
    let offset = 0.001;
    windowPolygons.forEach(wp => {
      for (let wpi = 0; wpi < wp.polygon.vertices.length - 1; wpi++) {
        let v1 = wp.polygon.vertices[wpi].clone().applyMatrix4(matrix);
        let v2 = wp.polygon.vertices[wpi + 1].clone().applyMatrix4(matrix);
        let maked = false;
        for (let i = 0; i < lines.length; i++) {
          if (lines[i].type === LineType.LT_OUTERWALL && v1.distanceTo(v2) > 0.01) {
            let p1 = new THREE.Vector3();
            let p2 = new THREE.Vector3();

            lines[i].line.closestPointToPoint(v1, true, p1);
            lines[i].line.closestPointToPoint(v2, true, p2);

            if ((v1.distanceTo(p1) < offset && checkPointOnLine(p1, lines[i].line)) && (v2.distanceTo(p2) < offset && checkPointOnLine(p2, lines[i].line))) {
              let l1: PolylineInfo, l2: PolylineInfo, l3: PolylineInfo;
              if (checkPointOnLine(p2, new THREE.Line3(p1, lines[i].line.end))) {
                l1 = { line: new THREE.Line3(lines[i].line.start, p1), thickness: 0.6, type: LineType.LT_OUTERWALL };
                l2 = { line: new THREE.Line3(p1, p2), thickness: 0.6, type: wp.lineType };
                l3 = { line: new THREE.Line3(p2, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
              }
              else {
                l1 = { line: new THREE.Line3(lines[i].line.start, p2), thickness: 0.6, type: LineType.LT_OUTERWALL };
                l2 = { line: new THREE.Line3(p2, p1), thickness: 0.6, type: wp.lineType };
                l3 = { line: new THREE.Line3(p1, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
              }
              lines.splice(i, 1, l1, l2, l3);
              i += 2;
              maked = true;
            }
          }
        }
        wp.maked = maked;
      }
    })

    windowPolygons.forEach(wp => {
      if (!wp.maked) {
        if (wp.lineType === LineType.LT_LIGHTWINDOW) {
          makeState = makeState === makeHouseState.outerWindowError ? makeHouseState.allWindowError : makeHouseState.lightWindowError;
        }
        else if (wp.lineType === LineType.LT_OUTERWINDOW) {
          makeState = makeState === makeHouseState.lightWindowError ? makeHouseState.allWindowError : makeHouseState.outerWindowError;
        }
      }
    })

    this.outputPolygon = lines;
    this.makeState = makeState;
  }

  toJson = () => {
    let text = {
      name: this.name,
      buildingType: this.buildingType,
      block: this.block.name,
      position: this.position,
      scale: this.scale,
      rotate: this.rotate,
      parts: this.parts.map(p => p.toJson()),
      componentType: this.componentType,
      level: this.level,
      exclusiveArea: this.exclusiveArea, // 전용 면적
      serviceArea: this.serviceArea, // 발코니 면적
      balconyOver150cm: this.balconyOver150cm,
      balconyLess150cm: this.balconyLess150cm,
      commonWallArea: this.commonWallArea,
      levelHeights: this.levelHeights,
      piloti: this.piloti,
    }

    return text;
  }
}