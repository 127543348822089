import * as THREE from '@teneleven/three';
import * as turf from '@turf/turf';

export function PolygonSimplification(polygon: THREE.Vector3[]) {
    let verts = polygon.slice();
    for (let i = 0; i < verts.length - 1; i++) {
        if (verts[i].distanceTo(verts[i + 1]) < 0.0001) {
            verts.splice(i, 1);
            i = -1;
        }
    }

    for (let i = 0; i < verts.length - 2; i++) {
        let v1 = new THREE.Vector3().subVectors(verts[i], verts[i + 1]).normalize();
        let v2 = new THREE.Vector3().subVectors(verts[i + 1], verts[i + 2]).normalize();

        if (v1.dot(v2) > 0.999 || v1.dot(v2) < -0.999) {
            verts.splice(i + 1, 1);
            i = -1;
        }
    }

    let fv = new THREE.Vector3().subVectors(verts[0], verts[1]).normalize();
    let lv = new THREE.Vector3().subVectors(verts[verts.length - 1], verts[verts.length - 2]).normalize();
    if (fv.dot(lv) > 0.999 || fv.dot(lv) < -0.999) {
        verts[verts.length - 1] = verts[1];
        verts.splice(0, 1);
    }

    return verts;
}

export function checkPointOnLine(point: THREE.Vector3, line: THREE.Line3) {
    if (((point.distanceTo(line.start) + point.distanceTo(line.end)) <= line.distance() + 0.0001))
        return true;
    else
        return false;
}

export function CheckPointInPolygon(point: THREE.Vector3, polygon: THREE.Vector3[]) {
    var pt = turf.point([point.x, point.y]);

    let sv = polygon[0];
    let ev = polygon[polygon.length - 1];
    
    if (sv.distanceTo(ev) < 0.0001 && (sv.x !== ev.x || sv.y !== ev.y)) {
        ev.x = sv.x;
        ev.y = sv.y;
    }

    let polygonPoints: number[][] = [];
    polygon.forEach(p => {
        polygonPoints.push([p.x, p.y]);
    })

    var searchWithin = turf.polygon([polygonPoints]);

    return turf.booleanPointInPolygon(pt, searchWithin);
}