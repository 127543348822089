import React, { Component, ChangeEvent } from 'react';
import * as THREE from '@teneleven/three';
import { getResultData, getURL, getURLData } from './SampleViewerDataManager';
import { Sky } from './../../Viewer/sky';
import { MakeBuildingMesh } from './../../Viewer/MeshManager';
import '../../css/Visualizer.scss';
import { default as moment } from 'moment';
import App from '../../App';
import { ReactComponent as CompassIcon } from '../../img/Visualizer/icon_compass.svg';
import VisibilityIcon from '@material-ui/icons/Visibility'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import ApartmentIcon from '@material-ui/icons/Apartment'
import CameraIcon from '@material-ui/icons/CameraAlt'
import { degreesToRadians } from '@turf/turf';
import { DEMData } from '../../Viewer/DEMManager';
import { OrbitControls } from '@teneleven/three/examples/jsm/controls/OrbitControls'

// const OrbitControls = require('@teneleven/three/examples/js/controls/OrbitControls');
let gifshot = require('gifshot');
let SunCalc = require('suncalc');

interface SceneProps {
  ServerStage: string,
  ProjectID: number,
  index: number,
  dem: THREE.Group,
  subBuilding: THREE.Group,
  siteCenter: THREE.Vector3,
  siteLine: THREE.Line[],
  cameraChange: number,
  getPos: (x: number, y: number) => THREE.Vector3,
  reportInfors: reportInformation[],
  lightIndex: number,
  indexSize: number,
  indexList: number[],
  finished: () => void;
  removeScene: () => void;
  setCamera: (position: THREE.Vector3, target: THREE.Vector3, zoom: number) => void;
  zoomOut: (index: number) => void;
  syncCameraPosition: THREE.Vector3;
  syncCameraTarget: THREE.Vector3;
  syncCameraZoom: number;
  reset: boolean;
  delete: boolean;
  demData: DEMData[];
  season: string;
  centerInLanLat: THREE.Vector2;
}

interface SceneState {
  sceneWidth: number,
  sceneHeight: number,
  // lightDir: THREE.Vector3,
  cameraMode: boolean,
  totalHouse: number,
  floorAreaRatio: number;
  daylightHoursAvg: string;
  isMouseOver: boolean,
  realReportID: number,
  subBuildingVisible: boolean,
  subBuildingButtonHover: boolean,
}

export interface reportInformation {
  type: string,
  floorAreaRatio: number,
  coreAreaRatio: number,
  aveLevelNum: number,
  aveLevelArea: number,
  aveLevelHouse: number,
  maxLevel: number,
  minLevel: number,
  totalBuildingNumber: number,
  totalHouse: number,
  coast: number,
  daylightHoursAvg: number,
  daylightHoursMin: number,
  viewPointAvg: number,
  reportID: number,
  realReportID: number,
}

export class Scene extends Component<SceneProps, SceneState> {
  state: SceneState = {
    sceneHeight: window.innerHeight,
    sceneWidth: window.innerWidth - 120,
    // lightDir: new THREE.Vector3(0),
    cameraMode: true,
    daylightHoursAvg: '',
    floorAreaRatio: 0,
    totalHouse: 0,
    isMouseOver: false,
    realReportID: 0,
    subBuildingVisible: true,
    subBuildingButtonHover: false,
  }

  mount: HTMLDivElement | null = null;
  renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true, alpha: true });
  scene = new THREE.Scene();
  orthoCamera = new THREE.OrthographicCamera(-50, 50, 50, -50, 0.1, 1000);
  orthoControl = new OrbitControls(this.orthoCamera, this.renderer.domElement);

  perspectiveCamera = new THREE.PerspectiveCamera(60, this.state.sceneWidth / this.state.sceneHeight, 0.1, 1000.0);
  perspectiveControls = new OrbitControls(this.perspectiveCamera, this.renderer.domElement);
  dirLight = new THREE.DirectionalLight(0xffffff, 1);
  lightPosOffset = 500;

  renderCamera: THREE.Camera = this.perspectiveCamera;
  mainControl = this.perspectiveControls;

  sunPos: THREE.Vector3[] = [];
  referenceMatrix = new THREE.Matrix4().identity();
  frustumSize = 50;
  buildingGroup = new THREE.Group();
  subbuildingGroup = new THREE.Group();
  demGroup = new THREE.Group();

  sky = new THREE.Mesh();

  buildings = new THREE.Group();

  initResultData = async () => {
    let url = getURL(this.props.ProjectID, `${this.props.index}/locData/resLocation.json`);// `${getHeadOfS3URI(this.props.ProjectID)}/${this.props.index}/locData/resLocation.json`;
    let resJson: any;

    resJson = await getURLData(url);
    App.stage !=="prod" && console.log(resJson);
    this.scene.add(this.buildingGroup);
    (resJson.buildings as []).forEach((b: any) => {
      let building = MakeBuildingMesh(b, this.props.demData);
      let pos = this.props.getPos(b.position.x, b.position.y);
      building.position.set(pos.x, b.position.z * 0.1, pos.z);
      this.buildingGroup.add(building);
    })

    let data = getResultData(this.props.ProjectID)[this.props.index - 1];
    let info: reportInformation = {
      reportID: this.props.index,
      coast: Number(data.loc_construction_cost.toFixed(2)),
      daylightHoursAvg: Number(data.daylight_hours_avg.toFixed(2)),
      daylightHoursMin: Number(data.daylight_hours_min.toFixed(2)),
      maxLevel: Number(data.loc_building_stories_max.toFixed(2)),
      minLevel: Number(data.loc_building_stories_min.toFixed(2)),
      aveLevelArea: Number(data.loc_building_stories_avg.AREA.toFixed(2)),
      aveLevelNum: Number(data.loc_building_stories_avg.NUMERICAL.toFixed(2)),
      aveLevelHouse: Number(data.loc_building_stories_avg.HOUSE.toFixed(2)),
      coreAreaRatio: Number(data.loc_building_land_ratio.toFixed(2)),
      floorAreaRatio: Number(data.loc_floor_area_ratio.toFixed(2)),
      totalBuildingNumber: data.loc_building_number,
      totalHouse: data.loc_total_household,
      viewPointAvg: data.view_point_avg,
      type: '',
      realReportID: data.real_report_number,
    }
    this.props.reportInfors.push(info);
    this.props.finished();

    this.setState({
      realReportID: data.real_report_number,
      totalHouse: data.loc_total_household,
      floorAreaRatio: Number(data.loc_floor_area_ratio.toFixed(2)),
      daylightHoursAvg: moment(0).utc().add(moment.duration(Number(data.daylight_hours_avg.toFixed(2)), 'hours')).format('HH시간 mm분'),
    })

  }

  initSky = () => {
    this.sky = Sky();

    this.sky.geometry = new THREE.SphereBufferGeometry(1, 32, 15);
    this.sky.scale.setScalar(40);
    this.sky.position.set(0, 0, 0);

    (this.sky.material as THREE.ShaderMaterial).depthTest = false;
    this.sky.renderOrder = -1;

    let uniforms = (this.sky.material as THREE.ShaderMaterial).uniforms;
    uniforms["turbidity"].value = 10;
    uniforms["rayleigh"].value = 2;
    uniforms["luminance"].value = 1;
    uniforms["mieCoefficient"].value = 0.005;
    uniforms["mieDirectionalG"].value = 0.8;
    uniforms["cameraPos"].value = this.sky.position;
    uniforms["sunPosition"].value = new THREE.Vector3(10, 10, 10);

    this.scene.add(this.sky);
  }

  addLine = (p1: THREE.Vector3, p2: THREE.Vector3, color: THREE.Color) => {
    let geometry = new THREE.Geometry();
    geometry.vertices.push(p1);
    geometry.vertices.push(p2);
    this.scene.add(new THREE.Line(geometry, new THREE.LineBasicMaterial({ color: color })));
  }

  initLight = () => {
    this.resetLightDir('2017/12/21');

    var light = new THREE.AmbientLight(0x404040);
    this.setSunDir(this.props.lightIndex);

    // let index = this.props.lightIndex;
    // let lightPosition = new THREE.Vector3(this.sunPos[index].x * this.lightPosOffset, this.sunPos[index].y * this.lightPosOffset, this.sunPos[index].z * this.lightPosOffset);
    // (this.sky.material as THREE.ShaderMaterial).uniforms["sunPosition"].value = lightPosition;

    // this.setState({ lightDir: this.sunPos[index] })

    // this.dirLight.position.set(lightPosition.x, lightPosition.y, lightPosition.z);
    this.dirLight.intensity = 0.5;
    this.dirLight.castShadow = true;
    this.dirLight.shadow.bias = -0.001;

    this.dirLight.shadow.camera.far = 1000;
    this.dirLight.shadow.camera.left = -300;
    this.dirLight.shadow.camera.right = 300;
    this.dirLight.shadow.camera.top = 300;
    this.dirLight.shadow.camera.bottom = -300;

    this.dirLight.shadow.mapSize.width = 4096;
    this.dirLight.shadow.mapSize.height = 4096;

    this.scene.add(this.dirLight);
    this.scene.add(this.dirLight.target);

    this.scene.add(new THREE.AmbientLight(0xeeeeee, 0.7));
    this.scene.fog = new THREE.FogExp2(0xdfddd6, 0.002);

    // let helper = new THREE.CameraHelper(this.dirLight.shadow.camera);
    // this.scene.add(helper);
  }

  sph2cart = (azimuth: number, elevation: number, r: number) => {
    let x = r * Math.cos(elevation) * Math.cos(azimuth);
    let y = r * Math.cos(elevation) * Math.sin(azimuth);
    let z = r * Math.sin(elevation);

    return new THREE.Vector3(x, y, z);
  }

  resetLightDir = async (date: string) => {
    this.sunPos = [];
    for (let i = 0; i < 9; i++) {
      let hour = 8 + i;// Math.floor(i / 4);
      // let minute = i % 4 * 15;
      let fulldate = new Date(`${date}/${hour}:00`);
      let pos = SunCalc.getPosition(fulldate, this.props.centerInLanLat.y, this.props.centerInLanLat.x);
      let lightDir = this.sph2cart(-pos.azimuth + Math.PI, pos.altitude, 1);

      this.sunPos.push(new THREE.Vector3(-lightDir.y, lightDir.z, -lightDir.x));
      // lightDirArray.push(`${date}/${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')},${-lightDir.y},${lightDir.z},${-lightDir.x} \n`)
    }
    this.setSunDir(this.props.lightIndex);
  }

  animate = () => {
    requestAnimationFrame(this.animate);

    this.sky.position.set(this.renderCamera.position.x, this.renderCamera.position.y, this.renderCamera.position.z);
    // this.renderer.setSize(this.mount!.scrollWidth, this.mount!.scrollHeight);
    this.renderer.render(this.scene, this.renderCamera);
  }

  componentDidMount = () => {
    this.mount!.appendChild(this.renderer.domElement);

    this.renderer.setSize(this.state.sceneWidth, this.state.sceneHeight);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    // var geometry = new THREE.BoxGeometry(1, 1, 1);
    // var material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    // var cube = new THREE.Mesh(geometry, material);
    // this.scene.add(cube);

    this.perspectiveControls.target.set(this.props.siteCenter.x, this.props.siteCenter.y, this.props.siteCenter.z);
    this.perspectiveControls.object.position.set(this.props.siteCenter.x, this.props.siteCenter.y + 50, this.props.siteCenter.z + 50);
    this.perspectiveControls.panSpeed = 0.4;
    this.perspectiveControls.rotateSpeed = 0.3;
    this.perspectiveControls.mouseButtons.LEFT = THREE.MOUSE.RIGHT;
    this.perspectiveControls.mouseButtons.RIGHT = THREE.MOUSE.LEFT;
    this.perspectiveControls.screenSpacePanning = true;
    this.perspectiveControls.update();

    this.orthoControl.enableRotate = false;
    this.orthoControl.enabled = false;
    this.orthoControl.maxZoom = 10;
    this.orthoControl.minZoom = 0.1;
    this.orthoControl.panSpeed = 0.4;
    this.orthoControl.mouseButtons.LEFT = THREE.MOUSE.RIGHT;
    this.orthoControl.mouseButtons.RIGHT = THREE.MOUSE.LEFT;

    this.subbuildingGroup = this.props.subBuilding.clone();
    this.demGroup = this.props.dem.clone();
    this.scene.add(this.subbuildingGroup);
    this.scene.add(this.demGroup);

    // var axesHelper = new THREE.AxesHelper(5);
    // this.scene.add(axesHelper);
    this.props.siteLine.forEach(l => {
      this.scene.add(l.clone());
    });
    // line mesh
    // var geo = new THREE.Geometry();
    // for (var j = 0; j < Math.PI; j += 2 * Math.PI / 100) {
    //   var v = new THREE.Vector3(Math.cos(j), Math.sin(j), 0);
    //   geo.vertices.push(v);
    // }
    // // console.log(geo);
    // var line = new MeshLine.MeshLine();
    // line.setGeometry(geo, (p: any) => { console.log('aaaaaaa'); return p; });

    // var mat = new MeshLine.MeshLineMaterial({ color: new THREE.Color().setRGB(1, 0, 0) });
    // let mm = new THREE.Mesh(line.geometry, mat);
    // this.scene.add(mm);
    this.changeCameraMode(this.props.cameraChange);

    this.initSky();
    this.initLight();
    this.initResultData();
    this.animate();

    this.onWindowResize();

    window.addEventListener('mousemove', this.updateCampass);
    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
  }

  componentWillUpdate(previousProps: Readonly<SceneProps>, previousState: Readonly<SceneState>) {
    if (previousState.sceneWidth !== this.state.sceneWidth || previousState.sceneHeight !== this.state.sceneHeight) {
      this.renderer.setSize(previousState.sceneWidth, previousState.sceneHeight);
      let aspect = previousState.sceneWidth / previousState.sceneHeight;
      this.perspectiveCamera.aspect = aspect;
      this.perspectiveCamera.updateProjectionMatrix();

      // const frustumSize = 50;
      this.orthoCamera.left = -this.frustumSize * aspect / 2;
      this.orthoCamera.right = this.frustumSize * aspect / 2;
      this.orthoCamera.top = this.frustumSize / 2;
      this.orthoCamera.bottom = -this.frustumSize / 2;
      this.orthoCamera.updateProjectionMatrix();
    }

    if (previousProps.lightIndex !== this.props.lightIndex) {
      this.setSunDir(previousProps.lightIndex);
    }

    if (this.props.cameraChange !== previousProps.cameraChange) {
      this.changeCameraMode(previousProps.cameraChange);
    }

    if (this.props.indexSize !== previousProps.indexSize) {
      this.onWindowResizeByZoom(previousProps.indexSize);
    }

    if (this.props.syncCameraPosition !== previousProps.syncCameraPosition || this.props.syncCameraTarget !== previousProps.syncCameraTarget || this.props.syncCameraZoom !== previousProps.syncCameraZoom) {
      if (this.state.cameraMode) {
        this.orthoControl.object.position.set(previousProps.syncCameraPosition.x, 100, previousProps.syncCameraPosition.z);
        this.orthoControl.target.set(previousProps.syncCameraPosition.x, this.props.siteCenter.y, previousProps.syncCameraPosition.z);
        //@ts-ignore
        this.orthoControl.object.zoom = previousProps.syncCameraZoom;
        //@ts-ignore
        this.orthoControl.object.updateProjectionMatrix();
        this.orthoControl.update();
      }
      else {
        this.perspectiveControls.target.set(previousProps.syncCameraTarget.x, previousProps.syncCameraTarget.y, previousProps.syncCameraTarget.z);
        this.perspectiveControls.object.position.set(previousProps.syncCameraPosition.x, previousProps.syncCameraPosition.y, previousProps.syncCameraPosition.z);
        this.perspectiveControls.update();
      }
    }

    if (this.props.reset !== previousProps.reset) {
      this.lookAtNorth();

      this.setState({
        cameraMode: true,
      })
    }

    if (this.props.delete !== previousProps.delete && previousProps.delete === false) {
      this.deleteScene();
    }

    if (this.state.subBuildingVisible !== previousState.subBuildingVisible) {
      this.subbuildingGroup.visible = !this.state.subBuildingVisible;
    }

    if (this.props.season !== previousProps.season) {
      if (previousProps.season === 'spring') {
        this.resetLightDir('2017/3/20')
      }
      else if (previousProps.season === 'summer') {
        this.resetLightDir('2017/6/21')
      }
      else if (previousProps.season === 'fall') {
        this.resetLightDir('2017/9/22')
      }
      else if (previousProps.season === 'winter') {
        this.resetLightDir('2017/12/21')
      }
    }
  }

  setSunDir = (index: number) => {
    let minValue = Math.floor(index);
    let maxValue = minValue + 1;
    if (maxValue > 8) {
      maxValue = 8;
    }
    let minDir = new THREE.Vector3(this.sunPos[minValue].x, this.sunPos[minValue].y, this.sunPos[minValue].z);
    let maxDir = new THREE.Vector3(this.sunPos[maxValue].x, this.sunPos[maxValue].y, this.sunPos[maxValue].z);
    let newDir = minDir.add(maxDir.sub(minDir).multiplyScalar(index - minValue)).multiplyScalar(this.lightPosOffset);

    (this.sky.material as THREE.ShaderMaterial).uniforms["sunPosition"].value = newDir;
    this.dirLight.position.set(newDir.x, newDir.y, newDir.z);
  }

  onKeyUp = async (event: KeyboardEvent) => {
    switch (event.key) {
      case 'a':
        // this.ScreenShot();
        // await this.deleteScene();
        break;
      default: break;
    }
  }

  resetCameraAspect = (width: number, height: number) => {
    this.renderer.setSize(width, height);
    let aspect = width / height;
    this.perspectiveCamera.aspect = aspect;
    this.perspectiveCamera.updateProjectionMatrix();
  }

  rotateCameraAndCaptureForGIF = (gifWidth: number, gifHeight: number, frameCount: number) => {
    let images: any[] = [];
    // let gifWidth = 1920 / 2;
    // let gifHeight = 1080 / 2;
    // let frameCount = 20;
    let renderSize = new THREE.Vector2();
    let centerPos = this.perspectiveControls.target as THREE.Vector3;
    let cameraPos = this.perspectiveControls.object.position as THREE.Vector3;
    let cVector = this.perspectiveControls.object.position.clone().sub(this.perspectiveControls.target) as THREE.Vector3;

    this.renderer.getSize(renderSize);
    this.resetCameraAspect(gifWidth, gifHeight);

    for (let i = 0; i < frameCount; i++) {
      let cv = cVector.applyAxisAngle(new THREE.Vector3(0, 1, 0), degreesToRadians(360 / frameCount));

      this.perspectiveControls.object.position.set(centerPos.x + cv.x, centerPos.y + cv.y, centerPos.z + cv.z);
      this.perspectiveControls.update();

      this.renderer.setSize(gifWidth, gifHeight);
      this.renderer.render(this.scene, this.perspectiveCamera);

      images.push({ src: this.renderer.domElement.toDataURL() })
    }

    this.resetCameraAspect(renderSize.x, renderSize.y);
    this.perspectiveControls.object.position.set(cameraPos.x, cameraPos.y, cameraPos.z);

    return images;
  }

  ScreenShot = () => {
 let imgData;
    var strMime = "image/jpeg";
    try {
      let ssRenderer = new THREE.WebGLRenderer({ antialias: true });
      ssRenderer.setSize(2560, 1440);
      let camera = new THREE.PerspectiveCamera(60, 2560 / 1440, 0.1, 450.0);
      camera.position.set(this.renderCamera.position.x, this.renderCamera.position.y, this.renderCamera.position.z);
      camera.matrix = this.renderCamera.matrix.clone();
      camera.lookAt(this.mainControl.target);
      camera.updateMatrix();

      ssRenderer.shadowMap.enabled = true;
      ssRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
      ssRenderer.render(this.scene, camera);

      imgData = ssRenderer.domElement.toDataURL(strMime);
      var link = document.createElement('a');
      if (typeof link.download === 'string') {
        document.body.appendChild(link);
        link.download = `${this.props.ProjectID}_${this.props.index}_${performance.now()}.jpg`;
        link.href = imgData;
        link.click();
        document.body.removeChild(link);
      }
      ssRenderer.dispose();
      //@ts-ignore
      camera = null;
    }
    catch (e) {

    }
  }

  lookAtNorth = () => {
    this.perspectiveControls.target.set(this.props.siteCenter.x, this.props.siteCenter.y, this.props.siteCenter.z);
    this.perspectiveControls.object.position.set(this.props.siteCenter.x, this.props.siteCenter.y + 50, this.props.siteCenter.z + 50);
    this.perspectiveControls.update();
  }

  onWindowResize = () => {
    this.setState({
      sceneWidth: this.props.indexList.length > 1 ? (window.innerWidth - 120) / 2 : (window.innerWidth - 120),
      sceneHeight: this.props.indexList.length > 2 ? (window.innerHeight - 60) / 2 : (window.innerHeight - 60),
    })
  }

  onWindowResizeByZoom = (size: number) => {
    this.setState({
      isMouseOver: false,
      sceneWidth: size > 1 ? (window.innerWidth - 120) / 2 : (window.innerWidth - 120),
      sceneHeight: size > 2 ? (window.innerHeight - 60) / 2 : (window.innerHeight - 60),
    })
  }

  updateCampass = () => {
    let dir = new THREE.Vector3(0, 0, 0);
    this.renderCamera.getWorldDirection(dir);
    dir.projectOnPlane(new THREE.Vector3(0, 1, 0));
    dir.normalize();
    const worldDir = new THREE.Vector3(0, 0, -1);
    let angle = Math.acos(worldDir.dot(dir));
    angle = THREE.MathUtils.radToDeg(angle);

    worldDir.set(1, 0, 0);
    if (worldDir.dot(dir) < 0) {
      angle = 360 - angle;
    }

    let c = document.querySelector(`.compassImg${this.props.index}`) as HTMLImageElement;
    if (c) {
      c.style.transform = `rotate(${Math.floor(-angle)}deg)`;
    }
  }

  changeCameraMode = (cameraChange: number) => {
    let cameraMode = true;
    if (cameraChange % 2 === 0) {
      cameraMode = false;
    }

    if (cameraMode) {
      this.renderCamera = this.orthoCamera;
      this.perspectiveControls.enabled = false;
      this.orthoControl.enabled = true;
      let aspect = this.state.sceneWidth / this.state.sceneHeight;

      this.orthoCamera.left = -this.frustumSize * aspect / 2;
      this.orthoCamera.right = this.frustumSize * aspect / 2;
      this.orthoCamera.top = this.frustumSize / 2;
      this.orthoCamera.bottom = -this.frustumSize / 2;
      this.orthoCamera.updateProjectionMatrix();

      this.orthoControl.object.position.set(this.props.siteCenter.x, 100, this.props.siteCenter.z);
      this.orthoControl.target.set(this.props.siteCenter.x, this.props.siteCenter.y, this.props.siteCenter.z);
      this.orthoControl.update();
    } else {
      this.renderCamera = this.perspectiveCamera;
      this.perspectiveControls.enabled = true;
      this.orthoControl.enabled = false;
      this.lookAtNorth();
    }

    this.setState({ cameraMode: cameraMode });
  }

  componentWillUnmount = () => {
    this.mount!.removeChild(this.renderer.domElement);
  }

  onMouseSceneOver = () => {
    if (this.props.indexList.length < 2)
      return;
    this.setState({
      isMouseOver: true,
      sceneWidth: this.props.indexList.length > 1 ? ((window.innerWidth - 120) / 2 - 4) : (window.innerWidth - 124),
      sceneHeight: this.props.indexList.length > 2 ? ((window.innerHeight - 60) / 2 - 4) : ((window.innerHeight - 60) - 4),
    })
  }

  onMouseSceneOut = () => {
    if (this.props.indexList.length < 2)
      return;

    this.setState({
      isMouseOver: false,
      sceneWidth: this.props.indexList.length > 1 ? (window.innerWidth - 120) / 2 : (window.innerWidth - 120),
      sceneHeight: this.props.indexList.length > 2 ? (window.innerHeight - 60) / 2 : (window.innerHeight - 60),
    })
  }

  deleteScene = async () => {
    App.stage !=="prod" && console.log('delete');
    this.scene.remove(this.buildingGroup);
    this.scene.remove(this.subbuildingGroup);
    this.scene.remove(this.demGroup);

    while (this.buildingGroup.children.length > 0) {
      let c = this.buildingGroup.children[0];
      this.buildingGroup.remove(c);
      while (c.children.length > 0) {
        let cc = c.children[0];
        c.remove(cc);
        while (cc.children.length > 0) {
          let ccc = cc.children[0];
          cc.remove(ccc);
          this.scene.remove(ccc);
          (ccc as THREE.Mesh).geometry.dispose();
          ((ccc as THREE.Mesh).material as THREE.Material).dispose();
        }
      }
    }

    while (this.subbuildingGroup.children.length > 0) {
      (this.subbuildingGroup.children[0] as THREE.Mesh).geometry.dispose();
      ((this.subbuildingGroup.children[0] as THREE.Mesh).material as THREE.Material).dispose();
      this.subbuildingGroup.remove(this.subbuildingGroup.children[0]);
    }

    while (this.demGroup.children.length > 0) {
      (this.demGroup.children[0] as THREE.Mesh).geometry.dispose();
      ((this.demGroup.children[0] as THREE.Mesh).material as THREE.Material).dispose();
      this.demGroup.remove(this.demGroup.children[0]);
    }

    // while (this.props.subBuilding.children.length > 0) {
    //   (this.props.subBuilding.children[0] as THREE.Mesh).geometry.dispose();
    //   ((this.props.subBuilding.children[0] as THREE.Mesh).material as THREE.Material).dispose();
    //   this.props.subBuilding.remove(this.props.subBuilding.children[0]);
    // }

    // while (this.props.dem.children.length > 0) {
    //   (this.props.dem.children[0] as THREE.Mesh).geometry.dispose();
    //   ((this.props.dem.children[0] as THREE.Mesh).material as THREE.Material).dispose();
    //   this.props.dem.remove(this.props.dem.children[0]);
    // }

    this.scene.remove(this.sky);
    this.sky.geometry.dispose();
    (this.sky.material as THREE.Material).dispose();
    App.stage !=="prod" && console.log('remove');
    this.renderer.dispose();
    this.props.removeScene();
  }

  render = () => {
    return (
      <div className={`Canvas ${this.state.isMouseOver && 'mouseOver' || ''}`} ref={(mount) => { this.mount = mount }} onMouseOver={this.onMouseSceneOver} onMouseOut={this.onMouseSceneOut}>
        <div className='rightTop'>
          <div className='compassButton' onClick={this.lookAtNorth}>
            <img className={`compassImg${this.props.index}`} src='../img/icon_compass.png' />
          </div>

          <div className={`imageButton`} onClick={this.ScreenShot}>
            <span className="tooltiptext">현재 결과 캡쳐</span>
            <CameraIcon className={'image'} />
          </div>

          <div className={`imageButton`} onClick={() => this.setState({ subBuildingVisible: !this.state.subBuildingVisible })} onMouseOver={() => this.setState({ subBuildingButtonHover: true })} onMouseOut={() => this.setState({ subBuildingButtonHover: false })}>
            <span className="tooltiptext">주변 건물</span>
            <img className={'image'} src={`../img/icon_subbuildings${(this.state.subBuildingVisible || this.state.subBuildingButtonHover) && '_on' || ''}.png`} />
          </div>

          <div className={`imageButton ${this.props.indexList.length < 2 && 'hidden' || ''}`} onClick={() => this.props.setCamera(this.renderCamera.position, this.mainControl.target, this.orthoCamera.zoom)}>
            <span className="tooltiptext">동일 시점으로 보기</span>
            <VisibilityIcon className={'image'} />
          </div>

          <div className={`imageButton ${this.props.indexList.length < 2 && 'hidden' || ''}`} onClick={() => this.props.zoomOut(this.props.index)}>
            <span className="tooltiptext">크게 보기</span>
            <ZoomOutMapIcon className={'image'} />
          </div>
        </div>

        <div className='leftTop'>
          <div className='titleDiv'><div className='title'>{this.state.realReportID}번 결과</div></div>
          <div className='parameter'><div className='name'>달성 용적률</div><div className='value'>{this.state.floorAreaRatio}%</div></div>
          <div className='parameter'><div className='name'>달성 세대 수</div><div className='value'>{this.state.totalHouse}세대</div></div>
          <div className='parameter'><div className='name'>평균 일조시간</div><div className='value'>{this.state.daylightHoursAvg}</div></div>
        </div>
      </div>
    )
  }
}