import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import { Button } from '@material-ui/core';
import { ConverterInputBox } from './ConverterInputBox';
import { ConverterLayer, PartOfSelect } from './DataTypes';
import { Field, FieldType } from './Field';
import '../css/CADConverter/HouseAndCore.scss';
import { CheckColsedPolygon, switchLayerState } from './CoreAndHouseController';
import { Height } from '@material-ui/icons';

interface fieldProp {
  field: Field,
  selectLayer: ConverterLayer | null,
  settingID: string,
  showLayerList: (id: string) => void,
  removeField: (field: Field) => void,
  valueChanged: () => void,
  showModel: (information: string) => void,
}

interface fieldState {
  id: string,
  name: string
  value: number,
}

enum InputType {
  area = '면적',
  height = '높이',
  none = '없음'
}

// user interface
export class FieldUI extends Component<fieldProp, fieldState> {
  state: fieldState = {
    name: this.props.field.name,
    id: this.props.field.id,
    value: this.props.field.typeName === FieldType.topography ? this.props.field.getHeight() : this.props.field.getArea(),
  }

  componentDidMount = async () => {

  }

  componentDidUpdate = (previousProps: Readonly<fieldProp>, previousState: Readonly<fieldState>) => {
    if (this.props.settingID !== this.props.field.id) {
      return;
    }

    if (this.props.selectLayer !== previousProps.selectLayer) {
      if (!this.props.selectLayer)
        return;

      if (!CheckColsedPolygon(this.props.selectLayer)) {
        // setErrorColorForLayer(layer);
        this.props.showModel('닫친 폴리곤이 아닙니다.');
        return;
      }

      this.props.field.setLayer(this.props.selectLayer);
      this.setState({
        value: this.props.field.getArea()
      });
    }
  }

  //to-do: 저장된 면적이 입력한 면작 인지 확니 필요
  valueChanged = (value: number) => {
    if (this.getInputType() === InputType.area)
      this.props.field.setArea(value);
    else if (this.getInputType() === InputType.height)
      this.props.field.setHeight(value);
    this.props.valueChanged();
    this.setState({ value: value })
  }

  //to-do: need rebuild function
  checkSelectState = () => {
    return this.props.settingID === this.props.field.id;
  }

  deleteWall = () => {
    switchLayerState(this.props.field.getLayer());
    this.props.field.setNullLayer();

    this.props.valueChanged();
  }

  getInputType = () => {
    switch (this.props.field.typeName) {
      case FieldType.site:
      case FieldType.vacancyInside:
        return InputType.area;

      case FieldType.topography:
        return InputType.height;

      // case FieldType.road:
      // case FieldType.vacancyOutside:
      // case FieldType.centerLineOfRoad:
      default:
        return InputType.none;
    }
  }

  render = () => {
    return (
      <div className='field' key={this.state.id}>
        <div className='nameDiv'>{this.state.name}<CloseIcon className='closeIcon' onClick={e => this.props.removeField(this.props.field)} /></div>
        <div className='buttonDiv'>
          <div className={`selectButton ${this.props.settingID === this.props.field.id && 'selected' || 'unselected'}`} onClick={e => this.props.showLayerList(this.props.field.id)} >
            <span className='text'>{this.props.field.typeName} 선택</span>
            <CheckIcon className={`CheckIcon ${this.props.field.getFieldLayerName() && 'checked'}`} />
          </div>
          <div className='nameText'>
            {this.props.field.getFieldLayerName()}
            <CloseIcon className={`closeIcon ${(this.checkSelectState() && this.props.field.getLayer()) && 'selected' || 'unselected'} ${this.props.field.getLayer() && 'show' || 'hide'}`} onClick={this.deleteWall} />
          </div>
        </div>
        <div className={`inputValues ${this.getInputType() === InputType.none && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
          {
            this.getInputType() === InputType.area && <ConverterInputBox name='면적' value={this.state.value} valueOnChange={this.valueChanged} unit='㎡' step={0.01} />
          }
          {
            this.getInputType() === InputType.height && <ConverterInputBox name='해발 고도' value={this.state.value} valueOnChange={this.valueChanged} unit='m' step={0.01} />
          }
        </div>
        <div className='Line' />
      </div>
    )
  }
}
