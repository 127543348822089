import React, { Component } from 'react';

export interface TabsProps {
  className?: string;
  align?: "horizontal" | "vertical";
}
export default class Tabs extends Component<TabsProps> {
  render() {
    return (
      <div className={`Tabs ${this.props.className && this.props.className} ${this.props.align && this.props.align || "horizontal"}`}>
      {
        this.props.children
      }
      </div>
    )
  }
}