import React, { Component, ChangeEvent } from 'react';
import { Scene, reportInformation } from './scene';
import wkx from 'wkx';
import * as THREE from '@teneleven/three';
import _ from 'lodash';
import App from '../../App';
import queryString from 'query-string';
import { default as moment } from 'moment';
import { ReactComponent as SunIcon } from '../../img/Visualizer/icon-sun.svg';// '../img/Visualizer/icon-sun.svg';
import { ReactComponent as SunIconHover } from '../../img/Visualizer/icon-sun-hover.svg';
import { ReactComponent as TopViewIcon } from '../../img/Visualizer/icon-top-view.svg';
import { ReactComponent as TopViewIconHover } from '../../img/Visualizer/icon-top-view-hover.svg';
import Checkbox from '@material-ui/core/Checkbox';

import InfoIcon from '@material-ui/icons/Info'
import SettingBackIcon from '@material-ui/icons/SettingsBackupRestore'
import { InformationModal } from '../../Viewer/InformationModal';
import { DEMData, getDemHeight } from '../../Viewer/DEMManager';
import { getProjectData, getResultData, getURL, getURLData } from './SampleViewerDataManager';
import { OBJLoader } from '@teneleven/three/examples/jsm/loaders/OBJLoader';
import { tm2lonlat } from '../../Viewer/DBManager';

interface ReportIndexInfo {
  successed: boolean;
  reportID: number;
  realReportID: number;
  added: boolean;
}

interface VisualizerProps {
}

interface VisualizerState {
  ServerStage: string,
  ProjectID: number,
  ProjectName: string,
  userProjectID: number,
  index: number[],
  reportIndexList: ReportIndexInfo[],
  // reportList: boolean[],
  // reportIndex: number[],
  dem: THREE.Group;
  demCount: number;
  subBuilding: THREE.Group;
  subBuildingCount: number;
  handle: number;
  siteCenterInScene: THREE.Vector3;
  siteCenterInLonLat: THREE.Vector2;
  centerInTM: THREE.Vector3;
  demFinished: boolean;
  subModelFinished: boolean;
  cameraChange: number;
  siteArea: number;
  siteUseDistrict: string;
  showInfo: boolean;
  showLightControl: boolean;
  showLightSlider: boolean;
  lightIndex: number;
  loadingMessage: string;
  loadingProgess: string;
  finished: number;
  sceneSize: number;
  syncCameraPosition: THREE.Vector3;
  syncCameraTarget: THREE.Vector3;
  syncCameraZoom: number;
  reset: boolean;
  address: string;
  slider: boolean;
  sunButtonOver: boolean;
  topViewButtonOver: boolean;
  season: 'spring' | 'summer' | 'fall' | 'winter',
}

export class SampleVisualizer extends Component<VisualizerProps, VisualizerState> {
  state: VisualizerState = {
    ServerStage: App.stage,
    ProjectID: 0,
    ProjectName: '',
    userProjectID: 0,
    index: [],
    dem: new THREE.Group(),
    subBuilding: new THREE.Group(),
    handle: 1,
    siteCenterInScene: new THREE.Vector3(0),
    siteCenterInLonLat: new THREE.Vector2(0),
    centerInTM: new THREE.Vector3(0),
    demFinished: false,
    subModelFinished: true,
    subBuildingCount: 0,
    cameraChange: 0,
    siteArea: 0,
    siteUseDistrict: '',
    showInfo: false,
    showLightControl: true,
    showLightSlider: true,
    lightIndex: 4,
    loadingMessage: '지형 정보를 불러오는 중입니다.',
    loadingProgess: ' (0/9)',
    reportIndexList: [],
    // reportList: [],
    // reportIndex: [],
    sceneSize: 0,
    syncCameraPosition: new THREE.Vector3(0),
    syncCameraTarget: new THREE.Vector3(0),
    syncCameraZoom: 1,
    reset: false,
    slider: false,
    address: '',
    sunButtonOver: false,
    topViewButtonOver: false,
    season: "winter",
    demCount: 0,
    finished: 0,
  }

  siteVertsArray: THREE.Vector2[][] = [];
  siteLine: THREE.Line[] = [];
  demData: DEMData[] = [];
  reportInformation: reportInformation[] = [];
  lightMiddleIndex = 4;
  lightTimeText = '13:00';
  subBuildingRange = 500;

  getScenePos = (x: number, y: number) => {
    let newPosX = ((x - this.state.centerInTM.x) / 10);
    let newPosY = -((y - this.state.centerInTM.z) / 10);

    return new THREE.Vector3(newPosX, 0, newPosY);
  }

  componentDidUpdate(previousProps: VisualizerProps, previousState: VisualizerState) {
    if (previousState.siteCenterInScene !== this.state.siteCenterInScene) {
      this.state.subBuilding.position.set(this.state.siteCenterInScene.x, this.state.siteCenterInScene.y, this.state.siteCenterInScene.z);
    }
  }

  generateDEMMesh = async (dem: number[][], x: number, y: number, TMx: number, TMy: number) => {
    let demWidth = 257;
    const geometry = new THREE.PlaneBufferGeometry(demWidth - 1, demWidth - 1, demWidth - 1, demWidth - 1);
    geometry.rotateX(-Math.PI / 2);
    geometry.rotateY(-Math.PI / 2);

    for (let y = 0; y < demWidth; y++) {
      for (let x = 0; x < demWidth; x++) {
        geometry.attributes.position.setY(x + y * demWidth, dem[x][demWidth - y - 1] * 0.1);
      }
    }

    geometry.computeVertexNormals();
    var material = new THREE.MeshLambertMaterial({ color: 0xffffff });
    // material.wireframe = true;

    let mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(y, 0, x);
    mesh.receiveShadow = true;
    mesh.castShadow = true;

    await new Promise(async (resolve) => {
      let texture = new THREE.TextureLoader().load(getURL(this.state.ProjectID, `0/tex/${TMx}_${TMy}.png`));
      material.map = texture;

      this.state.dem.add(mesh);
      this.setState({
        demCount: this.state.demCount + 1,
        loadingMessage: `지형 정보를 불러오는 중입니다.`,
        loadingProgess: ` (${this.state.demCount}/9)`,
      })
      resolve();
    })
  }

  setSiteLineMesh = async () => {
    await Promise.all(this.siteVertsArray.map(async a => {
      let siteArray: THREE.Vector3[] = [];
      let lineGeo = new THREE.Geometry();
      a.forEach(v => {
        let pos = this.getScenePos(v.x, v.y);
        siteArray.push(new THREE.Vector3(pos.x, getDemHeight(this.demData, v) * 0.1, pos.z));
      });

      siteArray.forEach(s => {
        lineGeo.vertices.push(s);
      });

      var material = new THREE.LineBasicMaterial({
        color: 0xff0000
      });

      this.siteLine.push(new THREE.Line(lineGeo, material));
    }))
  }

  getDEM = async (TMx: number, TMy: number) => {
    // let s3 = await new AWSModule("S3").connect();

    const cx = (Math.floor(TMx / 2560)) * 2560;
    const cy = (Math.floor(TMy / 2560)) * 2560 + 1120;

    const dems: number[][] = [];
    let offset = 1;
    for (let i = -offset; i <= offset; i += 1) {
      for (let j = -offset; j <= offset; j += 1) {
        dems.push([cx + 2560 * i, cy + 2560 * j])
      }
    }

    await Promise.all(dems.map(async key => {
      // const sr = await getS3Buffer(`s3://buildit-sample-bucket/sample_report/sample${this.state.ProjectID}/3DViewer/0/dem/${key[0] + '_' + key[1]}.dem`);
      // console.log(sr);
      const sr = await getURLData(getURL(this.state.ProjectID, `0/dem/${key[0]}_${key[1]}.dem`), true);
      const vals = _.range(257).map(i => {
        return _.range(257).map(j => sr.readFloatLE((i * 257 + j) * 4))
      });
      this.demData.push({ pos: new THREE.Vector2(key[0], key[1]), value: vals });
      await this.generateDEMMesh(vals, (key[0] - cx) * 0.1, (key[1] - cy) * 0.1, key[0], key[1]);
    }));

    this.state.dem.rotateY(Math.PI / 2);
    this.setState({
      centerInTM: new THREE.Vector3(cx + 1280, 0, cy + 1280),
    }, () => this.setState({
      siteCenterInScene: this.getScenePos(TMx, TMy),
      siteCenterInLonLat: tm2lonlat(new THREE.Vector2(TMx, TMy)),
      demFinished: true,
    }))
    await this.setSiteLineMesh();
  }

  getSubBuilding = async (TMx: number, TMy: number, siteArray: string[]) => {
    let loader = new OBJLoader();
    this.state.subBuilding.scale.set(0.1, 0.1, -0.1);
    let subGeo = new THREE.Geometry();
    let count = 0;

    let listData = await getURLData(getURL(this.state.ProjectID, '0/obj/list.txt'));
    let buildingsName = listData.toString().split('\n');
    for (let i = 0; i < buildingsName.length - 1; i++) {
      await new Promise(resolve => {
        loader.load(getURL(this.state.ProjectID, `0/obj/${buildingsName[i]}`),//`https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/sample_report/sample1/3DViewer/0/obj/${buildingsName[i]}`,
          (model: THREE.Group) => {
            (model.children[0] as THREE.Mesh).position.set(- TMx, 0, - TMy);
            (model.children[0] as THREE.Mesh).updateMatrix();
            let geo = new THREE.Geometry().fromBufferGeometry((model.children[0] as THREE.Mesh).geometry as THREE.BufferGeometry);
            for (let i = 0; i < geo.faces.length; i++) {
              let temp = geo.faces[i].a;
              geo.faces[i].a = geo.faces[i].c
              geo.faces[i].c = temp;
            }
            geo.computeVertexNormals();
            subGeo.merge(geo, (model.children[0] as THREE.Mesh).matrix);
            count++;

            if (count > 300) {
              this.state.subBuilding.add(this.makeSubMesh(subGeo));
              count = 0;
              subGeo = new THREE.Geometry();
            }
            this.setState({
              subBuildingCount: this.state.subBuildingCount + 1,
              loadingMessage: `주변 건물 정보를 불러오는 중입니다. `,
              loadingProgess: `(${this.state.subBuildingCount}/${buildingsName.length - 1})`,
            })
            resolve();
          },
          (xhr: any) => {
            // console.log(xhr);
          },
          (error: any) => {
            // console.log(error);
          }
        )
      })
    }

    this.state.subBuilding.add(this.makeSubMesh(subGeo));
    App.stage !== "prod" && console.log(this.state.subBuilding.children.length);
    this.setState({ subModelFinished: true });
  }

  makeSubMesh = (geo: THREE.Geometry) => {
    let mesh = new THREE.Mesh(geo, new THREE.MeshLambertMaterial({ color: '#ffffff' }));
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    return mesh;
  }

  componentDidMount = async () => {
    const qs = queryString.parse(window.location.search);
    // this.setState({
    //   index: Number(qs.rid)
    // }, async () => {
    const rid = qs.rid! instanceof Array ? qs.rid!.map(Number) : [parseInt(qs.rid as string)];
    this.state.index = rid;
    let projectID = Number(qs.pid);
    let project = getProjectData(projectID);
    let reports = getResultData(projectID);

    if (project) {
      await this.setState({
        ProjectID: projectID,
        userProjectID: Number(qs.pid),
        // @ts-ignore
        ProjectName: project.project_name,
        // @ts-ignore
        address: project.project_address,
        // @ts-ignore
        siteUseDistrict: project.project_use_district,
      }, async () => {
        let url = getURL(this.state.ProjectID, 'ShapeInfo.json');
        let shapeJson = await getURLData(url);
        App.stage !== "prod" && console.log(shapeJson);
        let center = new THREE.Vector2(0, 0);
        let count = 0;

        (shapeJson.projectSite as []).forEach((ps: any) => {
          // @ts-ignore
          let gj = wkx.Geometry.parse(ps).toGeoJSON().coordinates as number[][][];

          gj.forEach(g => {
            let vArray: THREE.Vector2[] = [];
            g.forEach(c => {
              center.add(new THREE.Vector2(c[0], c[1]));
              vArray.push(new THREE.Vector2(c[0], c[1]));
            })
            count += g.length;
            this.siteVertsArray.push(vArray);
          })
        })
        center.divideScalar(count);
        let exludeArray = shapeJson.projectSite.concat(shapeJson.vacancyInside);
        App.stage !== "prod" && console.log(exludeArray);
        await this.getDEM(center.x, center.y);
        await this.getSubBuilding(center.x, center.y, exludeArray);

        let reportIndexList: ReportIndexInfo[] = [];

        for (let i = 0; i < reports.length; i++) {
          reportIndexList.push({
            added: false,
            realReportID: reports[i].real_report_number,
            reportID: i,
            successed: true,
          })
        }

        (rid as []).map(i => {
          reportIndexList[i - 1].added = true;
        })

        this.setState({
          siteArea: shapeJson.projectSiteArea,
          reportIndexList: reportIndexList,
        })
      })
    } else {
      // console.log(rr.error);
    }
    // window.addEventListener("keyup", this.onKeyUp, false);
  }

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'b':
        App.stage !== "prod" && console.log(this.reportInformation);
        break;
      default: break;
    }
  }

  getInfoById = (index: number) => {
    return this.reportInformation.find((e) => { return index === e.reportID; })
  }

  showLightSlider = () => {
    let s = document.querySelector('.circleSlider') as HTMLInputElement;
    if (this.state.showLightSlider) {
      s.animate(
        [
          { bottom: `${-s.scrollHeight - 10}px` },
          { bottom: `-45px` },
        ], {
        duration: 400,
      }
      );
      s.style.bottom = `-45px`;
    }
    else {
      s.animate(
        [
          { bottom: `-45px` },
          { bottom: `${-s.scrollHeight - 10}px` },
        ], {
        duration: 400,
      }
      );
      s.style.bottom = `${-s.scrollHeight - 10}px`;
    }

    this.setState({
      showLightSlider: !this.state.showLightSlider,
    })
  }

  AddRemoveScene = (index: number) => {
    let indices = this.state.index;
    let i = indices.findIndex(i => i === index + 1);
    if (i >= 0) {
      if (indices.length == 1)
        return;
      // this.state.reportList[index] = false;
      this.state.reportIndexList[index].added = false;
    }
    else {
      if (this.state.index.length >= 4)
        return;

      indices.push(index + 1);
      this.state.reportIndexList[index].added = true;
      // this.state.reportList[index] = true;
    }

    this.setState({
      index: indices,
      sceneSize: indices.length,
    });
  }

  RemoveScene = (index: number) => {
    let indices = this.state.index;
    let i = indices.findIndex(i => i === index + 1);
    indices.splice(i, 1);
    this.setState({
      index: indices,
      sceneSize: indices.length,
    });
  }

  ZoomOutScene = (index: number) => {
    let reportIndexList = this.state.reportIndexList;

    for (let i = 0; i < reportIndexList.length; i++) {
      if (i !== index - 1) {
        reportIndexList[i].added = false;
      }
    }
    this.setState({ reportIndexList: reportIndexList });
  }

  SetCameraAngle = (position: THREE.Vector3, target: THREE.Vector3, zoom: number) => {
    this.setState({
      syncCameraPosition: new THREE.Vector3().copy(position),
      syncCameraTarget: new THREE.Vector3().copy(target),
      syncCameraZoom: zoom,
    })
  }

  ResetAllScene = () => {
    App.stage !== "prod" && console.log('reset');
    this.setLightIndex(4);
    this.setState({
      reset: !this.state.reset,
      cameraChange: 0,
    })
  }

  SliderMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (this.state.slider) {
      let sliderCenter = new THREE.Vector2((window.innerWidth - 120) / 2, window.innerHeight - 45 - 60);
      let mousePos = new THREE.Vector2(e.clientX, e.clientY);
      let radius = 100;
      let sliderRadius = 10;

      let mPos = mousePos.clone().sub(sliderCenter).add(new THREE.Vector2(-sliderRadius + radius, radius - 100 - 60));
      let atan = Math.atan2(mPos.x - radius, mPos.y - radius);
      let deg = THREE.MathUtils.radToDeg(atan);
      if (deg > -140 && deg < 0)
        deg = -140;
      if (deg >= 0 && deg < 140)
        deg = 140;

      this.setLightIndex(deg);
    }
  }

  setLightIndex = (index: number) => {
    this.lightTimeText = moment(0).utc().add(moment.duration(index + 8, 'hours')).format('HH:mm');
    this.setState({ lightIndex: index });
  }

  render = () => {
    let sunIcon = <SunIcon className='imageButton' />;
    let topViewIcon = <TopViewIcon className='imageButton' />;

    if (this.state.sunButtonOver || !this.state.showLightSlider) {
      sunIcon = <SunIconHover className='imageButton' />
    }
    if (this.state.topViewButtonOver || this.state.cameraChange % 2 !== 0) {
      topViewIcon = <TopViewIconHover className='imageButton' />
    }
    if (this.state.ProjectID !== 0 && this.state.demFinished && this.state.subModelFinished && this.state.reportIndexList.length > 0) {
      return (
        <React.Fragment>
          <header className='VisualizeHead'>
            <img src="/img/buildit_platform_logo.svg" className="logo" />
            <div className='projectName'>{this.state.ProjectName}</div>
            <div className='buttons'>
              <div className='imageBox' onClick={() => this.setState({ showInfo: !this.state.showInfo })}>
                <span className="tooltiptext" >프로젝트 정보</span>
                <InfoIcon className='imageButton' />
              </div>
              <div className='imageBox' onClick={() => this.setState({ showLightControl: !this.state.showLightControl })} onMouseOver={() => this.setState({ sunButtonOver: true })} onMouseOut={() => this.setState({ sunButtonOver: false })}>
                <span className="tooltiptext">음영 조절</span>
                {sunIcon}
              </div>
              <div className='imageBox' onClick={() => this.setState({ cameraChange: this.state.cameraChange + 1 })} onMouseOver={() => this.setState({ topViewButtonOver: true })} onMouseOut={() => this.setState({ topViewButtonOver: false })}>
                <span className="tooltiptext">탑 뷰</span>
                {topViewIcon}
              </div>
              <div className='imageBox' onClick={this.ResetAllScene}>
                <span className="tooltiptext">렌더링 초기화</span>
                <SettingBackIcon className='imageButton' />
              </div>
            </div>
          </header>

          <div className='lightControl' hidden={this.state.showLightControl}>
            <div className='text'>계절</div>
            <div className='seasonButtons'>
              <div className={`button ${this.state.season === 'spring' && 'selected' || ''}`} onClick={() => this.setState({ season: 'spring' })} >춘분</div>
              <div className={`button ${this.state.season === 'summer' && 'selected' || ''}`} onClick={() => this.setState({ season: 'summer' })}>하지</div>
              <div className={`button ${this.state.season === 'fall' && 'selected' || ''}`} onClick={() => this.setState({ season: 'fall' })}>추분</div>
              <div className={`button ${this.state.season === 'winter' && 'selected' || ''}`} onClick={() => this.setState({ season: 'winter' })}>동지</div>
            </div>
            <div className='text'>시간<span className='time'>{this.lightTimeText}</span> </div>
            <div><input className='slider' type='range' value={this.state.lightIndex} min='0' max='8' step='0.001' onChange={e => this.setLightIndex(Number(e.target.value))}></input></div>
          </div>

          <div className='mainView'>
            <div className='Visualizer'>
              <div className='checkboxList'>
                {(this.state.reportIndexList).map(index => {
                  if (index.successed)
                    return <div className='reportBox' key={index.reportID}>
                      <Checkbox
                        className={`checkbox ${index.added && 'checked' || ''}`}
                        checked={index.added}
                        onClick={() => this.AddRemoveScene(index.reportID)}
                      />
                      <span className='label'>{index.realReportID}번 결과</span>
                    </div>
                })}
              </div>
              <div className='canvases'>
                {(this.state.index as []).map(i => {
                  return <Scene
                    key={i}
                    ServerStage={this.state.ServerStage}
                    ProjectID={this.state.ProjectID}
                    index={i}
                    dem={this.state.dem}
                    subBuilding={this.state.subBuilding}
                    getPos={this.getScenePos}
                    siteCenter={this.state.siteCenterInScene}
                    siteLine={this.siteLine}
                    cameraChange={this.state.cameraChange}
                    reportInfors={this.reportInformation}
                    lightIndex={this.state.lightIndex}
                    indexSize={this.state.index.length}
                    indexList={this.state.index}
                    finished={() => this.setState({ finished: this.state.finished + 1 })}
                    removeScene={() => this.RemoveScene(i - 1)}
                    setCamera={this.SetCameraAngle}
                    zoomOut={this.ZoomOutScene}
                    syncCameraPosition={this.state.syncCameraPosition}
                    syncCameraTarget={this.state.syncCameraTarget}
                    syncCameraZoom={this.state.syncCameraZoom}
                    reset={this.state.reset}
                    delete={this.state.reportIndexList[i - 1].added}
                    demData={this.demData}
                    centerInLanLat={this.state.siteCenterInLonLat}
                    season={this.state.season}
                  />
                })}
              </div>

              <InformationModal
                reportInformation={this.reportInformation}
                indexArray={this.state.index}
                siteUseDistrict={this.state.siteUseDistrict}
                siteArea={this.state.siteArea.toLocaleString()}
                projectID={this.state.userProjectID}
                address={this.state.address}
                open={this.state.showInfo}
              />
            </div>
          </div>
        </React.Fragment >
      )
    }
    else {
      return (
        <div className='loading'>
          <header>
            <img src="/img/buildit_platform_logo.svg" className="logo" />
            <div className='projectName'>{this.state.ProjectName}</div>
          </header>
          <div className='information'>
            <div className='progress'>
              <img src={'/img/icon/loading.png'} ></img>
            </div>
            <div className='centerWord'>BUILDIT 3D VIEWER</div>
            <div className='loadingMessage'>
              <span>{this.state.loadingMessage}</span><span className='white'>{this.state.loadingProgess}</span>
            </div>
          </div>
        </div>
      )
    }
  }
}