import React, { Component, Fragment } from 'react';
import './css/UserConfirm.scss';
import './css/BuilditUI.scss';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { truncate } from 'fs';
import App from './App';

export default class UserConfirm extends Component {
  componentWillMount = () => {
    App.stage !=="prod" && console.log(App.session);
  }

  render() {
    return (
      <div className="UserConfirm buildit-template">
        <div className="user-confirm-wrapper">
          <Link to="/login">
            <img src="/img/buildit_platform_logo.svg" style={{height: "25px"}} className="logo" />
          </Link>
          <div className="user-confirm-body">
            <div className="header">
              빌드잇<br />
              회원가입
            </div>
            <div className="info">
              아래 이메일로 회원가입 링크를 보냈습니다. <br />
              회원가입을 완료하기 위해 등록한 이메일을 <br />
              확인해주세요.
            </div>
            <div className="email-wrap font font-special font-18px">
            { App.session.email! }
            </div>
            {/* <div className="options">
              <Button className="btn btn-primary btn-small bg-navy" disableRipple={true}>
                회원가입 링크 다시 보내기
              </Button>
              <Button className="btn btn-primary btn-small bg-navy" disableRipple={true}>
                이메일 주소 변경하기
              </Button>
            </div> */}
            <div className="login-wrap">
              {/* <Link to="/login" style={{textDecoration: "none"}}>
                <Button className="btn btn-run brn-large bg-navy" disableRipple={true}>LOGIN</Button>
              </Link> */}
              <Button onClick={e => {
                // @ts-ignore
                this.props.history.push('/login');
              }}
               className="btn btn-run brn-large bg-navy" disableRipple={true}>LOGIN</Button>
            </div>
          </div>
        </div>
        <div className="footer">
          <div>
            <a target="_blank" href="/termofservice?type=개인%20정보%20처리%20방침">개인정보처리방침</a> | <a target="_blank" href="/termofservice?type=서비스%20이용%20약관">이용약관</a>
          </div>
          <div>Copyright © 2019 BUILDIT</div>
        </div>
      </div>
    )
  }
}