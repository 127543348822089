import { CircularProgress } from '@material-ui/core';
import React, { Component } from 'react';


export interface PageLoadingProps {}
export interface PageLoadingState {}

export default class MyPage extends Component<PageLoadingProps, PageLoadingState> {
  render() {
    return (
      <div className="PageLoading" style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress className="circular-progress" style={{ color: "#ccc" }}  />
      </div>
    )
  }
}