import { timeScale } from "../Utils";

// 다세대필드 삭제 차후 다시 살림 ( 20200526 )

const ProjectCardData = [
  { 
    field: "loc_building_land_ratio" , fieldName: "건폐율", normalField: false, unit: "%", showSmall: true,
    format: (data: any) => Number(Number(data).toFixed(2)).toLocaleString()
  },
  { 
    field: "loc_floor_area_ratio" , fieldName: "달성 용적률", normalField: true, unit: "%", showSmall: true,
    format: (data: any) => Number(Number(data).toFixed(2)).toLocaleString()
  },
  { field: "loc_building_stories_min" , fieldName: "최저 층수", normalField: false, unit: "층", showSmall: false,
    format: (data: any) => data
  },
  { field: "loc_building_stories_max" , fieldName: "최고 층수", normalField: false, unit: "층", showSmall: true,
    format: (data: any) => data
  },
  { field: "loc_building_stories_avg" , fieldName: "평균 층수", normalField: false, unit: "층",  showSmall: false,
    format: (data: any) => Number(data).toFixed(2)
  },
  { field: "loc_building_number" , fieldName: "건물 수", normalField: false, unit: "동", showSmall: true,
    format: (data: any) => data
  },
  { field: "loc_total_household" , fieldName: "총 세대수", normalField: true, unit: "세대" , showSmall: true,
    format: (data: any) => Number(data).toLocaleString()
  },
  { field: "daylight_hours_avg" , fieldName: "평균 일조시간", normalField: true, showSmall: false,
    format: (data: any) => timeScale(data)
  },
  { field: "daylight_hours_mode" , fieldName: "최빈 일조시간", normalField: false, showSmall: false,
    format: (data: any) => timeScale(data)
  },
  { field: "daylight_hours_proportion_less_n_hours" , fieldName: "2시간 미만 일조비율", normalField: false, unit: "%", showSmall: false,
    format: (data: any) => (Number(data).toFixed(2)).toLocaleString()
  },
  { field: "view_point_avg" , fieldName: "평균 조망점수", normalField: false, unit: "점", showSmall: false,
    format: (data: any) => Number(Number(data).toFixed(2)).toLocaleString()
  },
  { field: "loc_construction_cost" , fieldName: "추정 공사비", normalField: false, unit: "(천)원", showSmall: true,
    format: (data: any) => Number(Number(Number(data) / 1000).toFixed(0)).toLocaleString()
  },
  {
    smallField: true, field: "parkingNumber", fieldName: "주차 대수", normalField: false, unit: "대", showSmall: true,
    format: (data: any) => Number(data)
  }
]

export default ProjectCardData;