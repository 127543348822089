import React, { Component } from 'react';

import PageViewIcon from '@material-ui/icons/Pageview';
import S3Image from './S3Image';
import { Checkbox } from '@material-ui/core';
import { timeScale } from './Utils';
import './css/ProjectResultCard.scss';
import { ProjectCardHover } from './ProjectResult';
import { BuildingStoriesAvgType, Project } from './model/Project';
import { default as _ } from 'lodash';
import ProjectCardData from './model/ProjectCard';

export interface ProjectResultCardProps {
  result: any;
  project: Project;
  className?: string;
  checked: boolean;
  isCompare: boolean;
  disableCheckedEffect?: boolean;
  hovers: ProjectCardHover;
  setActiveResult?: (n: number) => void;
  setCheckedResult: (n: number) => void;
  setHovers: (fieldName: keyof ProjectCardHover, hover: boolean) => void;
}


export interface ProjectResultCardState {
  result?: any;
}

export class ProjectResultCard extends Component<ProjectResultCardProps, ProjectResultCardState> {
  state: ProjectResultCardState = {
  }


  componentDidUpdate = (pp: Readonly<ProjectResultCardProps>, ps: Readonly<ProjectResultCardState>) => {
    if (!_.isEqual(pp.result, this.props.result)) {
      this.parseResult();
    } 
  }

  componentDidMount = () => {
    this.parseResult();
  }

  parseResult = () => {
    if (this.props.result) {
      this.setState({
        result: {
          ...this.props.result,
          loc_building_stories_avg: this.props.result.loc_building_stories_avg[this.props.project.building_stories_avg_type!],
          daylight_hours_proportion_less_n_hours: this.props.result.daylight_hours_proportion_less_n_hours[1],
        }
      })
    }
  }

  render() {
    return (
      <div className={`ProjectResultCard ${this.props.className} ${this.props.isCompare && "COMPARE" || "NORMAL"}
        ${!this.props.disableCheckedEffect && this.props.checked && "checked" || ""}`}
        onClick={e => { 
          if (this.props.setActiveResult && this.props.isCompare === false) {
            this.props.setActiveResult(this.props.result.real_report_number)
          }
        }}
      >
        <div className="header bg-navy font font-primary">
          {this.props.result.real_report_number}번 결과
          {
            this.props.isCompare === false &&
            <Checkbox
            disableRipple={true}
            className={`checkbox m-l-a ${this.props.checked && "checked" || ""}`} checked={this.props.checked}
              onClick={e => {
                e.stopPropagation();
                this.props.setCheckedResult(this.props.result.real_report_number);
              }} 
            />
          }
        </div>
        <div className="cad-img" 
          onClick={e => { 
            if (this.props.setActiveResult && this.props.isCompare) {
              this.props.setActiveResult(this.props.result.real_report_number)
            }
          }}
        >
          <S3Image s3={{
            Bucket: "teneleven-engine-result",
            Key: this.props.result.base_report_file_path.resImage.split("teneleven-engine-result/")[1],
          }} />
          {
            <PageViewIcon className="go-to-icon" />
          }
        </div>
        <div className="content">
        {
          ProjectCardData.map(d => {
            if (this.props.project.building_type! !== "다세대주택" && d.smallField) {
              return;
            } else if (this.props.project.building_type! === "다세대주택" && !d.showSmall) {
              return;
            } else if (this.props.isCompare) {
              return (
                <div className={`key-value ${this.props.hovers[d.fieldName as keyof ProjectCardHover] && "hover" || ""}`}
                  onMouseEnter={() => this.props.setHovers(d.fieldName as keyof ProjectCardHover, true)}
                  onMouseLeave={() => this.props.setHovers(d.fieldName as keyof ProjectCardHover, false)}
                >
                  <div className="value">
                  {
                    this.state.result && d.format(this.state.result[d.field])
                  } 
                  {
                    d.unit
                  }   
                  </div>
                </div>
              )
            } else if (this.props.isCompare === false && d.normalField) {
              return (
                <div className="key-value">
                {
                  <div className="key">{d.fieldName}</div>
                }
                  <div className="value">
                  {
                    this.state.result && d.format(this.state.result[d.field])
                  } 
                  {
                    d.unit
                  }   
                  </div>
                </div>
              )
            }
          })
        }
        </div>
      </div>
    )
  }

  moveToDetailResult = () => {

  }
}

