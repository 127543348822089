import React, { Component, Fragment } from 'react';
import { Project } from './model/Project';
import { Input, TextField, MenuItem, ClickAwayListener, IconButton, Button } from '@material-ui/core';
import './css/Test.scss';
import './css/Landing.scss';
import BuilditInput from './BuilditInput';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import IncIcon from '@material-ui/icons/Add';
import DecIcon from '@material-ui/icons/Remove';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CheckIcon from '@material-ui/icons/Check';
// @ts-ignore
import { reproject } from 'reproject';
import Clipboard from 'react-clipboard.js';
import { default as RcTooltip } from 'rc-tooltip';

import { number } from 'prop-types';
import { default as _ } from 'lodash';
import { User } from './model/User';
import BuilditTemplate from './BuilditTemplate';
import MapView from './MapView';
import DrawingManager2 from './DrawingManager2';

import Tabs from './Tabs';
import Tooltip from './Tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import Switch from './Switch';
import DownloadButton from './DownloadButton';
import * as turf from '@turf/turf';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import jquery from 'jquery';
const jsts = require('jsts');
const $ = jquery;



interface Test2Props {
  
}
interface Test2State {
  project: Project;
  user: User;
  openTooltip: boolean;
  on: boolean;
  on2: boolean;
  on2Msg?: string | JSX.Element;
  compare: boolean;
}

export default class Test2 extends Component<Test2Props, Test2State> {
  state: Test2State = {
    project: new Project(),
    user: {} as User,
    openTooltip: false,
    on: false,
    on2: false,
    
    compare: false,
  }

  componentDidMount = async () => {
    var doAnimations = function() {
      console.log('asdfasdfsadasdfasd');
      // Calc current offset and get all animatables
      var offset = $('.buildit-template').scrollTop()! + $('.buildit-template').height()!,
          $animatables = $('.animatable');

      console.log($('.buildit-template').scrollTop()!, $('.buildit-template').height()!);
      
      // Unbind scroll handler if we have no animatables
      if ($animatables.length == 0) {
        // $(window).off('scroll', doAnimations);
      }
      
      // Check all animatables and animate them if necessary
      $animatables.each(function(i) {
         var $animatable = $(this);
        if (($animatable.offset()!.top + $animatable.height()! - 20) < offset) {
          $animatable.removeClass('animatable').addClass('animated');
        }
      });
  
    };
    
    // Hook doAnimations on scroll, and trigger a scroll
    $('.buildit-template').on('scroll', doAnimations);
    $('.buildit-template').trigger('scroll');
  }

  unionProjectSite = (callback?: Function) => {
    
  }

  render () {
    console.log('test-props', this.props);
    return (
      
      <BuilditTemplate>
        <div style={{backgroundColor: "white", height: "100%"}} className="TEST">
          <BuilditInput 
            onChange={() => {}}
            value={1}
            min={undefined}
          />
        </div>
      </ BuilditTemplate>
    );
  }

   
  onUpdateProject = (update: Project, callback?: Function) => {
    const newProject = new Project({ 
      ..._.cloneDeep(this.state.project),
      ..._.cloneDeep(update),
    })

    this.setState({ project: newProject }, () => {
      callback && callback();
    })
  }
}


interface RegisterInputProps {}
interface RegisterInputState {}

class RegisterInput extends Component<RegisterInputProps, RegisterInputState> {
  render() {
    return (
      <div className="RegisterInput">
        <BuilditInput 
          type="text"
          endAdornment={<Fragment>
            <CheckIcon className="icon" />
          </Fragment>}
          onChange={e => console.log('a')}
        />
      </div>
    )
  }
}

interface ProjectInputProps {
  className?: string;
  value?: React.ReactText;
  type?: "text" | "number";
  select?: boolean;
  list?: Array<{value: React.ReactText, label: React.ReactText}>
  unitList?: Array<{value: React.ReactText, label: React.ReactText}>
  unit?: string;
  unitValue?: string;
  unitFieldName?: keyof Project;
  fieldName?: keyof Project;
  step?: number;
  title?: string;
  infoAdornment?: React.ReactNode;
  min?: number;
  max?: number;
  length?: number;
  error?: boolean;
  errorMsg?: string;
  multiline?: boolean;
  onChange: (update: Project, callback?: Function) => void;
  onChangeUnit?: (val: any) => void;
}


interface ProjectInputState {
  focus: boolean;
  hover: boolean;
}
class ProjectInput extends Component<ProjectInputProps, ProjectInputState> {
  state: ProjectInputState = {
    focus: false,
    hover: false,
  }

  componentWillMount = () => {
    if (this.props.type === "number" && this.props.value === undefined) {
      this.props.onChange({ [this.props.fieldName as string]: 0 })
    }
  }
  render () {
    return (
      <div className="ProjectInput" 
        onMouseEnter={e => this.setState({hover: true})}
        onMouseLeave={e => this.setState({hover: false})}
      >
        <div className="header">
          <div className={`title ${this.props.error && "error"}`}>{this.props.title}</div>
          { 
            this.props.infoAdornment && 
            <div className="info-adornment">
              <IconButton className="icon-btn">
                <InfoIcon className="icon" />
              </IconButton>
            </div> 
          }
          {
            this.props.unitFieldName && 
            <div className="unit-select">
              <BuilditInput
                className="unit" 
                value={this.props.unitValue}
                onChange={this.onUnitChange}
                select
                list={this.props.unitList}
              />
            </div>
          }
        </div>
        <div className="content">
          <BuilditInput
            multiline={this.props.multiline}
            min={this.props.min}
            max={this.props.max}
            length={this.props.length}
            value={this.props.value}
            type={this.props.type}
            onChange={this.onChange}
            step={this.props.step === undefined ? 1 : this.props.step}
            select={this.props.select}
            list={this.props.list}
            error={this.props.error}
            errorMsg={this.props.errorMsg}
            onFocus={() => this.setState({ focus: true })}
            onBlur={() => this.setState({ focus: false })}
            startAdornment={
              this.props.type === "number" &&
              <Fragment>
                <IconButton className={`icon-btn dec-btn ${(!this.state.focus && !this.state.hover) && "visibility-hidden" || ""}`} onClick={e => this.handleValue("DEC")}><DecIcon className="icon" /></IconButton>
                <IconButton className={`icon-btn inc-btn ${(!this.state.focus && !this.state.hover) && "visibility-hidden" || ""}`} onClick={e => this.handleValue("INC")}><IncIcon className="icon" /></IconButton>
              </Fragment> || undefined
            }
            endAdornment={
              this.props.type === "number" &&
              this.props.unit &&
              <Fragment>
                <div className="unit" style={{padding: "10px 0px"}}>{this.props.unit}</div>
              </Fragment> || undefined
            }
          />
        </div>
      </div>
    );
  }
  onChange = (value: React.ReactText) => {
    this.props.onChange({
      [this.props.fieldName as string]: value
    })
  }
  onUnitChange = (value: React.ReactText) => {
    this.props.onChange({
      [this.props.unitFieldName as string]: value
    })
  }
  handleValue = (type: "INC" | "DEC") => {
    const step = this.props.step === undefined ? 1 : this.props.step;
    const curVal = this.props.value;
    const nextVal = Math.round(((curVal as number) + (type === "INC" ? step : -step)) * 100) / 100;
    const min = this.props.min === undefined ? 0 : this.props.min;
    const max = this.props.max === undefined ? Number.MAX_SAFE_INTEGER : this.props.max;
    if (min <= nextVal && nextVal <= max) {
      this.onChange(nextVal);
    } else if (min > nextVal) {
      this.onChange(min);
    } else if (max < nextVal) {
      this.onChange(max);
    }
  }
}

