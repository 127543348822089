import React, { Component } from 'react';
import { ProjectFormPropsBase } from './ProjectInput';
import CloseIcon from '@material-ui/icons/Close'
import SubIcon from '@material-ui/icons/RemoveCircleOutline';
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Checkbox, IconButton, Button, CircularProgress } from '@material-ui/core';
import './css/ProjectFormMapRoad.scss';

import { default as _ } from 'lodash';
import BuilditInput from './BuilditInput';
import ProjectFieldInput from './ProjectFieldInput';
import { ProjectFormMapProps } from './ProjectFormMap';
import { ProjectSitePolygon } from './Shape';
import DrawingManager2 from './DrawingManager2';
import { ShapeType } from './model/Project';
import Tooltip from './Tooltip';
import SwapIcon from '@material-ui/icons/SwapHoriz';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Modal, { ModalOptions } from './Modal';
import BuildingLaws, {Laws} from './BuildingLaws';

export interface ProjectFormMapRoadProps extends ProjectFormPropsBase {
  className?: string;
  roadValue?: number[][];
  roadActive?: boolean[][];
  openRoadConfig: boolean;
  setOpenRoadConfig: (open: boolean) => void;
  drawRoad: (polygon: ProjectSitePolygon, siteIndex: number, roadIndex: number, distance: number) => void;
  clearRoad: (polygon: ProjectSitePolygon, roadIndex: number) => void;
  drawShape: (type: ShapeType) => void;
  onUpdateShape: (type?: ShapeType) => void;
  projectSite: ProjectSitePolygon[];
}
export interface ProjectFormMapRoadState {
  selectedRoadValue: number;
  selectedRoads: boolean[];
  selectedRoad: Array<{polygonIndex: number, roadIndex: number}>;
  clearRoadInput: boolean[][];
  showExplanation: boolean;
  modalOptions?: ModalOptions;
  test: boolean;
}

export default class ProjectFormMapRoad extends Component<ProjectFormMapRoadProps, ProjectFormMapRoadState> {
  state: ProjectFormMapRoadState = { 
    selectedRoadValue: 0,
    selectedRoads: [],
    selectedRoad: [],
    clearRoadInput: [],
    showExplanation: false,
    test: false,
  }

  setInit = () => {
    if (this.props.roadValue && this.props.roadValue.length > 0) {
      this.setState({
        selectedRoads: this.props.roadValue.map(r => false),
        selectedRoad: [],
        clearRoadInput: this.props.roadValue.map(r => r.map(rr => false))
      })
    }
  }

  componentWillMount = () => {
    this.setInit();
  }

  componentWillReceiveProps = (pp: Readonly<ProjectFormMapRoadProps>) => {
    // 인접대지경계선 -> road로 바뀜 (인접대지경계선의 설정이 바뀜)
    if (this.props.currentProject.auto_road !== pp.currentProject.auto_road) {
      this.setInit();
    }
  }

  componentWillUnmount = () => {
  }

  componentDidUpdate = () => {
    
  }

  render() {
    let tabIndex = 1;
    
      return (
        <div className={`ProjectFormMapRoad ${this.props.className || ""}`}>
          <div className="header">
            <span className="title">인접도로 설정</span>
            <InfoOutlinedIcon className="icon" onClick={e => this.showExplanation()}/>
            <IconButton className="close-btn" onClick={() => this.props.setOpenRoadConfig(false) }><CloseIcon className="icon close-icon" /></IconButton>
          </div>
          <div className="body">
          {
            this.props.currentProject.auto_road
            &&
            <div className="info-wrap">
              <div className="info">
                사업영역 설정의 <span style={{ fontWeight: "bold" }}>필지 모드</span>에서는 <span style={{fontWeight: "bold"}}>인접도로 / 공지영역</span>의<br />
                설정없이 <span style={{color: "#48DDF2"}}>인접대지경계선</span>을 설정 할 수 있습니다.<br />
                적용 하시겠습니까?
              </div>
              <div className="warning">
                * 인접대지경계선을 적용시, 공지영역의 설정이 제한됩니다.
                 <br />
                (설정된 공지영역은 삭제 됩니다.)
              </div>
              <div className="btns">
                <Button onClick={() => this.props.drawShape('ROAD')} className="bg-navy btn-small road btn btn-primary" >
                  직접 인접도로 설정
                </Button>
                <Button onClick={() => {
                  this.props.setOpenRoadConfig(false);
                }} className="bg-navy btn-small auto btn btn-run" >적용하기</Button>
              </div>
            </div>
            ||
            (
              this.props.currentProject.road_value!.length > 0 && this.state.test === false &&
              <div className="wrapper">
                {
                  this.props.currentProject.auto_road === false &&
                  <div className="display-flex">
                    {
                      this.props.currentProject.project_site_type === "SELECT" &&
                      <Button className="bg-navy btn btn-primary btn-small" disableRipple={true} onClick={() => this.props.drawShape("BOUNDARY_SITE")}>
                        자동으로 인접대지경계선 설정
                      </Button>
                    }
                    <Button className="bg-navy btn btn-run btn-small"
                      onClick={() => {
                        this.props.setOpenRoadConfig(false);
                      }}
                    >
                      인접도로 설정 완료
                    </Button>
                  </div>
                }
                {
                  this.props.currentProject.road_value!.map((roads, i) => {
                    return (
                      <div className="roads-wrap" key={`roads-wrap-${i}`}>
                        <div className="header">
                          <Checkbox className={`checkbox ${this.state.selectedRoads[i] && "checked" || ""}`}
                            key={`roads-${i}`}
                            checked={this.state.selectedRoads[i]}
                            onClick={() => {
                              this.setSelectedRoad(i);
                            }}
                          />
                          <Button disableRipple={true} className="show-btn">
                            <div className="title">{Number(i + 1).toString().padStart(2, '0')} 대지영역</div>
                            <div className="font font-secondary font-12px">마우스 휠, 키보드 방향키로 조절가능</div>
                            {/* <UpArrowIcon className="icon" /> */}
                          </Button>
                        </div>
                        {
                          <div className="body">
                          {                  
                            roads.map((road, j) => {
                              return <RoadItem key={`road-${i}-${j}`} 
                                tabIndex={tabIndex++}
                                setSelectedRoad={this.setSelectedRoad}
                                siteIndex={i} roadIndex={j} 
                                roadValue={this.props.roadValue}
                                checked={this.state.selectedRoad.findIndex(e => e.polygonIndex === i && e.roadIndex === j) > -1}
                                onUpdateProject={this.props.onUpdateProject}
                                currentProject={this.props.currentProject}
                                projectSite={this.props.projectSite}
                                roadActive={this.props.roadActive}
                                drawRoad={this.props.drawRoad}
                                clearRoad={this.props.clearRoad}
                                clearInput={this.state.clearRoadInput[i][j]}
                                {...this.props}
                              />
                            })
                          }
                          </div>  
                        }
                      </div>
                    )
                  })
                }
              </div>
               ||
              <div className="road-loading">
                <div className="circular-progress-wrap">
                  <CircularProgress className="circular-progress" />
                </div>
                <p className="t-a-c" style={{ marginBottom: "0px" }}>{this.props.currentProject.auto_road && "인접대지경계선을 설정중입니다." || "인접도로를 설정중입니다."}</p>
                <p className="t-a-c" style={{ marginTop: "0px" }}>잠시만 기다려 주세요.</p>
              </div>
            )     
          }        
          </div>
          {
            (this.props.currentProject.auto_road === false && this.props.currentProject.road_value!.length > 0) 
            &&
            <div className="footer">
              <div className="header">
                <div className="main-title">동일하게 적용하기</div>
                <div className="sub-title">동일하게 적용할 도로를 선택하신 후, 아래에서 적용해주세요</div>
              </div>
              <div className="body">
                <ProjectFieldInput className="select-road-project-input" placeholder="입력" 
                  unit="m"
                  type="number"
                  fieldValue={this.state.selectedRoadValue}
                  onChange={v => this.onChangeRoadValue(v as number)}
                  onUpdateProject={this.props.onUpdateProject}
                  currentProject={this.props.currentProject}
                  disabledAutoSetting={true}
                  min={0}
                  max={200}
                  error={this.state.selectedRoadValue > 200}
                  errorMsg={"유효 범위는 0 ~ 200m 입니다"}
                />
                <Button className={`bg-navy btn btn-primary btn-small ${this.state.selectedRoad.length < 2 && "disabled"}`}
                  disabled={this.state.selectedRoad.length < 2}
                  onClick={() => this.setSelectedRoadsValue()}
                >동일하게 적용하기</Button>
              </div>
            </div>
          }
          {
            this.state.showExplanation && 
            <Modal
              open={this.state.showExplanation}
              type={this.state.modalOptions && this.state.modalOptions.type && this.state.modalOptions.type || "SIMPLE"}
              positive={this.state.modalOptions && this.state.modalOptions.positive}
              negative={this.state.modalOptions && this.state.modalOptions.negative}
              title={this.state.modalOptions && this.state.modalOptions.title}
              negativeTitle={this.state.modalOptions && this.state.modalOptions.negativeTitle}
              positiveTitle={this.state.modalOptions && this.state.modalOptions.positiveTitle}
              content={this.state.modalOptions && this.state.modalOptions.content}
              color={this.state.modalOptions && this.state.modalOptions.color || "DARK"}
            />
          }
        </div>
      ) 
    

  }

  onChangeRoadValue = (width: number) => {
    this.setState({ selectedRoadValue: width })
  }

  setSelectedRoadsValue = () => {
    this.state.selectedRoad.map(r => {
      this.props.roadValue![r.polygonIndex][r.roadIndex] = this.state.selectedRoadValue;
    })
    this.props.onUpdateProject({ 
      road_value: this.props.roadValue
      }, () => this.setState({
       clearRoadInput: _.cloneDeep(this.props.roadActive)!
      }, () => this.setState({
        clearRoadInput: this.props.roadActive!.map(r => r.map(rr => false ))
      }, () => {
        this.setState({ test: true}, () => setTimeout(() => this.setState({ test: false }), 500))
      })));
  }

  setSelectedRoad = (polygonIndex: number, roadIndex?: number) => {
    const selectedRoads = _.cloneDeep(this.state.selectedRoads);
    const selectedRoad = _.cloneDeep(this.state.selectedRoad);
    const findIndex = this.state.selectedRoad.findIndex(e => e.polygonIndex === polygonIndex && e.roadIndex === roadIndex);

    if (roadIndex === undefined) {
      let newSelectedRoad: Array<{polygonIndex: number, roadIndex: number}>= [];
      if (selectedRoads[polygonIndex] === true) {        
        selectedRoad.map(r => {
          if (r.polygonIndex !== polygonIndex) {
            newSelectedRoad.push(r);
          }
        })
      } else {
        if (this.props.roadValue) {
          newSelectedRoad = selectedRoad;
          this.props.roadValue[polygonIndex].map((r, i) => {
            newSelectedRoad.push({ polygonIndex: polygonIndex, roadIndex: i })
          })
        }
      }

      selectedRoads[polygonIndex] = !selectedRoads[polygonIndex];
      this.props.roadActive![polygonIndex].map((r, i) =>  this.props.roadActive![polygonIndex][i] = false );
      newSelectedRoad.map((r: {polygonIndex: number, roadIndex: number}) => this.props.roadActive![r.polygonIndex][r.roadIndex] = true )
      this.setState({
        selectedRoad: newSelectedRoad,
        selectedRoads: selectedRoads,
      });

    } else {
      if (findIndex > -1) {
        selectedRoad.splice(findIndex, 1);
        selectedRoads[polygonIndex] = false;
      } else {
        selectedRoad.push({ polygonIndex: polygonIndex, roadIndex: roadIndex });
  
        const selectedLength = selectedRoad.filter(e => e.polygonIndex === polygonIndex).length;
        if (this.props.roadValue) {
          if (selectedLength === this.props.roadValue[polygonIndex].length) {
            selectedRoads[polygonIndex] = true;
          }
        }
      }

      this.props.roadActive![polygonIndex].map((r, i) => this.props.roadActive![polygonIndex][i] = false);
      selectedRoad.map((r: {polygonIndex: number, roadIndex: number}) => this.props.roadActive![polygonIndex][r.roadIndex] = true )
      this.setState({
        selectedRoad: selectedRoad,
        selectedRoads: selectedRoads,
      });
    }
  }

  setModal = (open: boolean, options?: ModalOptions) => {
    this.setState({
      showExplanation: open,
      modalOptions: options
    });
  }

  showExplanation = () => {
    this.setModal(true, {
      title: "건축법규",
      type: "NORMAL",
      positive: () => {
        this.setModal(false);
      },
      negative: "hidden",
      content: (
        <div>
          <div className="info-modal buildit-template">
            <img className="img" src="/img/law_picture/road.png"/>
            <div className="description">
              <span className="first-title font font-14px">공지영역</span>
              <span>인접한 필지 중 도로, 하천, 공원 등 건축물을 지을 수 없는 영역을 나타냅니다.</span>
              <span className="second-title font font-14px font-special">인접대지경계선</span>
              <span>건축법시행령 제86조 일조 등의 확보를 위한 건축물의 높이 제한 항목의 기준이되는 인접대지경계선입니다. 공동주택의 경우 인접한 대지와 사업영역 사이에공지영역이 있으면 그 반대편 대지경계선과의 중심선을 인접대지경계선으로 적용합니다.</span>
            </div>
          </div>
        </div>
      )
    })
  }
}

interface RoadItemProps extends ProjectFormPropsBase {
  tabIndex: number;
  siteIndex: number,
  roadIndex: number,
  roadValue?: number[][],
  roadActive?: boolean[][],
  checked: boolean;
  projectSite: ProjectSitePolygon[];
  clearInput: boolean;
  setSelectedRoad: (polygonIndex: number, roadIndex: number) => void,
  drawRoad: (polygon: ProjectSitePolygon, siteIndex: number, roadIndex: number, distance: number) => void;
  clearRoad: (polygon: ProjectSitePolygon, roadIndex: number) => void;

}
interface RoadItemState {
  forceBlur: boolean;
}

class RoadItem extends Component<RoadItemProps, RoadItemState> {

  state: RoadItemState = {
    forceBlur: false,
  }

  componentWillMount = () => {
    this.onChange2 = _.debounce(this.onChange2, 400);
  }

  componentDidUpdate = () => {
    this.redraw();
    if (this.props.clearInput) {
      this.setState({
        forceBlur: true
      }, () => {
        this.setState({ forceBlur: false })
      })
    }
  }

  redraw = () => {
    if (this.props.roadValue !== undefined) {
      this.props.clearRoad(this.props.projectSite[this.props.siteIndex], this.props.roadIndex);
      this.props.drawRoad(
        this.props.projectSite[this.props.siteIndex],
        this.props.siteIndex, this.props.roadIndex,
        this.props.roadValue[this.props.siteIndex][this.props.roadIndex]
      )
    }
  }

  shouldComponentUpdate = (np: RoadItemProps, ns: RoadItemState) => {
    return !_.isEqual(this.props.currentProject.road_value![this.props.siteIndex][this.props.roadIndex], np.currentProject.road_value![this.props.siteIndex][this.props.roadIndex]) || !_.isEqual(this.props.checked, np.checked);
  }

  render() {
    return (
      <div className="RoadItem" onMouseEnter={() => this.showActiveRoad(true)} onMouseLeave={() => this.showActiveRoad(false)}>
        <Checkbox className={`checkbox ${this.props.checked && "checked" || ""}`}
          checked={this.props.checked}
          onClick={() => {
            this.props.setSelectedRoad(this.props.siteIndex, this.props.roadIndex)
          }}
        />
        <span className="title">{Number(this.props.roadIndex + 1)}번 도로</span>
        <ProjectFieldInput {...this.props} className="road-project-input" placeholder="입력" 
          disabledAutoSetting={true}
          autoFocus={this.props.siteIndex === 0 && this.props.roadIndex === 0}
          forceBlur={this.state.forceBlur}
          refName={`road-input-${this.props.siteIndex}-${this.props.roadIndex}`}
          tabIndex={this.props.tabIndex}
          onFocus={() => { 
            this.showActiveRoad(true);
          }}
          onBlur={() => { 
            this.showActiveRoad(false);
          }}
          min={0}
          max={200}
          unit="m"
          type="number"
          fieldValue={undefined}
          defaultValue={this.props.currentProject.road_value![this.props.siteIndex]![this.props.roadIndex]}
          onChange={v => this.roadChange(v as number)}
          error={this.props.currentProject.road_value![this.props.siteIndex]![this.props.roadIndex] > 200}
          errorMsg={"유효 범위는 0 ~ 200m 입니다"} />
      </div>
    )
  }
  showActiveRoad = (show: boolean) => {
    if (show) {
      
      if (this.props.roadActive !== undefined) {
        this.props.roadActive[this.props.siteIndex][this.props.roadIndex] = true;
      }
    } else {
      
      if (this.props.roadActive !== undefined && this.props.checked === false) {
        this.props.roadActive[this.props.siteIndex][this.props.roadIndex] = false;
      }
    }
    this.redraw();
  }

  onChange = (width: number) => {    
    if (this.props.roadValue) {
      this.props.roadValue[this.props.siteIndex][this.props.roadIndex] = width;
      this.props.onUpdateProject({ road_value: this.props.roadValue })
    }
  }
  roadChange = (width: number) => {
    if (this.props.roadValue) {
      this.props.roadValue[this.props.siteIndex][this.props.roadIndex] = width;
      this.redraw();
    }
    this.onChange2(width);
  }
  onChange2 = (width: number) => {
    this.props.onUpdateProject({ road_value: this.props.roadValue })
  }
}