import React, { Fragment, Component } from 'react';
import BuilditTemplate from './BuilditTemplate';
import SessionComponent from './SessionComponent';

import './css/Home.scss';
import App from './App';
import { Project } from './model/Project';
import ProjectTypeTag from './ProjectTypeTag';
import AWSModule from './AWSModule';

import ArrowForward from '@material-ui/icons/ArrowForward';
import { Link } from 'react-router-dom';
import Tabs from './Tabs';
import { Button } from '@material-ui/core';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import S3Image from './S3Image';
import * as URI from "uri-js";
import MapView from './MapView';
import { default as _ } from 'lodash';
import DiscountTag from './DiscountTag';
import ProjectIcon from './ProjectIcon';

import Axios from 'axios';
import TopAlert from './TopAlert';
import ProjectTypeModal from './ProjectTypeModal';
import Tooltip from './Tooltip';
import ServicePlanTag from './ServicePlanTag';

export interface HomeProps {

}

export interface HomeState {
  finishedProjects?: Array<Project>,
  finishedProjectsLength: number,
  finishedTabActive: "AI" | "DESIGNER",
  onUpdateNotification: boolean;
  point: number,
}
const UPDATE_NOTIFICATION_KEY = "update-notification";

export default class Home extends SessionComponent<HomeProps, HomeState> {

  
  rr =  window.localStorage.getItem(UPDATE_NOTIFICATION_KEY);

  state: HomeState = {
    finishedProjectsLength: 0,
    finishedTabActive: App.stage === "prod" ? "DESIGNER" : "AI",
    onUpdateNotification: this.rr === null ? false : JSON.parse(this.rr).on,
    point: 0,
  }

  componentWillMount = async() => {
    this.getFinishedProjects();
    // 초기 세팅
    if (window.localStorage.getItem(UPDATE_NOTIFICATION_KEY) === null){
      window.localStorage.setItem(UPDATE_NOTIFICATION_KEY, JSON.stringify({
        version: "1.1.0",
        on: true,
      }))
    }
    await App.updateSession();
  }

  // timer = setInterval(async () => {    
  //   await App.updateSession();
  // }, 30000)

  // componentWillUnmount() {
  //   clearInterval(this.timer);
  // }
  
  setFinishedTabActive = (type: "AI" | "DESIGNER") => {
    if (type !== this.state.finishedTabActive) {
      this.setState({
        finishedTabActive: type
      }, () => {
        this.getFinishedProjects();
      })
    }
  }

  getFinishedProjects = async () => {
    

    if (this.state.finishedTabActive === "AI") {
      const r = await App.search({
        table: 'platform-buildit-project',
        query: `discarded:false AND deleted:false AND status:FINISHED AND (project_type:AI OR project_type:CAL) AND (NOT hidden:true)`,
        from: 0,
        size: 4,
        sort: [ { finished_at: 'desc', created_at: 'desc' } ],
      });

      const rr = r.data;
      if (rr.hits && rr.hits.hits) {
        const projects = rr.hits.hits.map((p: any) => p._source);
        if (rr.hits.total > 0 && rr.hits.total < 4) {
          for (let i = 0; i < 4 - rr.hits.total; i++) {
            projects.push({});
          }
        }
        this.setState({
          finishedProjects: projects,
          finishedProjectsLength: rr.hits.total
        })
      } else {
        console.log('err');
      }
    } else {
      const r = await App.search({
        table: 'platform-buildit-project',
        query: `discarded:false AND deleted:false AND status:FINISHED AND project_type: DESIGNER AND (NOT hidden:true)`,
        from: 0, size: 4,
        sort: [ { finished_at: 'desc' } ]
      });
      
      const rr = r.data;
  
      if (rr.hits && rr.hits.hits) {
        const projects = rr.hits.hits.map((p: any) => p._source);
        
        if (rr.hits.total > 0 && rr.hits.total < 4) {
          for (let i = 0; i < 4 - rr.hits.total; i++) {
            projects.push({});
          }
        }

        if (rr.hits.total > 0 && rr.hits.total < 4) {
          for (let i = 0; i < 4 - rr.hits.total; i++) {
            (projects as Array<any>).push({});
          }
        }

        if (projects && projects.length > 0) {
          projects.sort((a: Project, b: Project) => {
            // @ts-ignore
            return new Date(b.finished_at) - new Date(a.finished_at);
          })
        }
        
        this.setState({
          finishedProjects: projects && projects || [],
          finishedProjectsLength: rr.hits.total,
        })
      } else {
        console.log('err');
      }
    }
  }
  render() {
    return (
      <BuilditTemplate footer={true} {...this.props} >
         
        <div className="Home">
          {/* {
            this.state.onUpdateNotification &&
              <TopAlert from="Home" onCloseBtn={true} onCloseCallback={() => {
                let ls = JSON.parse(window.localStorage.getItem(UPDATE_NOTIFICATION_KEY)!);
                ls.on = false;
                window.localStorage.setItem(UPDATE_NOTIFICATION_KEY, JSON.stringify(ls))
                this.setState({ onUpdateNotification: ls.on })
              }} 
            />
          } */}

          <div className="home-wrapper">
            <div className="header">
              <div className="wrapper">
                <div className="title">HOME</div>
                {
                  App.session.paying_type &&
                  <div className="right-info-wrapper">
                    <Tooltip msg={App.session.paying_type === "INFINITE" ? '오늘 사용 가능 포인트 (일일 최대 100만 포인트 사용 가능)': '사용 가능 포인트'} arrowOn={false}>
                      <div className="point-info">
                        <div className="name">
                          <img className="m-r-sm buildit-point-logo" src="/img/icon_Bpoint.svg" />
                        </div>
                        <div className="point">
                          {App.session.paying_type === "INFINITE" ? Number(App.session.point).toLocaleString() : Number(App.session.point + App.session.service_point).toLocaleString()}P
                        </div>
                      </div>
                      <ServicePlanTag type={App.session.paying_type} style={{ marginLeft: "20px" }} />
                    </Tooltip>
                  </div>
                }
              </div>
            </div>
            <div className="body">
              <div className="main-panel">
                <div className="finished-projects ">
                  <div className="header">
                    <Link className="router-link" to={`/project/list?type=FINISHED&page=1&q=${encodeURIComponent(JSON.stringify({query: [{ field: "status", content: "FINISHED"}]}))}`}>
                      완료된 프로젝트
                      <ArrowForward className="icon m-l-sm" />
                    </Link>
                    <Tabs className="bg-navy tabs tabs-small m-l-a ">
                      <Button disableRipple={true} onClick={e => this.setFinishedTabActive("AI")} className={`bg-navy tab tab-primary tab-small ${this.state.finishedTabActive === "AI" && "active" || ""}`}>AI</Button>
                      <Button disableRipple={true} onClick={e => this.setFinishedTabActive("DESIGNER")} className={`bg-navy tab tab-primary tab-small ${this.state.finishedTabActive === "DESIGNER" && "active" || ""}`}>DESIGNER</Button>
                    </Tabs>
                  </div>
                  <div className="body">
                  {
                    this.state.finishedProjects === undefined &&
                    <div style={{height: "410px"}} />
                    ||
                    (this.state.finishedProjects!.length === 0 &&
                    <Fragment>
                      <div className="sample-project">
                        <HomeSampleProjectCard />
                      </div>
                      <div className="empty-result">
                        {/* <div className="empty-img">
                          <img src="/img/home_empty.png" />
                        </div> */}
                        <div className="content">
                          <div className="main-title">Welcome to BUILDIT!</div>
                          <div className="sub-title">
                            <div className="msgs">
                              <div className="msg">왼쪽의 샘플 프로젝트 결과를 체험해 보실 수 있습니다.</div>
                              <div className="msg"><span className="font font-special">+프로젝트 시작</span>을 눌러 간단한 설정으로 빌드잇 결과를 받아보세요!</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                      ||
                    this.state.finishedProjects!.slice(0, 4).map((p: Project, i: number) => {   
                      if (p.user_project_id) {
                        return ( <HomeProjectCard key={`${p.project_type}-home-card-${i}`} project_id={p.project_id} /> )
                      } else if (this.state.finishedProjectsLength === i) {
                        return ( <HomeProjectCard key={`${p.project_type}-home-card-${i}`} firstEmpty={true} /> )
                      } else {
                        return ( <HomeProjectCard key={`${p.project_type}-home-card-${i}`} empty={true} /> )
                      }
                    }))
                  }
                  </div>
                </div>
              </div>
              <div className="sub-panel">
                <ProjectListSummary
                  type="FAVORITE"
                  title="즐겨찾기 프로젝트"
                  query={`email.keyword:${App.session.email} AND deleted:false AND discarded:false AND favorite:true AND (NOT hidden:true)`}
                  link={`/project/list?type=FAVORITE&page=1&q=${encodeURIComponent(JSON.stringify({query: [{ field: "favorite", content: true}]}))}`}
                />
                <ProjectListSummary 
                  type="LATEST"
                  className="m-l-a"
                  title="최근 생성 프로젝트"
                  query={`email.keyword:${App.session.email} AND deleted:false AND discarded:false AND (NOT hidden:true)`}
                  link="/project/list"
                />
              </div>
            </div>
          </div>
        </div>
      </BuilditTemplate>
    )
  }
}

export class HomeSampleProjectCard extends Component {
  render() {
    return (
      <Link to={`/sample?pid=1`} className="router-link">
        <div className={`HomeProjectCard`} style={{marginRight: "20px"}}>
          <div className="header">
            <div className="user-project-id">
              0
            </div>
            <div className="project-type-tag">
              <div style={{ 
                  borderRadius: "20px",
                  color: "#ccc",
                  border: "1px solid #ccc",
                  fontSize: "10px",
                  width: "60px",
                  display: "flex",
                  height: "18px",
                  alignItems: "center",
                  justifyContent: "center",
              }}>
                sample
              </div>
            </div>
          </div>
          <div className="view-panel">
            <img src="https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/sample_report/sample1/7/resView.png" style={{ width: "100%" }}/>
          </div>
          <div className="body">
            <div title={`불광 5구역 프로젝트`} className="project-name"><span>{`불광 5구역 프로젝트`}</span></div>
            <div className="project-value">
              달성 용적률
              <div className="floor-area-ratio">
                220.36%
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}


export interface HomeProjectCardProps {
  firstEmpty?: boolean;
  empty?: boolean;
  project_id?: number;
  className?: string;
}
export interface HomeProjectCardState {
  result: any;
  existConfigProject: boolean;
  project?: Project;
}

export class HomeProjectCard extends Component<HomeProjectCardProps, HomeProjectCardState> {
  state: HomeProjectCardState = {
    result: undefined,
    existConfigProject: false,
  }
  componentDidUpdate = (pp: HomeProjectCardProps) => {
    
  }
  componentWillMount = async () => {
    
    const ddb = await new AWSModule('DDB').connect();
    if (this.props.project_id !== undefined) {
      const proj = await ddb.Ddb!.get({
        ConsistentRead: true,
        TableName: 'platform-buildit-project',
        Key: {
          stage: App.stage,
          project_id: this.props.project_id
        }
      }).promise();
      this.setState({
        project: proj.Item
      })

      const r = await App.search({
        table: "engine-buildit-result",
        query: `base_project_id:${this.props.project_id}`,
        sort: [ { loc_floor_area_ratio: 'desc' } ],
      });

      const rr = r.data;

      if (rr.hits && rr.hits.hits && rr.hits.hits.length > 0) {
        const projectId = rr.hits.hits[0]._source.base_project_id;
        const reportId = rr.hits.hits[0]._source.base_report_id;
        const r = await ddb.Ddb!.get({
          TableName: "engine-buildit-result",
          ConsistentRead: true,
          Key: {
            base_uuid: `${App.stage}_${projectId}_${reportId}`
          }
        }).promise();

        if (r.Item) {
          this.setState({ result: r.Item });
        }
      }
    }


    if (this.props.firstEmpty) {
      const cr = await App.search({
        table: "platform-buildit-project",
        query: `discarded:false AND deleted:false AND status:CONFIG AND (NOT hidden:true)`,
        from: 0, size: 1,
      });
      
      const crr = cr.data;
      if (crr.hits && crr.hits.hits && crr.hits.hits.length > 0) {
        this.setState({ existConfigProject: true });
      }
    }
  }

  render() {
    if (this.props.firstEmpty) {
      return (
        <div className={`HomeProjectCard first-empty ${this.props.className || ""}`}>
        {
            this.state.existConfigProject &&
          <div className="content" >
            <div className="title">설정중인 프로젝트를 완료해주세요!</div>
            <Link to={`/project/list?type=CONFIG&page=1&q=${encodeURIComponent(JSON.stringify({query: [{ field: "status", content: "CONFIG"}]}))}`} className="text-deco-none" >
            <Button disableRipple={false} className="bg-navy btn btn-primary btn-large btn-moveList">설정중 목록가기</Button>
            </Link>
          </div>
          ||
          <div className="content">
            <div className="title">새로운 프로젝트를 시작하세요.</div>
            <Button disableRipple={false} className="bg-navy btn btn-run btn-large">+ 새 프로젝트 시작</Button>
          </div>
        }
        </div>
      )
    } else if (this.props.empty) {
      return (
        <div className={`HomeProjectCard empty ${this.props.className && this.props.className || ""}`}>
          <div className="msg">프로젝트를 완료해주세요.</div>
        </div>
      )
    } else {
      return (
        <Link to={`/project/result?pid=${this.state.project && this.state.project.user_project_id}`} className="router-link">
          <div className={`HomeProjectCard ${this.props.className && this.props.className || ""}`}>
            <div className="header">
              <div className="user-project-id">
                { this.state.project && this.state.project.user_project_id }
              </div>
              <div className="project-type-tag">
                { this.state.project && <ProjectTypeTag background="DARK" type={this.state.project.project_type} /> }
              </div>
              <div className="statusField">
                { this.state.project && <ProjectIcon status={this.state.project.status} />}
              </div>
            </div>
            <div className="view-panel">
            {
              this.state.project &&
              this.state.project.status === "FINISHED" &&
              (this.state.project.project_type === "AI" || this.state.project.project_type === "CAL") &&
              <div className="img">
              {
                this.state.result && this.state.result.base_report_file_path.resImage &&
                <S3Image
                  s3={{
                    Bucket: "teneleven-engine-result",
                    Key: this.state.result.base_report_file_path.resImage.split("teneleven-engine-result/")[1]
                  }}
                /> 
              }
              </div> 
              ||
              this.state.project && this.state.project.project_type === "DESIGNER" &&
              <div >
                <MapView project={this.state.project} height={285} 
                  size="SMALL"
                  viewOptions={{
                    projectSite: true,
                    road: true,
                    vacancyInside: true,
                    vacancyOutside: true,
                    skyline: true,
                    boundarySite: true,
                  }}
                  interaction={false}
                />
              </div>
            }
            </div>
            <div className="body">
              <div title={this.state.project && this.state.project.project_name} className="project-name">
                {
                  this.state.project &&
                  this.state.project.discountable && <DiscountTag className="m-r-sm"/>
                }
                <span className="name">{this.state.project && this.state.project.project_name}</span>
              </div>
              {
                this.state.project && (this.state.project.project_type === "AI" || this.state.project.project_type === "CAL") &&
                <div className="project-value">
                  달성 용적률
                  <div className="floor-area-ratio">
                    {
                      this.state.result !== undefined ?
                      Number(this.state.result.loc_floor_area_ratio).toFixed(2) : 0
                    }%
                  </div>
                </div>
              }
            </div>
          </div>
        </Link>
      )
   }
  }
}

export interface ProjectListSummaryProps {
  className?: string,
  title: string,
  query: string,
  link: string,
  type: "FAVORITE" | "LATEST";
}

export interface ProjectListSummaryState {
  projects: Array<Project>;
}

export class ProjectListSummary extends Component<ProjectListSummaryProps, ProjectListSummaryState> {
  state: ProjectListSummaryState = {
    projects: [],
  }

  componentWillMount = async () => {
    const r = await App.search({
      table: "platform-buildit-project",
      query: this.props.query,
      from: 0, size: 5,
      sort: [ { created_at: 'desc' } ],
    });

    const rr = r.data;
    if (rr.hits && rr.hits.hits) {
      const projects = rr.hits.hits.map((p: any) => p._source);
      if (rr.hits.total < 5 && rr.hits.total > 0) {
        for (let i = 0; i < 5 - rr.hits.total; i++) {
          projects.push({});
        }
      }
      this.setState({
        projects: projects
      })
    } else {
      console.log('err');
    }
  }

  render() {
    return (
      <div className={`ProjectListSummary ${this.props.className && this.props.className || ""}`}>
        <div className="header">
          <Link to={this.props.link} className="router-link">
            {this.props.title}
            <ArrowForward className="icon m-l-sm" />
          </Link>
        </div>
        <div className="body">
          <div className="project-summary-table">
          {
            this.state.projects.length === 0 &&
            <div className="empty">
              <div className="msg"> 
              {
                this.props.type === "FAVORITE" &&
                "즐겨찾는 프로젝트가 없습니다"
              }
              </div>
            { 
              this.props.type === "LATEST" &&
              <div className="msg">
                <span>최근 생성 프로젝트가 없습니다</span>
                <span>새로운 프로젝트를 시작해주세요!</span>
              </div>
            }
            </div>

            ||
            this.state.projects.map((p: Project) => {
              let to = "edit";
              if (["FINISHED", "RUNNING", "WAITING", "FAILED"].includes(p.status!)) {
                to = "result";
              }

              if (p.user_project_id) {
                return (
                  <Link to={`/project/${to}?pid=${p.user_project_id}`} className="router-link">
                    <div className="tr">
                      <div className="td user_project_id">{p.user_project_id}</div>
                      <div className="td project_type"><ProjectTypeTag type={p.project_type} background="DARK"/></div>
                      <div className="td project_name" title={p.project_name}>
                      {
                        p.discountable && <DiscountTag className="m-r-sm"/>
                      }
                        <div className="name">{p.project_name}</div>
                      </div>
                    </div>
                  </Link>
                )
              } else {
                return (
                  <div className="tr empty" />
                )
              }
            })
          }
          </div>
        </div>
      </div>
    )
  }
}