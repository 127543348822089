import React, { Component } from 'react';
import { FileDrop } from 'react-file-drop';
import { Button } from '@material-ui/core'
import '../css/CADConverter/DropFileToLoadBox.scss';
import { ConverterType } from './DataTypes';
import { ReactComponent as DownloadIcon } from '../img/icon/download.svg';

interface DropFilePops {
  functionForDrop: (file: any) => void,
  functionForButton: (e: React.ChangeEvent<HTMLInputElement>) => void,
  type: ConverterType,
  showSample: boolean,
  loadFile: boolean,
  //sampleImgLink:string,
  //sampleDxfLink:string,
}

interface DropFileState {
  dragCover: boolean,

}

export class DropFileBox extends Component<DropFilePops, DropFileState>{
  state: DropFileState = {
    dragCover: true,
  }
  type = 'my_type';

  componentDidMount = () => {
    switch (this.props.type) {
      case ConverterType.myType:
        this.type = 'my_type';
        break;
      case ConverterType.mySite:
        this.type = 'my_site';
        break;
    }

    console.log(this.props.type);
  }

  render = () => {
    return (
      <div className={`dropFileBox ${this.props.loadFile && 'hidden'}`}>
        <FileDrop onDrop={(files, event) => this.props.functionForDrop(files)}
          onFrameDragEnter={() => this.setState({ dragCover: false })}
          onFrameDragLeave={() => this.setState({ dragCover: true })}
          onFrameDrop={() => this.setState({ dragCover: true })}
        >
          <div className='cover hidden'></div>
        </FileDrop>
        <div className={`sampleExample ${this.props.showSample && 'hidden'}`}>
          <img src={`/img/sample_file/${this.type}/sample.png`} className="sampleImg" />
          <div className='sampleText' >
            <div className='title'>Welcome to BUILDIT!</div>
            <div className='content'><span style={{ color: '#48DDF2' }}>샘플 파일 다운로드 후 업로드</span> 하시면</div>
            <div className='content'> CAD Converter를 체험해 보실 수 있습니다.</div>
            <a href={`../img/sample_file/${this.type}/sample.dxf`} download='sample.dxf'>
              <div className='downloadButton'><span>샘플 DXF 파일 내려받기</span><DownloadIcon className='donwloadIcon' />
              </div>
            </a>
          </div>
        </div>
        <div className={`content ${!this.state.dragCover && 'noEvent'} ${!this.props.showSample && 'topMargin40'}`}>
          <div className='topText'>CAD 파일을 불러와서 <br />{this.props.type}을 업로드해주세요!</div>
          <Button className='button' component="label">
            파일 선택
            <input type="file" id="ex_file" style={{ display: 'none' }} onChange={this.props.functionForButton} accept='.dxf' />
          </Button>
          <div className='bottomText'>또는 파일을 여기로 끌어 놓으세요.<br />현재 dxf 파일만 가능합니다.</div>
        </div>
      </div>
    )
  }
}

