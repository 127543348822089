import React, { Component, Fragment } from 'react';
import SessionComponent from '../SessionComponent';
import BuilditTemplate from '../BuilditTemplate';
import '../css/ProjectResult.scss';
import ProjectInput from '../ProjectInput';
import moment  from 'moment';
import Tabs from '../Tabs';
import { Button, Checkbox, Badge, Input, InputAdornment, Table, TableHead, TableRow, TableCell, TableBody, ClickAwayListener, CircularProgress } from '@material-ui/core';
import Tooltip from '../Tooltip';
import { Project } from '../model/Project';
import AWSModule from '../AWSModule';
import App from '../App';
import queryString from 'query-string';
import schema from '@teneleven/platform-buildit-schema'; // labmda schema
import ProjectValue from '../ProjectValue';
import $ from 'jquery';
import { default as _ } from 'lodash';
import ProjectSummary from '../ProjectSummary';
import SaveIcon from '@material-ui/icons/Save';
import DiscardIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import ToListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit'

import FavoriteProjectIconActive from '@material-ui/icons/Star';
import FavoriteProjectIcon from '@material-ui/icons/StarBorder';
import DownloadIcon from '@material-ui/icons/VerticalAlignBottom';
import Modal, { ModalOptions } from '../Modal';
import { Link } from 'react-router-dom';
import { WhiteSwitch } from '../CustomSwitch';
import ProjectTypeTag from '../ProjectTypeTag';
import { timeScale } from '../Utils';
import S3Image from '../S3Image';
import C3Chart from '../C3Chart';
import { red400 } from 'material-ui/styles/colors';
import HousingChart from '../HousingChart';
import Clipboard from 'react-clipboard.js';
import BuilditInput from '../BuilditInput';
import ProjectCardData from '../model/ProjectCard';
import ProjectIcon from '../ProjectIcon';
import Switch from '../Switch';
import DownloadButton from '../DownloadButton';
import { sampleProject1, sampleProject2, sampleProject3 } from './SampleData';
import PageViewIcon from '@material-ui/icons/Pageview';
import PageFoot from '../pageFoot';
/*
  TODO: result data 가공부분 함수화하기
  Dynamodb counter 호출부분 module화해서 쓰기
*/

export interface ProjectResultProps {}
export interface ProjectResultState {
  results: any[]; // 결과 리스트
  checkedList: boolean[]; // 선택된 리스트
  project: Project; // 해당 프로젝트 정보
  activeResult: number; // 상단 탭 active 번호
  openCompareTooltip: boolean;
  compareTooltipMsg: string;
  resultCompare: boolean; // 전체보기, 비교보기
  openModal: boolean;
  modalOptions?: ModalOptions;
}

export default class ProjectResult extends Component<ProjectResultProps, ProjectResultState> {
  state: ProjectResultState = {
    results: [],
    checkedList: [],
    project: {},
    activeResult: 0,
    openCompareTooltip: false,
    compareTooltipMsg: "",
    resultCompare: false,
    openModal: false,
  }

  componentWillMount = async () => {
    const qs = queryString.parse(location.search);
    if (qs && qs.pid) {
      const user_project_id = qs.pid;
      let project_id = 0;
      // const user_project_id = 554;

      if (qs.pid === "1") {

        sampleProject1.result.map((r, i) => {
          r.base_report_file_path.resImage = `https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/sample_report/sample1/${i + 1}/resView.png`;
          r.base_report_file_path.cad = `https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/sample_report/sample1/${i + 1}/cad.dxf`
          r.base_report_file_path.lightReport = `https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/sample_report/sample1/${i + 1}/세대별 일조시간.xlsx`
          // @ts-ignore
          r.base_report_file_path.viewReport = `https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/sample_report/sample1/${i + 1}/세대별 조망점수.xlsx`
          // @ts-ignore
          r.base_report_file_path.priceReport= `https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/sample_report/sample1/${i + 1}/사업성 분석자료.xlsx`
          r.base_report_file_path.constructionReport = `https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/sample_report/sample1/${i + 1}/건축개요 보고서.xlsx`
        })
        this.setState({
          project: sampleProject1.project as Project,
          results: sampleProject1.result as Array<any>,
          checkedList: sampleProject1.result.map(r => false)
        }, () => {
          App.stage !=="prod" && console.log(this.state.results);
        })
      }
    }
  }

  componentDidUpdate = async () => {
  }

  

  render() {
    
    return (
      <div className="buildit-template" style={{ height: "100%", overflow: "auto" }}>
        <div style={{height: "60px", display: "flex", alignItems: "center", padding: "0px 30px", backgroundColor: "#232732"}}>
          <img src="/img/buildit_platform_logo.svg" style={{height: "25px", cursor: "pointer"}} />
          {
            App.session &&
            <Button className="btn btn-run bg-navy m-l-a" disableRipple={true} style={{width: "140px"}}
              onClick={() => {
                // @ts-ignore
                this.props.history.go(-1);
              }}
            >
              돌아가기
            </Button> ||
            <Button className="btn btn-run bg-navy m-l-a" disableRipple={true} style={{width: "140px"}}
              onClick={() => {
                // @ts-ignore
                this.props.history.push('/user/register')
              }}
            >
              회원가입
            </Button>
          }
          
        </div>
        <div className="ProjectResult">
          <ProjectResultHeader 
            activeResult={this.state.activeResult}
            results={this.state.results} 
            project={this.state.project}
            setActiveResult={this.setActiveResult}
            updateProject={this.updateProject}
            setModal={this.setModal}
            copyProject={this.copyProject}
            discardProject={this.discardProject}
            saveProject={this.saveProject}
          />
          <ProjectResultBody
            activeResult={this.state.activeResult}
            results={this.state.results} 
            project={this.state.project}
            setActiveResult={this.setActiveResult}
            setCheckedResult={this.setCheckedResult}
            checkedList={this.state.checkedList}
            openCompareTooltip={this.state.openCompareTooltip}
            compareTooltipMsg={this.state.compareTooltipMsg}
            resultCompare={this.state.resultCompare}
            setResultCompare={this.setResultCompare}
            setCompareTooltip={this.setCompareTooltip}
          />
           {
             this.state.openModal &&
            <Modal
              open={this.state.openModal}
              type={this.state.modalOptions && this.state.modalOptions.type && this.state.modalOptions.type || "SIMPLE"}
              positive={this.state.modalOptions && this.state.modalOptions.positive}
              negative={this.state.modalOptions && this.state.modalOptions.negative}
              title={this.state.modalOptions && this.state.modalOptions.title}
              negativeTitle={this.state.modalOptions && this.state.modalOptions.negativeTitle}
              positiveTitle={this.state.modalOptions && this.state.modalOptions.positiveTitle}
              content={this.state.modalOptions && this.state.modalOptions.content}
              color={this.state.modalOptions && this.state.modalOptions.color || "DARK"}
            />
           }
        </div>
        <PageFoot />
      </div>
    );
  }

  setCompareTooltip = (on: boolean, msg: string, callback?: Function) => {
    this.setState({
      openCompareTooltip: on,
      compareTooltipMsg: msg
    }, () => {
      if (callback) {
        callback();
      }
    })
  }

  setModal = (open: boolean, options?: ModalOptions) => {
    this.setState({
      openModal: open,
      modalOptions: options
    });
  }

  copyProject = () => {

  }
  discardProject = () => {
    
  }

  setResultCompare = (isCompare: boolean) => {
    if (isCompare) {
      if (this.state.checkedList.filter(r => r === true).length < 2) {
        this.setCompareTooltip(true, "2개 이상의 프로젝트를 선택해주세요", () => {
          setTimeout(() => {
            this.setCompareTooltip(false, "");
          }, 1500)
        })
      } else {
        this.setState({ resultCompare: isCompare })
      }
    } else {
      this.setState({ resultCompare: isCompare });
    }
  }

  updateProject = async (update: Project, callback?: Function) => {
  }

  saveProject = async (project: Project) => {
  }

  setActiveResult = (n: number) => {
    this.setState({ activeResult: n}, () => {
      window.scrollTo(0, 0);
    });
  }

  setCheckedResult = (reportId: number) => { 
      const n = this.state.results.findIndex(e => e.real_report_number === reportId);
      if (this.state.checkedList[n] === false) {
        if (this.state.checkedList.filter(e => e === true).length > 3) {
          this.setCompareTooltip(true, "프로젝트의 비교는 최대 4개까지 가능합니다", () => {
            setTimeout(() => {
              this.setCompareTooltip(false, "");
            }, 1500);
          })
          return;
        }
        this.setState({ 
          checkedList: this.state.checkedList.map((r, i) => { return i === n ? !r : r })
        }, () => {
          this.setCompareTooltip(true, "비교할 프로젝트가 선택되었습니다", () => {
            setTimeout(() => {
              this.setCompareTooltip(false, "");
            }, 1500);
          })
        });
      } else {
        this.setState({ checkedList: this.state.checkedList.map((r, i) => { return i === n ? !r : r }) });
      }
  }
  onCreateProject = async(callback: Function) => {
  }
}

export interface ProjectResultHeaderProps {
  results: any[];
  project: Project;
  activeResult: number;

  updateProject: (update: Project, callback?: Function) => void;
  saveProject: (project: Project) => void;
  setActiveResult: (n: number) => void;
  setModal: (open: boolean, options: ModalOptions) => void;
  copyProject: () => void;
  discardProject: () => void;
}
export interface ProjectResultHeaderState {
  projectNameFocus: boolean;  
  editProjectDescription: boolean;
  onSave: boolean;
  saveTooltipMsg: string;
}

export class ProjectResultHeader extends Component<ProjectResultHeaderProps, ProjectResultHeaderState> {
  state: ProjectResultHeaderState = {
    projectNameFocus: false,
    editProjectDescription: false,
    onSave: false,
    saveTooltipMsg: "",
  }

  componentDidUpdate = (pp: Readonly<ProjectResultHeaderProps>, ps: Readonly<ProjectResultHeaderState>) => {

  }

  render() {
    return (
      <div className="ProjectResultHeader">
        <div className="wrapper">
          <div className="page-title bg-navy font font-primary">
          {
            <div className="project-name-no-edit">
              <div className="project-name">(SAMPLE)불광 5구역 - 주택재개발정비사업 프로젝트</div>
            </div>
          }
          </div>
          <div className="project-info">
            <div className="id-type-status">
              <div className="project-type">
                <ProjectTypeTag background="DARK" type={this.props.project.project_type!} projectId={this.props.project.project_id || 0} />
              </div>
              <div className="project-status">              
                <ProjectIcon status={this.props.project.status} onText={true} />
              </div>
            </div>
            <div className="project-description">
            {
              <div style={{color: "#999", padding: "10px", border: "1px solid #555", width: "100%", boxSizing: "border-box", marginTop: "10px"}}>
                불광5구역, 서울시 건축심의 통과<br />불광역 인근 2393가구<br />새 아파트 단지 탈바꿈
              </div>
            }
            </div>
          </div>
          {
            this.props.project.status === "FINISHED" && (this.props.project.project_type === "AI" || this.props.project.project_type === "CAL") &&
            <div className="result-reports">
              <Tabs className="tabs bg-navy">
                <Button
                disableRipple={true} 
                className={`img-btn tab bg-navy tab-primary ${this.props.activeResult === 0 && "active" || ""}`}
                onClick={e => this.props.setActiveResult(0)}
                disabled={this.props.activeResult === 0}
                >
                  전체 결과
                </Button>
                {
                  [1, 2, 3, 4, 5, 6 , 7, 8, 9, 10].map((r, i) => {
                    if (i < this.props.results.length) {
                      return (
                        <Button
                          disableRipple={true} 
                          className={`img-btn tab bg-navy tab-primary
                            ${this.props.activeResult === i + 1 && "active" || ""}`
                          }
                          onClick={e => this.props.setActiveResult(i + 1)}
                          >
                          {`${i + 1}번 결과`}
                        </Button>
                      )
                    } else {
                      return <Button className={`bg-navy tab tab-primary visibility-hidden`} disabled={true}></Button>
                    }
                  })
                }
              </Tabs>
            </div>
          }
        </div>        
      </div>
    )
  }
}

export interface ProjectResultBodyProps {
  results: any[];
  project: Project;
  activeResult: number;
  checkedList: boolean[];
  openCompareTooltip: boolean;
  compareTooltipMsg: string;
  resultCompare: boolean;
  setActiveResult: (n: number) => void;
  setCheckedResult: (n: number) => void;
  setResultCompare: (isCompare: boolean) => void;
  setCompareTooltip: (on: boolean, msg: string) => void;
}
export interface ProjectResultBodyState {
  resultChart: any[];
  resultChartCompare: any[];
  resultChartElement: any[];
  // designerCode: string;
  openDesginerTooltip: boolean;
}

export class ProjectResultBody extends Component<ProjectResultBodyProps, ProjectResultBodyState> {

  state: ProjectResultBodyState = {
    resultChart: [],
    resultChartCompare: [],
    resultChartElement: [],
    // designerCode: "",
    openDesginerTooltip: false,
  }
  barChartColors = {
    '용적률': '#5aa2a6',
    '건폐율': '#6e748e',
    '세대수': '#8a6cc9',
    '2시간 미만 일조비율': '#eed784',
    '평균 일조 시간': '#e8afac',
    '최빈 일조 시간': '#c79477',
    '평균 조망 점수': '#b5ffe7',
  }

  getChartData = (type: any, resultVal: number) => {
    let chartVal = 0;
    if (type === "용적률") {
      const setVal = this.props.project!.floor_area_ratio!;
      const dif = setVal - resultVal;
      if (dif >= 0) {
        chartVal = resultVal / setVal * 100;
      } else {
        chartVal = (setVal - Math.abs(dif / 2)) / setVal * 100;
      }
    } else if (type === "건폐율") {
      const setVal = this.props.project!.building_land_ratio!;
      const dif = setVal - resultVal;
      if (dif >= 0) {
        chartVal = resultVal / setVal * 100;
      } else {
        chartVal = (setVal - Math.abs(dif / 2)) / setVal * 100;
      }
    } else if (type === "세대수") {
      const max = Math.max.apply(null, this.props.results.map(r => r.loc_total_household));
      const min = Math.min.apply(null, this.props.results.map(r => r.loc_total_household));
      if (max - min === 0) {
        chartVal = max !== 0 ? 100 : 1;
      } else {
        chartVal = resultVal !== 0 ? resultVal / max * 100 : 1;
      }
    } else if (type === "평균 조망 점수") {
      const max = Math.max.apply(null, this.props.results.map(r => r.view_point_avg));
      const min = Math.min.apply(null, this.props.results.map(r => r.view_point_avg));
      if (max - min === 0) {
        chartVal = max !== 0 ? 100 : 1;
      } else {
        chartVal = resultVal !== 0 ? resultVal / max * 100 : 1;
      }
    } else if (type === "2시간 미만 일조비율") {
      chartVal = resultVal;
    } else if (type === "평균 일조 시간") {
      const max = Math.max.apply(null, this.props.results.map(r => r.daylight_hours_avg));
      const min = Math.min.apply(null, this.props.results.map(r => r.daylight_hours_avg));
      if (max - min === 0) {
        chartVal = max !== 0 ? 100 : 1;
      } else {
        chartVal = resultVal !== 0 ? resultVal / max * 100 : 1;
      }
    } else if (type === "최빈 일조 시간") {
      const max = Math.max.apply(null, this.props.results.map(r => r.daylight_hours_mode));
      const min = Math.min.apply(null, this.props.results.map(r => r.daylight_hours_mode));
      if (max - min === 0) {
        chartVal = max !== 0 ? 100 : 1;
      } else {
        chartVal = resultVal !== 0 ? resultVal / max * 100 : 1;
      }
    }

    return chartVal === 0 ? 1 : chartVal;
  }

  setChartData = () => {
    this.setState({
      resultChart: [],
      resultChartElement: [],
      resultChartCompare: [],
    }, () => {

    });

    let resultChart: any[] = [];
    let resultChartCompare: any[] = [];
    let resultChartElement: any[] = [];

    resultChart.push(['x', '용적률', '건폐율', '세대수', '평균 일조 시간', '최빈 일조 시간', '2시간 미만 일조비율', '평균 조망 점수']);
    resultChartCompare.push(['x', '용적률', '건폐율', '세대수', '평균 일조 시간', '최빈 일조 시간', '2시간 미만 일조비율', '평균 조망 점수']);
    this.props.results.map((r, i) => {
      resultChart.push(
        [
          `${this.props.results[i].real_report_number}번 결과`,
          this.getChartData('용적률', r.loc_floor_area_ratio),
          this.getChartData('건폐율', r.loc_building_land_ratio),
          this.getChartData('세대수', r.loc_total_household),
          this.getChartData('평균 일조 시간', r.daylight_hours_avg),
          this.getChartData('최빈 일조 시간', r.daylight_hours_mode),
          this.getChartData('2시간 미만 일조비율', r.daylight_hours_proportion_less_n_hours && r.daylight_hours_proportion_less_n_hours.length > 0 ? r.daylight_hours_proportion_less_n_hours[1] : 0),
          this.getChartData('평균 조망 점수', r.view_point_avg),
          // r.floor_area_ratio,
          // r.building_land_ratio,
          // r.total_household,
          // r.daylight_hours_avg,
          // r.daylight_hours_mode,
          // r.daylight_hours_proportion_less_n_hours[2],
          // r.view_point_avg
        ]
      )
    })

    this.setState({ resultChart: _.cloneDeep(resultChart)});

    this.props.results.map((r, i) => {
      this.props.checkedList[i] &&
      resultChartCompare.push(
        [
          `${this.props.results[i].real_report_number}번 결과`,
          this.getChartData('용적률', r.loc_floor_area_ratio),
          this.getChartData('건폐율', r.loc_building_land_ratio),
          this.getChartData('세대수', r.loc_total_household),
          this.getChartData('평균 일조 시간', r.daylight_hours_avg),
          this.getChartData('최빈 일조 시간', r.daylight_hours_mode),
          this.getChartData('2시간 미만 일조비율', r.daylight_hours_proportion_less_n_hours && r.daylight_hours_proportion_less_n_hours.length > 0 ? r.daylight_hours_proportion_less_n_hours[1] : 0),
          this.getChartData('평균 조망 점수', r.view_point_avg),
        ]
      )
    })
    this.setState({ resultChartCompare: _.cloneDeep(resultChartCompare)});
  }

  componentDidUpdate = (pp: Readonly<ProjectResultBodyProps>, ps:Readonly<ProjectResultBodyState>) => {
    if (_.isEqual(pp, this.props) === false && this.props.project.project_type !== "DESIGNER") {
      this.setChartData();
    }

    // if(_.isEqual(ps.designerCode, this.state.designerCode) === false) {
    //   // @ts-ignore
    //   navigator.clipboard.writeText(this.state.designerCode);
    //   this.copyKeyCode();
    // }
  }

  componentWillMount = () => {
    this.setChartData();
  }

  getChartOptions = (cdata: Readonly<any>, results: Readonly<any>) => {
    const resultChartColors = {
      '용적률': '#5aa2a6',
      '건폐율': '#6e748e',
      '세대수': '#8a6cc9',
      '2시간 미만 일조비율': '#eed784',
      '평균 일조 시간': '#e8afac',
      '최빈 일조 시간': '#c79477',
      '평균 조망 점수': '#b5ffe7',
    }
    const defaultOptions = {
      data: {
        x: 'x',
        rows: cdata,
        groups: [ [ '용적률', '건폐율', '세대수', '평균 일조 시간', '최빈 일조 시간', '2시간 미만 일조비율', '평균 조망 점수'] ],
        colors: resultChartColors,
        order: null,
      },
      type: 'bar',
      config: {
        size: {
          height: 50 + 25 * cdata.length,
        },
        tooltip: {
          order: null,
          format: {
            title: (index: any) => {
              return cdata[index + 1][0];
            },
            value: (val: any, ratio: any, id: any, index: any) => {
              let format = val;
              const reportId = Number(cdata[index + 1][0].split('번')[0]);
              const resultIndex = this.props.results.findIndex(e => e.real_report_number === reportId);
              if (id === "용적률") {
                format = results[resultIndex].loc_floor_area_ratio.toFixed(2) + "%";
              } else if (id === "건폐율") {
                format = results[resultIndex].loc_building_land_ratio.toFixed(2) + "%";
              } else if (id === "세대수") {
                format = results[resultIndex].loc_total_household + "세대";
              } else if (id === "평균 일조 시간") {
                format = timeScale(results[resultIndex].daylight_hours_avg);
              } else if (id === "최빈 일조 시간") {
                format = timeScale(results[resultIndex].daylight_hours_mode);
              } else if (id === "2시간 미만 일조비율") {
                if (results[resultIndex].daylight_hours_proportion_less_n_hours) {
                  format = (results[resultIndex].daylight_hours_proportion_less_n_hours.length > 0 ? results[resultIndex].daylight_hours_proportion_less_n_hours[1].toFixed(2) : 0) + "%";
                }
              } else if (id === "평균 조망 점수") {
                format = results[resultIndex].view_point_avg + "점";
              }
              return format;
            }
          }
        },
        legend: {
          item: {
            onmouseover: (d: any) => {
              let resultChartElement = [];
              resultChartElement.push(['x', d]);
  
              cdata.map((r: any, i: number) => {
                if (i !== 0) {
                  resultChartElement.push([
                    r[0].split('번')[0],
                    cdata[i][cdata[0].findIndex((e: any) => e === d)]
                  ]);
                }
              });
              this.setState({ resultChartElement: resultChartElement })
            }
          }
        },
        bar: {
          width: 5
        },
        axis: {
          x: {
            type: 'category'
          },
          rotated: true,
        },
        padding: {
          left: 55,
        },
      }
    }

    return defaultOptions;
  }

  render() {
    if (this.props.activeResult === 0) { // 전체결과
      const allChartOptions = this.getChartOptions(this.state.resultChart, this.props.results);
      const compareChartOptions = this.getChartOptions(this.state.resultChartCompare, this.props.results);
      const elementChartOptions = this.getChartOptions(this.state.resultChartElement, this.props.results);
      elementChartOptions.config.padding.left = 20;
      elementChartOptions.config.legend.item.onmouseover = () => {};

      return (
        <div className="ProjectResultBody ALL">
        {
          this.props.project.status === "CONFIG" && 
          <div className="project-status-info">
            <div className="bg-navy font font-special title">프로젝트 설정을 완료 후 실행해주세요</div>
            <Link to={`/project/edit?pid=${this.props.project.user_project_id}`}>
              <Button disableRipple={true} className="btn btn-primary bg-navy">설정하러 가기</Button>
            </Link>
          </div>
        }
        {
          this.props.project.status === "RUNNING" && 
          <div className="project-status-info">
            <div className="bg-navy font font-special  title">프로젝트가 분석중입니다.</div>
            <Link to={`/project/list`}>
              <Button disableRipple={true} className="btn btn-primary bg-navy">목록으로 가기</Button>
            </Link>
          </div>
        }
        {
          this.props.project.status === "WAITING" &&
          <div className="project-status-info">
            <div className="bg-navy font font-special  title">
            {
              (this.props.project.project_type === "DESIGNER" || this.props.project.project_type === "SITE_PLAN") && 
              `프로젝트가 처리중입니다` || `프로젝트가 분석중입니다.`
            }
            </div>
            <Link to={`/project/list`}>
              <Button disableRipple={true} className="btn btn-primary bg-navy">목록으로 가기</Button>
            </Link>
          </div>
        }
        {
          this.props.project.status === "FAILED" && 
          <div className="project-status-info">
            <div className="bg-navy font font-special  title">프로젝트의 분석에 문제가 발생했습니다</div>
            <div className="font-emphasis m-b-sm font font-14px m-t-md">Email: 1011@1011.co.kr  Tel: 02 - 375 - 1011</div>
            <div className="font-emphasis m-b-sm font font-14px">위 연락처로 연락주시면 빠르게 처리해드리겠습니다.</div>
            <Link to={`/project/list`}>
              <Button disableRipple={true} className="btn btn-primary bg-navy">목록으로 가기</Button>
            </Link>
          </div>
        }
        {
          this.props.project.status === "FINISHED" &&
          (this.props.project.project_type === "AI" || this.props.project.project_type === "CAL" ) &&
          <Fragment>
            <div className="main-title bg-navy font font-emphasis">전체 결과</div>
            <div className="result-list-wrap">
              <div className="header">
                <div className="title font font-special">결과 목록</div>
                <div className="description m-l-md"></div>
                <div className="right-pannel">
                  {
                    this.props.checkedList.filter(r => r === true).length > 0 &&
                    <Link target="_blank" to={`/Visualizer?pid=${this.props.project.user_project_id}${
                      this.props.results.map((r: any, i: number) => {
                        if (this.props.checkedList[i]) {
                          return `&rid=${r.base_report_id}`
                        }
                      }).join().replace(/,/g, "")
                      }`} className="router-link">
                      <Button disableRipple={true} className={`btn bg-navy btn-small viewer-btn btn-run`}>3D VIEWER</Button>
                    </Link> 
                  }
                    <div>
                      <Tabs className="tabs bg-navy tabs-small compare-tabs m-l-a">
                        <Button disableRipple={true} className={`tab bg-navy tab-primary tab-small ${this.props.resultCompare === false && "active" || ""}`} onClick={e => this.props.setResultCompare(false)}>전체보기</Button>
                        <Tooltip msg={this.props.compareTooltipMsg}
                          on={this.props.openCompareTooltip}
                          id="ProjectResult-bottom-Compare"
                          className="tooltip"
                        >
                          <Button disableRipple={true} className={`tab bg-navy tab-primary tab-small ${this.props.resultCompare && "active" || ""}`} onClick={e => this.props.setResultCompare(true)}>비교보기</Button>
                        </Tooltip>
                      </Tabs>
                    </div>
                </div>
              </div>
              <ProjectResultList
                project={this.props.project}  
                setCheckedResult={this.props.setCheckedResult}
                checkedList={this.props.checkedList}
                results={this.props.results}
                isCompare={this.props.resultCompare}
                setActiveResult={this.props.setActiveResult}
              />
            </div>
            <div className="result-chart-wrap">
              <div className="result-chart">
                <div className="header font font-special bg-navy">
                  결과 그래프
                  <Tabs className="tabs bg-navy tabs-small compare-tabs m-l-a">
                    <Button disableRipple={true} className={`tab bg-navy tab-primary tab-small ${this.props.resultCompare === false && "active" || ""}`} onClick={e => this.props.setResultCompare(false)}>전체보기</Button>
                    <Tooltip msg={this.props.compareTooltipMsg}
                      on={this.props.openCompareTooltip}
                      id="ProjectResult-bottom-Compare"
                      className="tooltip"
                    >
                      <Button disableRipple={true} className={`tab bg-navy tab-primary tab-small ${this.props.resultCompare && "active" || ""}`} onClick={e => this.props.setResultCompare(true)}>비교보기</Button>
                    </Tooltip>
                    {/* <Tooltip msg={this.props.compareTooltipMsg} id="ProjectResult-top-Compare" on={this.props.openCompareTooltip} >
                      <Button disableRipple={true} className={`tab bg-navy tab-primary tab-small ${this.props.resultCompare && "active" || ""}`} onClick={e => this.props.setResultCompare(true)}>비교보기</Button>
                    </Tooltip> */}
                  </Tabs>
                </div>
                <div className="content">
                  <div className="description">결과별 비교를 하시려면 결과 목록에서 선택 후 확인하세요.</div>
                  {
                    this.props.resultCompare === false &&
                    <div className="chart">
                    {
                      this.props.results.length > 0 &&
                      <C3Chart 
                        type={allChartOptions.type}
                        data={allChartOptions.data}
                        config={allChartOptions.config}
                      />
                    }
                    </div> || 
                    <div className="chart">
                    {
                      this.props.results.length > 0 && this.props.checkedList.filter(r => r === true).length > 0 &&
                      <C3Chart 
                        type={compareChartOptions.type}
                        data={compareChartOptions.data}
                        config={compareChartOptions.config}
                      />
                    }
                    </div>
                  }
                </div>
              </div>
              <div className="result-chart element">
                <div className="header font font-special bg-navy">요소 별 비교</div>
                <div className="content">
                  <div className="description">
                  {
                    this.state.resultChartElement.length === 0 &&
                    <Fragment>
                      <div className="msg">범례에 마우스를 올리시면<br />특정 요소만 비교 가능합니다.</div>
                      <div className="msg">범례를 클릭하여<br />특정 요소를 숨길 수 있습니다. </div>
                    </Fragment>
                  }
                  </div>
                  <div className="chart">
                    {
                      this.state.resultChartElement.length > 0 &&
                      <C3Chart 
                        type={elementChartOptions.type}
                        data={elementChartOptions.data}
                        config={elementChartOptions.config}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </Fragment> 
          
        }
        {
          (this.props.project.project_type === "DESIGNER" || this.props.project.project_type === "SITE_PLAN") && this.props.project.status === "FINISHED" &&
         <Fragment>
          <div className="download-wrap">
            <div className="header">결과 파일 다운로드</div>
            <div className="content">
              {
                this.props.results.length > 0 &&
                <Fragment>
                  <div className="code">
                    DESIGNER Code : 
                    <span style={{color: "#ccc"}}>
                    {
                      this.props.results.length > 0 &&
                      this.props.results[0].designer_code.length > 0 && this.props.results[0].designer_code || "Designer-Code Error"
                    }
                    </span>
                    {
                      <Clipboard data-clipboard-text={this.props.results[0].designer_code}
                        className="project-result-code-copy-btn" onClick={this.copyKeyCode}>
                          <Tooltip on={this.state.openDesginerTooltip} msg="클립보드에 저장 되었습니다" className="tooltip">Copy Code</Tooltip>
                      </Clipboard> || ""  
                    }
                    
                  </div>
                  <div className="download-btns">
                    <DownloadButton 
                      className="bg-navy btn btn-large btn-run m-r-md download-btn designer-download-btn"
                      onClick={(callback: Function) => this.downloadDesigner(() => { callback && callback(); })}
                    >
                      <div>DESIGNER</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>zip</span>
                    </DownloadButton>
                    <DownloadButton onClick={(callback: Function) => this.downloadPriceReport(() => callback && callback())}
                      className="bg-navy btn btn-primary download-btn"
                    >
                      <div>사업성 분석자료</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>xlsx</span>
                    </DownloadButton>
                  </div>
                </Fragment>
              }
            </div>
          </div>
        </Fragment> 
        }
          
          <ProjectSummary project={this.props.project} className="project-summary" />
        </div>
      )
    } else {
      const chartData: any = [];
      const pr = this.props.results[this.props.results.findIndex(e => e.real_report_number === this.props.activeResult)];
      if (this.props.results) {
        // if (this.props.results[this.props.activeResult - 1].loc_my_building_shape_type && this.props.project.my_building_shape_type.length > 0) {
        //   this.props.project.my_building_shape_type.map(r => chartData.push([`동평면 (${r.name})`, r.proportion]));
        // }
        if (pr.loc_housing_type_ratio && pr.loc_housing_type_ratio.length > 0) {
          // pr.loc_housing_type_ratio.map((r: any) => chartData.push([`${r.area}㎡/${r.bay}베이`, r.proportion]));
          pr.loc_housing_type_ratio.map((r: any) => chartData.push([`${r.area}㎡`, r.proportion]));
        }
      }

      const resultIndex = this.props.results.findIndex(e => e.real_report_number === this.props.activeResult);
      const resultNumber = this.props.results[resultIndex].real_report_number;
      return (
        <div className="ProjectResultBody DETAIL">
          <div className="main-title bg-navy font font-emphasis">
            {resultNumber}번 결과
          </div>
          <div className="content">
            <div className="result-img-files">
              <div className="cad-img">
                <img src={this.props.results[resultIndex].base_report_file_path.resImage} />
              </div>
              <Link target="_blank" to={`sampleVisualizer?pid=${this.props.project.user_project_id}&rid=${this.props.results[resultIndex].base_report_id}`}  style={{textDecoration: "none"}}>
                <Button disableRipple={true} className="bg-navy btn btn-run webgl-btn">3D VIEWER</Button>
              </Link>
              <div className="files">
                <div className="header font font-special bg-navy">
                  결과 파일 다운로드
                </div>
                <div className="content">
                  <div className="designer">
                    {/* TODO: 클릭하면 복사되도록 */}
                    DESIGNER Code :
                    <span className="key">{this.props.results[resultIndex].designer_code}</span>
                    {
                      this.props.results[resultIndex].designer_code.length > 0 && 
                      <Clipboard data-clipboard-text={this.props.results[resultIndex].designer_code}
                        className="project-result-code-copy-btn" onClick={this.copyKeyCode}>
                          <Tooltip on={this.state.openDesginerTooltip} msg="클립보드에 저장 되었습니다" className="tooltip">Copy Code</Tooltip>
                      </Clipboard> || ""
                    }
                  </div>
                  <div className="report-files">
                    <DownloadButton 
                      className="bg-navy btn btn-run designer-download-btn download-btn"
                      onClick={(callback: Function) => { 
                        alert('추후 제공될 예정입니다.')
                        // window.location.href = "https://buildit-sample-bucket.s3.ap-northeast-2.amazonaws.com/BUILDIT+DESIGNER+SAMPLE.zip";
                        callback(); 
                      }}
                    >
                        <div>DESIGNER</div>
                        <span className="m-l-a" style={{textTransform: "none"}}>zip</span>
                    </DownloadButton>
                    <DownloadButton onClick={(callback: Function) => this.downloadReport(this.props.results[resultIndex].base_report_file_path.cad, () => callback && callback())}
                      className="bg-navy btn btn-primary download-btn m-l-a"
                    >
                      <div>사업영역 배치도</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>dxf</span>
                    </DownloadButton>
                    <DownloadButton onClick={(callback: Function) => this.downloadReport(this.props.results[resultIndex].base_report_file_path.constructionReport, () => callback && callback())}
                      className="bg-navy btn btn-primary download-btn"
                    >
                      <div>건축개요 보고서</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>xlsx</span>
                    </DownloadButton>
                    <DownloadButton onClick={(callback: Function) => this.downloadReport(this.props.results[resultIndex].base_report_file_path.lightReport, () => callback && callback())}
                      className="bg-navy btn btn-primary download-btn m-l-a"
                    >
                      <div>세대 별 일조시간</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>xlsx</span>
                    </DownloadButton>
                    <DownloadButton onClick={(callback: Function) => this.downloadReport(this.props.results[resultIndex].base_report_file_path.viewReport, () => callback && callback())}
                      className="bg-navy btn btn-primary download-btn "
                    >
                      <div>세대 별 조망점수</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>xlsx</span>
                    </DownloadButton>
                    <DownloadButton onClick={(callback: Function) => this.downloadReport(this.props.results[resultIndex].base_report_file_path.priceReport, () => callback && callback())}
                      className="bg-navy btn btn-primary download-btn m-l-a"
                    >
                      <div>사업성 분석자료</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>xlsx</span>
                    </DownloadButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="result-summary">
              <div className="title font font-special">결과 개요</div>
              <div className="content">
                <div className="values-left">
                  <ProjectValue
                    className="value-lg"
                    title={"사업영역 면적"}
                    unit='㎡'
                    value={this.props.project && Number(Number(this.props.project.project_site_area).toFixed(2)).toLocaleString()}
                   />
                   <ProjectValue
                    className="value-lg"
                    title={"추정 공사비"}
                    unit="(천)원"
                    value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_construction_cost / 1000).toFixed(0)).toLocaleString()}
                   />
                </div>
                <div className="values-right">
                  <ProjectValue
                    className="value-md"
                    unit="%"
                    title={"건폐율"}
                    value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_building_land_ratio).toFixed(2)).toLocaleString()}
                   />
                   <ProjectValue
                    className="value-md"
                    unit="%"
                    title={"용적률"}
                    value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_floor_area_ratio).toFixed(2)).toLocaleString()}
                   />
                   <ProjectValue
                    className="value-md"
                    unit="동"
                    title={"건물 수"}
                    value={this.props.results[resultIndex] && this.props.results[resultIndex].loc_building_number}
                   />
                   <ProjectValue
                    className="value-md last"
                    unit="세대"
                    title={"총 세대수"}
                    value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_total_household).toFixed(2)).toLocaleString()}
                   />
                   <ProjectValue
                    className="value-md"
                    unit="층"
                    title={"최저 층수"}
                    value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_building_stories_min).toFixed(2)).toLocaleString()}
                   />
                   <ProjectValue
                    className="value-md"
                    unit="층"
                    title={"최고 층수"}
                    value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_building_stories_max).toFixed(2)).toLocaleString()}
                   />
                   <ProjectValue className="value-md" title="평균 층수 타입" value={
                      this.props.project.building_stories_avg_type === "AREA" && "기준 면적" ||
                      (this.props.project.building_stories_avg_type === "HOUSE" && "총 세대" || "산술 평균")
                    } />
                   <ProjectValue
                    className="value-md last"
                    unit="층"
                    title={"평균 층수"}
                    value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_building_stories_avg[this.props.project.building_stories_avg_type!]).toFixed(2)).toLocaleString()}
                   />
                   <ProjectValue
                    className="value-md"
                    unit="점"
                    title={"평균 조망 점수"}
                    value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].view_point_avg).toFixed(2)).toLocaleString()}
                   />
                   <ProjectValue
                    className="value-md"
                    title={"평균 일조시간"}
                    value={this.props.results[resultIndex] && moment(0).utc().add(moment.duration(Number(this.props.results[resultIndex].daylight_hours_avg.toFixed(2)), 'hours')).format('HH시간 mm분')}
                    //value={this.props.results[resultIndex] && timeScale(this.props.results[resultIndex].daylight_hours_avg)}
                   />
                   <ProjectValue
                    className="value-md"
                    title={"최빈 일조시간"}
                    value={this.props.results[resultIndex] && moment(0).utc().add(moment.duration(Number(this.props.results[resultIndex].daylight_hours_mode.toFixed(2)), 'hours')).format('HH시간 mm분')}
                    //value={this.props.results[resultIndex] && timeScale(this.props.results[resultIndex].daylight_hours_mode)}
                   />
                   <ProjectValue
                    className="value-md lt-small  last"
                    title={"2시간 미만 일조비율"}
                    unit="%"
                    value={
                      this.props.results[resultIndex] && this.props.results[resultIndex].daylight_hours_proportion_less_n_hours &&
                      this.props.results[resultIndex].daylight_hours_proportion_less_n_hours.length > 0 ?
                      Number(Number(this.props.results[resultIndex].daylight_hours_proportion_less_n_hours[1]).toFixed(2)).toLocaleString() : 0
                  }
                   />
                   <div className="housing-type-chart">
                    <div className="title">세대 비율</div>
                      <HousingChart
                        project={this.props.project}
                        result={this.props.results[resultIndex]}
                        legend="right"
                        id="project-result-c3-chart"
                        type="result"
                     />
                   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  copyKeyCode = () => {
    this.setState({
      openDesginerTooltip: true
    }, () => {
      setTimeout(() => { 
        this.setState({
          openDesginerTooltip: false
        })
      }, 1500);
    })
  }
  downloadPriceReport = async (callback?: Function) => {
    
  }

  downloadReport = (path: string, callback?: Function) => {
    window.location.href = path;
    callback && callback();
  }

  downloadDesigner = (callback?: Function) => {
  }
}

export interface ProjectResultFooterProps {
  project: Project;
  setModal: (open: boolean, options: ModalOptions) => void;
  copyProject: () => void;
  discardProject: () => void;
}
export interface ProjectResultFooterState {}

export class ProjectResultFooter extends Component<ProjectResultFooterProps, ProjectResultFooterState> {
  render() {
    return (
      <div className="ProjectResultFooter">
        <div className="wrapper">
          <div className="buttons">
            {
              (this.props.project.status === "WAITING" || this.props.project.status === "RUNNING") &&
              <Tooltip msg="분석이 완료되기 전에 삭제하실 수 없습니다." id="ProjectResult-bottom-Discard" className="m-r-sm">
                <Button
                  disabled={true}
                  onClick={this.props.discardProject} disableRipple={true} className="bg-navy btn btn-cancel disabled"
                >
                  <DiscardIcon className="icon" />
                </Button>
              </Tooltip> ||
              <Tooltip msg="삭제하기" id="ProjectResult-bottom-Discard" className="m-r-sm">
                <Button
                  onClick={this.props.discardProject} disableRipple={true} className="bg-navy btn btn-cancel"
                >
                  <DiscardIcon className="icon" />
                </Button>
              </Tooltip>
            }
            {
              this.props.project.project_type === "CAL" &&  
              <Tooltip msg="CAL 프로젝트는 복사할 수 없습니다" id="ProjectResult-bottom-Copy"  className="m-r-sm">
                <Button disabled onClick={this.props.copyProject} disableRipple={true} className="bg-navy btn btn-primary disabled"><CopyIcon className="icon" /></Button>
              </Tooltip> ||
              <Tooltip msg="복사하기" id="ProjectResult-bottom-copy"  className="m-r-sm">
                <Button onClick={this.props.copyProject} disableRipple={true} className="bg-navy btn btn-primary"><CopyIcon className="icon" /></Button>
              </Tooltip>
            }
            <Link to="/project/list">
              <Tooltip msg="목록으로" id="ProjectResult-bottom-List">
                <Button disableRipple={true} className="bg-navy btn btn-primary"><ToListIcon className="icon" /></Button>
              </Tooltip>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}


export interface ProjectCardHover {
  "건폐율" : boolean,
  "용적률" : boolean,
  "최저 층수": boolean,
  "최고 층수": boolean,
  "평균 층수": boolean,
  "건물 수" : boolean,
  "총 세대수": boolean,
  "평균 일조시간": boolean,
  "최빈 일조시간": boolean,
  "2시간 미만 일조비율": boolean,
  "평균 조망점수": boolean,
  "추정 공사비": boolean,
}

export interface ProjectResultListProps {
  isCompare: boolean;
  checkedList: boolean[],
  results: any[],
  project: Project,
  setCheckedResult: (reportId: number) => void;
  setActiveResult: (n: number) => void;
}
export interface ProjectResultListState {
  hovers: ProjectCardHover,
}
export class ProjectResultList extends Component<ProjectResultListProps, ProjectResultListState> {
  state: ProjectResultListState = {
    hovers: {
      "건폐율" : false,
      "용적률" : false,
      "최저 층수": false,
      "최고 층수": false,
      "평균 층수": false,
      "건물 수" : false,
      "총 세대수": false,
      "평균 일조시간": false,
      "최빈 일조시간": false,
      "2시간 미만 일조비율": false,
      "평균 조망점수": false,
      "추정 공사비": false,
    }
  }

  render() {
    if (this.props.isCompare === false) {
      return (
        <div className="ProjectResultList NORMAL">
        {
          this.props.results &&
          this.props.results.map((r, i) => {
            return <ProjectResultCard
                setActiveResult={this.props.setActiveResult}
                project={this.props.project}
                hovers={this.state.hovers}
                result={r}
                className={`project-card`}
                setCheckedResult={this.props.setCheckedResult}
                checked={this.props.checkedList[i]}
                disableCheckedEffect={true}
                isCompare={this.props.isCompare}
                setHovers={this.setHovers}
              />
          })
        }
        </div>
      )
    } else {
      return (
        <div className="ProjectResultList COMPARE">
          <div className="project-card-header">
            <div className="title">결과 비교</div>
            <div className="project-site">
              <div className="info">
                <div className="title">대지영역 면적</div>
                <div className="area">{Number(Number(this.props.project.project_site_area).toFixed(2)).toLocaleString()}㎡</div>
              </div>
            </div>
            <div className="content">
            {
              ProjectCardData.map(r => {
                return (
                  <div className={`key ${this.state.hovers[r.fieldName as keyof ProjectCardHover] && "hover" || ""}`}>
                    {r.fieldName}
                  </div>
                )
              })
            }
            </div>
          </div>
        {
          this.props.results &&
          this.props.results.map((r, i) => {
            if (this.props.checkedList[i]) {
              return <ProjectResultCard
                setActiveResult={this.props.setActiveResult}
                project={this.props.project}
                hovers={this.state.hovers}
                result={r}
                className={`project-card`}
                setCheckedResult={this.props.setCheckedResult}
                checked={this.props.checkedList[i]}
                disableCheckedEffect={true}
                isCompare={this.props.isCompare}
                setHovers={this.setHovers}
              />
            }
          })
        }
        </div>
      )
    }
  }

  setHovers = (fieldName: keyof ProjectCardHover, hover: boolean) => {
    // @ts-ignore
    this.setState({ hovers: { [fieldName]: hover }})
  }
}



export interface ProjectResultCardProps {
  result: any;
  project: Project;
  className?: string;
  checked: boolean;
  isCompare: boolean;
  disableCheckedEffect?: boolean;
  hovers: ProjectCardHover;
  setActiveResult?: (n: number) => void;
  setCheckedResult: (n: number) => void;
  setHovers: (fieldName: keyof ProjectCardHover, hover: boolean) => void;
}


export interface ProjectResultCardState {
  result?: any;
}

export class ProjectResultCard extends Component<ProjectResultCardProps, ProjectResultCardState> {
  state: ProjectResultCardState = {
  }


  componentDidUpdate = (pp: Readonly<ProjectResultCardProps>, ps: Readonly<ProjectResultCardState>) => {
    if (!_.isEqual(pp.result, this.props.result)) {
      this.parseResult();
    } 
  }

  componentDidMount = () => {
    this.parseResult();
  }

  parseResult = () => {
    if (this.props.result) {
      this.setState({
        result: {
          ...this.props.result,
          loc_building_stories_avg: this.props.result.loc_building_stories_avg[this.props.project.building_stories_avg_type!],
          daylight_hours_proportion_less_n_hours: this.props.result.daylight_hours_proportion_less_n_hours[1],
        }
      })
    }
  }

  render() {
    return (
      <div className={`ProjectResultCard ${this.props.className} ${this.props.isCompare && "COMPARE" || "NORMAL"}
        ${!this.props.disableCheckedEffect && this.props.checked && "checked" || ""}`}
        onClick={e => { 
          if (this.props.setActiveResult && this.props.isCompare === false) {
            this.props.setActiveResult(this.props.result.real_report_number)
          }
        }}
      >
        <div className="header bg-navy font font-primary">
          {this.props.result.real_report_number}번 결과
          {
            this.props.isCompare === false &&
            <Checkbox
            disableRipple={true}
            className={`checkbox m-l-a ${this.props.checked && "checked" || ""}`} checked={this.props.checked}
              onClick={e => {
                e.stopPropagation();
                this.props.setCheckedResult(this.props.result.real_report_number);
              }} 
            />
          }
        </div>
        <div className="cad-img" 
          onClick={e => { 
            if (this.props.setActiveResult && this.props.isCompare) {
              this.props.setActiveResult(this.props.result.real_report_number)
            }
          }}
        >
          <img src={this.props.result.base_report_file_path.resImage} />
          {
            <PageViewIcon className="go-to-icon" />
          }
        </div>
        <div className="content">
        {
          ProjectCardData.map(d => {
            if (this.props.isCompare) {
              return (
                <div className={`key-value ${this.props.hovers[d.fieldName as keyof ProjectCardHover] && "hover" || ""}`}
                  onMouseEnter={() => this.props.setHovers(d.fieldName as keyof ProjectCardHover, true)}
                  onMouseLeave={() => this.props.setHovers(d.fieldName as keyof ProjectCardHover, false)}
                >
                  <div className="value">
                  {
                    this.state.result &&d.format(this.state.result[d.field])
                  } 
                  {
                    d.unit
                  }   
                  </div>
                </div>
              )
            } else if (this.props.isCompare === false && d.normalField) {
              return (
                <div className="key-value">
                {
                  <div className="key">{d.fieldName}</div>
                }
                  <div className="value">
                  {
                    this.state.result &&d.format(this.state.result[d.field])
                  } 
                  {
                    d.unit
                  }   
                  </div>
                </div>
              )
            }
          })
        }
        </div>
      </div>
    )
  }

  moveToDetailResult = () => {

  }
}

